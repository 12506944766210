import sha512 from 'js-sha512';
import React, { PureComponent } from 'react';
import { Col, Tooltip, Row, TabContent, TabPane } from 'reactstrap';
import styled from 'styled-components';
import Button from '../../components/Button';
import Checkbox from '../../components/Checkbox';
import Dropdown from '../../components/Dropdown';
import NavigationBar from '../../components/NavigationBar/NavigationBar';
import TitleBar from '../../components/TitleBar';
import { USER_ROLES, CAC_TYPE } from '../../res/Constants';
import buttonTheme from '../../res/styles/theme_button.module.scss';
import { getUsers, getUserPermissions, updateUser } from '../../service/UserService';
import { toastSuccess } from '../../ui/ToastHelper';
import { Input, PanelFieldTitle } from './components/Common';
import { HorizontalDiv } from '../../components/Common';
import colors from '../../res/styles/Colors';
import NavigationTabs from '../../components/NavigationTabs';
import dimension from './../../res/styles/Responsive';
import ZoneList from './components/ZoneList'
import { getFilters } from '../../service/FilterService';

const roleLabels = {};
roleLabels[USER_ROLES.admin] = 'Admin';
roleLabels[USER_ROLES.tech] = 'Tech';
roleLabels[USER_ROLES.commercial] = 'Commercial';
roleLabels[USER_ROLES.financial] = 'Financial';
roleLabels[USER_ROLES.client] = 'Customer';

const TABS = ['ACCOUNT', 'PERMISSIONS'];
const ACCOUNT_TAB = 0;
const PERMISSIONS_TAB = 1;

// TODO Duplicated from AddUser.jsx
function getInvalidFieldErrorText(fieldName) {
    return `${fieldName} is not valid`;
}

class ManageUsers extends PureComponent {
    state = {
        confirmPassword: null,
        validation: false,
        users: [],
        userGroups: [],
        selectedUser: { enabled: false, permissionOnlyTunnel: false },
        activeTab: ACCOUNT_TAB,
        speciesList: [],
        zoneList: [],
        isLoading: true,
        isUpdating: false
    };

    componentDidMount() {
        getUsers((users) => {
            users.sort((a, b) => a.username.localeCompare(b.username));

            const userMap = new Map();
            users.forEach((user) => {
                if (!userMap.has(user.role)) {
                    userMap.set(user.role, []);
                }
                userMap.get(user.role).push(user);
            });

            const userGroups = [];
            Object.keys(roleLabels).forEach((role) => {
                userGroups.push({ role: roleLabels[role], users: userMap.get(role) });
            });
            
            this.setState({
                users: [...users],
                userGroups: userGroups,
                selectedUser: userGroups[0].users[0],
                isLoading: false
            });

            this.onUserSelected(userGroups[0].users[0].username);
        });

        getFilters(CAC_TYPE.CROP, (filters) => {
            const zoneList = filters.zoneCode.values.map((zone) => {
                return { id: zone, zone: zone.name};
            });

            const speciesList = filters.speciesCode.values.map((specie) => {
                return { speciesName: specie.name, speciesCode: specie.value};
            });

            this.setState({
                zoneList: zoneList,
                speciesList: speciesList
            });
        });
    }

    onUserSelected = (username) => {
        const selectedUser = this.state.users.find((user) => user.username == username);

        getUserPermissions(selectedUser.pk, (permissions) => {
            const currentUserPermissions = [];
            permissions.forEach((permission) => {
                currentUserPermissions.push({ user: { pk: selectedUser.pk },
                                          zone: { code: permission.zone.code }, 
                                          species: { code: permission.species.code }});
            });

            this.setState({
                selectedUser: { ...selectedUser, permissions: currentUserPermissions, confirmPassword: null}
            });
    
        });
    };

    onPasswordEdited = (event) => {
        const password = event.target.value;
        this.setState({
            selectedUser: { ...this.state.selectedUser, password: password },
            validation: false
        });
    };

    onConfirmPasswordEdited = (event) => {
        const password = event.target.value;
        this.setState({ confirmPassword: password, validation: false });
    };

    onFirstNameEdited = (event) => {
        const firstName = event?.target?.value;
        // set as null if empty
        this.setState({
            selectedUser: {
                ...this.state.selectedUser,
                firstName: firstName || null
            }
        });
    };

    onLastNameEdited = (event) => {
        const lastName = event?.target?.value;
        // set as null if empty
        this.setState({
            selectedUser: {
                ...this.state.selectedUser,
                lastName: lastName || null
            }
        });
    };

    onUserEnabled = () => {
        this.setState({
            selectedUser: { ...this.state.selectedUser, enabled: !this.state.selectedUser.enabled }
        });
    };

    onConfirm = (permissions, zone) => {
        const user = { ...this.state.selectedUser };
        const updatedPermissions = user.permissions.filter((permission) => permission.zone.code != zone);

        permissions.forEach((permission) => {
            updatedPermissions.push({ user: { pk: user.pk },
                                      zone: { code: permission.zoneCode }, 
                                      species: { code: permission.speciesCode }});
        });
        this.setState({
            selectedUser: { ...this.state.selectedUser, permissions: updatedPermissions}
        });
        user.permissions = updatedPermissions;

    }

    updateUser = () => {
        if(!this.state.isUpdating){
            this.setState({ validation: true }, () => {
                if (this.isPasswordValid() && this.isConfirmPasswordValid()) {
                    const selectedUser = { ...this.state.selectedUser };
                    if (selectedUser.password != null && selectedUser.password.length > 0) {
                        selectedUser.password = sha512(selectedUser.password);
                    }
    
                    this.setState({ isUpdating: true});
                    updateUser(selectedUser, (updatedUser) => {
                        if (updatedUser != null) {
                            toastSuccess(`User ${updatedUser.username} successfully updated`);
                            this.setState({ isUpdating: false});
                        }
                    });
                }
            });    
        }
    };

    isPasswordValid = () => true;

    isConfirmPasswordValid = () => {
        const confirmPasswordValid =
            this.state.selectedUser.password != null && this.state.selectedUser.password.length > 0
                ? this.state.confirmPassword === this.state.selectedUser.password
                : true;
        return (!this.state.validation && this.isPasswordValid()) || confirmPasswordValid;
    };

    onTabSelection = (index) => this.setState({activeTab: index})

    onTunnelSettingToggle = () => {
        this.setState({
            selectedUser: { ...this.state.selectedUser, permissionOnlyTunnel: !this.state.selectedUser.permissionOnlyTunnel }
        });
    }

    render() {
        const userGroups = this.state.userGroups.map((group) => {
            return {
                label: group.role,
                options: group.users.map((u) => u.username)
            };
        });

        const activeTab = this.state.activeTab

        return (
            <div style={{ background: colors.light_gray}}>
                <NavigationBar />
                <TitleBar title='Manage User' />

                <UserContainer>
                    <UserFirstContainer>
                        <VerticalDiv>
                            <FieldTitle>User</FieldTitle>
                            <Dropdown
                                id='user'
                                style={{ width: '90%'}}
                                optgroups={userGroups}
                                emptyOption={false}
                                onChange={this.onUserSelected}
                            />
                        </VerticalDiv>

                        <UserCheckbox
                            text='Enabled'
                            isHorizontal={true}
                            isSelected={this.state.selectedUser.enabled}
                            onChange={this.onUserEnabled}
                        />
                    </UserFirstContainer>

                    <StyledButtonContainer isEnabled={!this.state.isUpdating}>
                            <Button
                                text='UPDATE USER'
                                className={!this.state.isUpdating? buttonTheme.green : buttonTheme.gray}
                                type='button'
                                styles={{height: '30px', width: '100%'}}
                                onClick={this.updateUser}
                            />
                    </StyledButtonContainer> 
                </UserContainer>

                <HeaderDetail>
                <div className='d-nav-header'>
                    <NavigationTabs
                        values={TABS}
                        activeTab={activeTab}
                        mainColor={colors.green}
                        dropdownClassName={buttonTheme.green}
                        onTabSelection={this.onTabSelection}
                    />
                </div>
                </HeaderDetail>
                <StyledTabContent activeTab={activeTab}>
                        <AccountTab tabId={ACCOUNT_TAB}>
                        <AccountTabContainer>
                        <FieldsCol xs='9' sm='7' md='5' lg='4' className='mx-auto'>
                        
                        <PanelFieldTitle>New Password</PanelFieldTitle>
                        <Input
                            type='password'
                            id='user-password'
                            value={this.state.selectedUser.password || ''}
                            onChange={this.onPasswordEdited}
                            onBlur={this.onPasswordEdited}
                        />
                        <Tooltip
                            placement='bottom'
                            target='user-password'
                            isOpen={!this.isPasswordValid()}>
                            {getInvalidFieldErrorText('Password')}
                        </Tooltip>

                        <PanelFieldTitle>Confirm Password</PanelFieldTitle>
                        <Input
                            type='password'
                            id='confirm-password'
                            value={this.state.confirmPassword || ''}
                            onChange={this.onConfirmPasswordEdited}
                            onBlur={this.onConfirmPasswordEdited}
                        />
                        <Tooltip
                            placement='bottom'
                            target='confirm-password'
                            isOpen={!this.isConfirmPasswordValid()}>
                            Confirm Password does not match with Password
                        </Tooltip>
                        <PanelFieldTitle>First Name</PanelFieldTitle>
                        <Input
                            type='text'
                            id='first-name'
                            defaultValue={this.state.selectedUser.firstName}
                            onBlur={this.onFirstNameEdited}
                        />
                        <PanelFieldTitle>Last Name</PanelFieldTitle>
                        <Input
                            type='text'
                            id='last-name'
                            defaultValue={this.state.selectedUser.lastName}
                            onBlur={this.onLastNameEdited}
                        />
                        </FieldsCol>
                </AccountTabContainer>
                        </AccountTab>

                        <PermissionsTab tabId={PERMISSIONS_TAB}>
                            <PermissionsTabContainer>
                                <TunnelSettingContainer onClick={this.onTunnelSettingToggle}>
                                    <SettingField>Tunnel Production Way Only</SettingField>
                                    {this.state.selectedUser.permissionOnlyTunnel ? 
                                    <img src={require('../../res/images/cac_toggle_on.png')}/> :
                                    <img src={require('../../res/images/cac_toggle_off.png')}/>}                             
                                </TunnelSettingContainer>
                                {this.state.isLoading ? null : 
                                    <ZoneList zoneList={this.state.zoneList} speciesList={this.state.speciesList}
                                        permissionsList={this.state.selectedUser.permissions} onConfirm={this.onConfirm}/> 
                                }
                            </PermissionsTabContainer>
                        </PermissionsTab>
                </StyledTabContent>
                </div>
        );
    }
}


const FieldTitle = styled.p`
    font-size: 12px;
    font-weight: bold;
    color: ${colors.darker_gray};
    width: 70%;
`;

const UserContainer = styled(HorizontalDiv)`
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    width: 100%;
    height: 30%;
    padding-left: 10%;
    padding-right: 10%;        
    background-color: ${colors.light_gray};
    align-items: center;
    padding-top: 2%;

    @media ${dimension.md} {
         height: 30%;
    }

    @media (min-width: 375px){
        flex-direction: row;
    }
`;

const UserFirstContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 55%;

    @media (max-width: 576px){
        width: 100%;
    }
`;

const VerticalDiv = styled.div`
    width: 50%;

    @media (min-width: 768px){
        width: 70%;
    }

    @media (min-width: 576px){
        width: 60%;
    }

    @media (min-width: 375px){
        width: 100%;
    }
`;

const StyledButtonContainer = styled.div`
        width: 190px;
        border-radius: 30px;
        background: ${props => props.isEnabled ? colors.green : colors.gray};
        margin-left: 35%;
        text-align: center;
        margin-top: 4%;
        align-items: center;

        @media (min-width: 768px){
            background: ${props => props.isEnabled ? colors.green : colors.gray};
            text-align: center;
            align-items: center;
            margin-left: 25%;
            margin-top: 4%;
            width: 20%;
        }
    
        @media (max-width: 576px){
            background: ${props => props.isEnabled ? colors.green : colors.gray};
            text-align: center;
            align-items: center;
            margin-top: 2%;
            width: 100%;
        }
 
`;

const AccountTab = styled(TabPane)`
        height: 500px;
        margin-bottom: 20px;

        @media (max-width: 375px){
            margin-left: 0;
            margin-right: 0;
        }
`;

const PermissionsTab = styled(TabPane)`
        height: 500px;
        margin-bottom: 20px;
        
        @media (max-width: 375px){
            margin-left: 0;
            margin-right: 0;
        }
`;

const UserCheckbox = styled(Checkbox)`
        align-self: center;
        width: 40%;

        @media (max-width: 768px){
            margin-left: 15%;
        }
`;

const StyledTabContent = styled(TabContent)`
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
    justify-content: center;
    align-items: center;
    background: white;

    @media (max-width: 375px){
        margin-left: 0;
        margin-right: 0;
        width: 100%;
        margin-bottom: 20px;

    }
`;

const StyledRow = styled(Row)`
    background-color: white;
`;

const AccountTabContainer = styled(StyledRow)`
    width: 80%;
    height: 400px;
    margin-left: 10%;
    margin-right: 10%;  

    @media (max-width: 375px){
        margin-left: 0;
        margin-right: 0;
        height: 400px;
        width: 100%;
    }
`;

const PermissionsTabContainer = styled.div`
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
    background-color: white; 
`;

const TunnelSettingContainer = styled.div`
    width: 100%;
    height: 10%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;

const SettingField = styled.p`
    font-size: 16px;
    font-weight: normal;
    color: ${colors.darkest_gray};
    margin: 0px 10px 0px 0px;
`;

const HeaderDetail = styled.div`
    height: 15vh;
    width: 80%;
    margin-left: 10%;
    margin-end: 10%;
    margin-top: 3%;
    background-color: white;

    .d-nav-header {
        width: 100%;
        margin-start: 10%;
        height: 50px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 3px;
        text-align: center;
    }

    & div {
        background-color: ${colors.gray};
    }

    & ul {
        background-color: ${colors.gray};
    }
    
    & li:checked { background: ${colors.gray}}
`;

const FieldsCol = styled(Col)`
    & > input,
    select {
        margin-bottom: 20px;
    }
    & > label {
        margin: 0 0 12px 0;
    }
`;

export default ManageUsers;
