/* eslint-disable import/prefer-default-export */
import { Row } from 'reactstrap';
import styled from 'styled-components';
import { FieldTitle } from '../../../components/Common';
import colors from '../../../res/styles/Colors';
import dimension from '../../../res/styles/Responsive';

export const ControlPanelPage = styled(Row)`
    min-height: 81vh;
    padding-top: 50px;
    background-color: ${colors.light_gray};

    @media ${dimension.md} {
        display: flex;
    }
`;

export const PanelFieldTitle = styled(FieldTitle)`
    color: ${colors.darker_gray};
`;

export const Input = styled.input`
    width: 100%;
    height: 30px;
    border-radius: 5px;
    box-shadow: 0 1px 2px grey;
    text-indent: 10px;

    :focus {
        outline: none;
    }
`;
