import PropTypes from 'prop-types';
import React from 'react';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import styled from 'styled-components';
import { HorizontalDiv } from '../../../../../components/Common';
import { CANCEL, SAVE, UNIFORMITY_VALUES } from '../../../../../res/Constants';
import themeButton from '../../../../../res/styles/theme_button.module.scss';
import { dismissToast, toastError } from './../../../../../ui/ToastHelper';
import EditableReportItem from './EditableReportItem';

DialogEditing.propTypes = {
    isOpen: PropTypes.bool,
    reportData: PropTypes.object,
    linesCount: PropTypes.number,
    cacType: PropTypes.number,
    onSave: PropTypes.func,
    onCancel: PropTypes.func
};

DialogEditing.defaultProps = {
    isOpen: false,
    onSave: () => {},
    onCancel: () => {}
};

export default function DialogEditing(props) {
    const [currentReportData, setCurrentReportData] = React.useState(null);
    const [allFieldsValid, setAllFieldsValid] = React.useState(true);

    const onReportEdited = (data, allFieldsValid) => {
        setCurrentReportData(data);
        setAllFieldsValid(allFieldsValid);
    };

    const onSave = React.useCallback(() => {
        if (!allFieldsValid) {
            return toastError('Invalid values');
        }
        dismissToast();
        props.onSave(sanitizeUniformity(currentReportData));
    }, [props.onSave, currentReportData]);

    const sanitizedReportData = sanitizeReportValues(props.reportData, '-');

    return (
        <Modal isOpen={props.isOpen}>
            <ModalBody>
                {sanitizedReportData != null ? (
                    <EditableReportItem
                        linesCount={props.linesCount}
                        reportData={sanitizedReportData}
                        cacType={props.cacType}
                        onReportEdited={onReportEdited}
                    />
                ) : (
                    <p>Loading...</p>
                )}
            </ModalBody>
            <ModalFooter>
                <StyledFooterDiv>
                    <button className={themeButton.green} onClick={onSave}>
                        {SAVE.toUpperCase()}
                    </button>
                    <button className={themeButton.red} onClick={props.onCancel}>
                        {CANCEL.toUpperCase()}
                    </button>
                </StyledFooterDiv>
            </ModalFooter>
        </Modal>
    );
}

function sanitizeReportValues(obj, value) {
    const safeObjCopy = { ...obj };
    Object.entries(safeObjCopy).forEach(([k, v]) => {
        if (v === value) {
            safeObjCopy[k] = '';
        }
    });
    return safeObjCopy;
}

function sanitizeUniformity(currentReportData) {
    const reportData = { ...currentReportData };
    const uniformityA = reportData.lineA?.Uniformity;
    if (uniformityA === 'Yes' || uniformityA === 'No') {
        reportData.lineA.Uniformity = UNIFORMITY_VALUES[uniformityA];
    }
    const uniformityB = reportData.lineB?.Uniformity;
    if (uniformityB === 'Yes' || uniformityB === 'No') {
        reportData.lineB.Uniformity = UNIFORMITY_VALUES[uniformityB];
    }
    return reportData;
}

const StyledFooterDiv = styled(HorizontalDiv)`
    width: 100%;
    justify-content: space-between;

    button {
        width: 150px;
    }
`;
