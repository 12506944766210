import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import colors from './../res/styles/Colors';
import dimension from './../res/styles/Responsive';

SearchBar.propTypes = {
    className: PropTypes.string,
    onSearchChange: PropTypes.func
};

SearchBar.defaultProps = {
    onSearchChange: () => {}
};

function SearchBar(props) {
    return (
        <StyledDiv className={props.className}>
            <input
                type='text'
                placeholder='Search'
                className='input-field'
                onChange={(e) => props.onSearchChange(e.target.value)}
            />
        </StyledDiv>
    );
}

const StyledDiv = styled.div`
    background: linear-gradient(
        to bottom,
        ${colors.green} 40%,
        ${colors.dark_green} 10%,
        ${colors.light_gray} 50%
    );
    display: flex;

    @media ${dimension.md} {
        background: inherit;
    }

    input {
        margin: auto;
        width: 90%;
        text-indent: 20px;

        @media ${dimension.md} {
            width: 300px;
        }

        &::placeholder {
            color: ${colors.dark_green};
        }
    }
`;

export default SearchBar;
