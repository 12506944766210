import axios from 'axios';
import { HTTP_CODE } from '../res/Constants';

const SETTINGS = '/settings';

/**
 * @param {Number[]} settingKeys
 */
export async function getSettingList(settingKeys) {
    const fetchedSettings = await axios({
        method: 'get',
        url: SETTINGS,
        params: { keys: settingKeys.toString() }
    });
    if (fetchedSettings.status !== HTTP_CODE.OK || fetchedSettings.data == null) {
        return [];
    }
    return fetchedSettings.data;
}

export function saveSettings(settings, onSuccessCallback, onFailureCallback) {
    axios({ method: 'post', url: SETTINGS, data: settings })
        .then((result) => {
            if (result.status === HTTP_CODE.OK) {
                if (onSuccessCallback != null) onSuccessCallback();
            } else {
                if (onFailureCallback != null) onFailureCallback();
            }
        })
        .catch((error) => {
            console.log(error);
            if (onFailureCallback != null) onFailureCallback();
        });
}
