import React from 'react';
import styled from 'styled-components';
import { LOGOUT } from '../../../res/Constants';
import PopoverMenu from '../../PopoverMenu';

export default function UserDropDown(props) {
    const [popoverOpen, setPopoverOpen] = React.useState(false);

    return (
        <div className={props.className}>
            <img
                id='header-user-popup'
                className='header-icon'
                src={require('../../../res/images/cac_cp_user.png')}
                alt='User icon'
            />
            <StyledPopoverMenu
                target='header-user-popup'
                isOpen={popoverOpen}
                header={props.username}
                body={[{ label: LOGOUT, onClick: props.handleLogout }]}
                onToggle={() => setPopoverOpen((curr) => !curr)}
            />
        </div>
    );
}

const StyledPopoverMenu = styled(PopoverMenu)`
    button {
        &:hover,
        &:focus {
            background-color: white;
            border-style: none;
            outline: none;
        }
    }
`;
