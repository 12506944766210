import * as _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { isBrowser } from 'react-device-detect';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import {
    ClickableText,
    EmptyDiv,
    FlexDiv,
    HorizontalDiv,
    ImgButton
} from '../../../components/Common';
import NavigationBar from '../../../components/NavigationBar/NavigationBar';
import TitleBar from '../../../components/TitleBar';
import { SORT_TYPE, USER_ROLES } from '../../../res/Constants';
import colors from '../../../res/styles/Colors';
import dimension from '../../../res/styles/Responsive';
import buttonTheme from '../../../res/styles/theme_button.module.scss';
import { getCurrentRole } from '../../../service/AccountService';
import { getClients } from '../../../service/ClientService';
import { getUsers } from '../../../service/UserService';
import {
    deleteVisit,
    exportVisits,
    getVisitsDates,
    loadVisitList,
    sanitizeDatesDifference,
    saveVisit
} from '../../../service/VisitService';
import { toastError, toastSuccess, toastWarning } from '../../../ui/ToastHelper';
import { sort } from '../../../utils/Algorithms';
import { timeWithSeconds } from '../../../utils/DateFormatter';
import { MaterialDatePicker } from '../Components/Common';
import VisitItem from '../Components/VisitItem';
import VisitItemEditing from './VisitItemEditing';

export default function VisitList() {
    const history = useHistory();
    const [dates, setDates] = React.useState({ from: null, to: null });
    const [visits, setVisits] = React.useState([]);
    const [visitsEditing, setVisitsEditing] = React.useState(new Set());
    const [clients, setClients] = React.useState([]);
    const [staffList, setStaffList] = React.useState([]);
    const [clonedPkCounter, setClonedPkCounter] = React.useState(-1);

    React.useEffect(() => {
        setDates(getVisitsDates());
        getClients((clients) => {
            setClients(clients);
        });
        if (getCurrentRole() !== USER_ROLES.client) {
            getUsers((users) => {
                setStaffList(users.filter((u) => u.role !== USER_ROLES.client));
            });
        }
    }, []);

    React.useEffect(() => {
        if (dates.from != null && !_.isNaN(dates.from) && dates.to != null && !_.isNaN(dates.to)) {
            loadVisitList(
                dates.from,
                dates.to,
                (list) => setVisits(list),
                () => toastError('Error while loading visits.')
            );
        }
    }, [dates]);

    const onDatesChange = (dateId, dateAsLong) => {
        setDates((curr) => sanitizeDatesDifference({ ...curr, [dateId]: dateAsLong }, dateId));
    };

    const onExport = () => {
        const { from, to } = dates;
        exportVisits(
            from,
            to,
            () => {},
            () => toastError('Error while exporting visits.')
        );
    };

    const onDelete = (visitPk) => {
        deleteVisit(visitPk, () => {
            toastSuccess('Visit successfully deleted.');
            setVisits(visits.filter((v) => v.pk != visitPk));
        });
    };

    const onCancelEditing = (visitPk) => {
        setVisitsEditing((curr) => new Set(_.without([...curr], visitPk)));
        if (visitPk < 0) {
            setVisits((curr) => curr.filter((v) => v.pk !== visitPk));
        }
    };

    const onCreate = () => {
        history.push('/visits/create');
    };

    const onEditDesktop = (visitPk) => {
        setVisitsEditing((curr) => new Set([...curr, visitPk]));
    };

    const onCloneDesktop = (visitToClone) => {
        const clonedVisit = {
            ...visitToClone,
            pk: clonedPkCounter,
            date: moment(visitToClone.date)
                .add(1, 'day')
                .valueOf()
        };
        setClonedPkCounter((curr) => curr - 1);
        setVisits((curr) => {
            const newVisits = [...curr];
            newVisits.splice(newVisits.indexOf(visitToClone) + 1, 0, clonedVisit);
            return newVisits;
        });
    };

    const onSaveVisit = (visit) => {
        if (visit?.date == null || isNaN(visit?.date)) {
            return toastWarning('Date is required.');
        }
        const visitToSave = {
            ...visit,
            time: timeWithSeconds(visit.time),
            pk: visit.pk > 0 ? visit.pk : null
        };
        saveVisit(
            visitToSave,
            (newVisit) => {
                onCancelEditing(visit.pk);
                setVisits((curr) => {
                    // sort the visits in case the user has changed the visit date
                    const newVisits =
                        visit.pk > 0
                            ? curr.map((v) => (v.pk === newVisit.pk ? newVisit : v))
                            : [...curr, newVisit];
                    return sort(newVisits, SORT_TYPE.ASCENDANT, 'date');
                });
            },
            () => toastError('An error occurred while saving the visit.')
        );
    };

    return (
        <StyledVisitPage>
            <div style={{ position: 'fixed', width: '100%', zIndex: '100' }}>
                <NavigationBar />
                <TitleBar title='Customer Visits' hasBack={false} />
            </div>
            <StyledBodyContainer>
                <DatesPicker
                    style={{ margin: '0 5%' }}
                    dates={dates}
                    onDatesChange={onDatesChange}
                />
                <button
                    className={buttonTheme.red}
                    style={{ width: '200px', alignSelf: 'center' }}
                    onClick={onCreate}>
                    ADD NEW VISIT
                </button>
                <StyledExportContainer>
                    <ImgButton
                        onClick={onExport}
                        src={require('../../../res/images/cac_excel_icon.png')}
                    />
                    <EmptyDiv width='10px' />
                    <ClickableText
                        bold
                        color={colors.green}
                        style={{ marginBottom: '0' }}
                        onClick={onExport}>
                        EXPORT
                    </ClickableText>
                </StyledExportContainer>
                <StyledListContainer>
                    {visits &&
                        visits.map((visit) => {
                            return visitsEditing.has(visit.pk) || visit.pk < 0 ? (
                                <VisitItemEditing
                                    key={visit.pk}
                                    visit={visit}
                                    clients={clients}
                                    staffList={staffList}
                                    onDelete={() => onDelete(visit.pk)}
                                    onCancel={() => onCancelEditing(visit.pk)}
                                    onSave={onSaveVisit}
                                />
                            ) : (
                                <VisitItem
                                    key={visit.pk}
                                    visit={visit}
                                    isEditable={true}
                                    onDelete={() => onDelete(visit.pk)}
                                    onEditDesktop={() => onEditDesktop(visit.pk)}
                                    onCloneDesktop={() => onCloneDesktop(visit)}
                                />
                            );
                        })}
                </StyledListContainer>
            </StyledBodyContainer>
        </StyledVisitPage>
    );
}

DatesPicker.propTypes = {
    dates: PropTypes.shape({
        from: PropTypes.node,
        to: PropTypes.number
    }),
    onDatesChange: PropTypes.func
};

function DatesPicker(props) {
    const onDateChange = (fieldId, dateAsLong) => {
        if (props.onDatesChange != null) {
            props.onDatesChange(fieldId, dateAsLong);
        }
    };

    // disable date input from keyboard only for desktop
    const onKeyDown = (e) => {
        if (isBrowser) {
            e.preventDefault();
        }
    };

    return (
        <FlexDiv direction='column' justifyContent='center' alignItems='center'>
            <p style={{ color: colors.darker_gray, fontSize: '16px' }}>Set an interval:</p>
            <HorizontalDiv>
                <MaterialDatePicker
                    value={moment(props.dates.from).format(moment.HTML5_FMT.DATE)}
                    onChange={(e) => onDateChange('from', e?.target?.valueAsNumber)}
                    onKeyDown={onKeyDown}
                />
                <ResponsiveEmptyDiv width='20px' />
                <MaterialDatePicker
                    value={moment(props.dates.to).format(moment.HTML5_FMT.DATE)}
                    onChange={(e) => onDateChange('to', e?.target?.valueAsNumber)}
                    onKeyDown={onKeyDown}
                />
            </HorizontalDiv>
        </FlexDiv>
    );
}

const StyledBodyContainer = styled.div`
    padding-top: 19vh; // due to the fixed header
    min-height: 100%;
    display: flex;
    flex-direction: column;
    background-color: ${colors.light_gray};

    p {
        font-size: 13px;
    }

    & > * {
        margin-top: 15px;
    }
`;

const StyledExportContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 15px 5% 0;

    && {
        p {
            font-size: 14px;
            margin-bottom: 0;
        }
    }
`;

const StyledListContainer = styled.div`
    background-color: ${colors.light_gray};
    padding: 0 5%;

    & > * {
        margin-bottom: 15px;
    }
`;

const StyledVisitPage = styled.div`
    height: 100%;
    background-color: ${colors.light_gray} p {
        font-size: 13px;
    }
`;

const ResponsiveEmptyDiv = styled(EmptyDiv)`
    @media ${dimension.md} {
        width: 30px;
    }
`;
