import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { VerticalDiv } from '../components/Common';
import dimension from './../res/styles/Responsive';
import { FieldTitle } from './Common';

export default class EditableText extends PureComponent {
    static whyDidYouRender = true;
    static propTypes = {
        className: PropTypes.string,
        type: PropTypes.string,
        title: PropTypes.string,
        initialValue: PropTypes.string,
        rows: PropTypes.number,
        fieldId: PropTypes.string,
        onBlur: PropTypes.func,
        onChange: PropTypes.func
    };

    static defaultProps = {
        type: 'text',
        title: '',
        initialValue: '',
        rows: 1
    };

    state = {
        value: this.props.initialValue
    };

    onChange = (event) => {
        const value = event?.target?.value;
        if (value != null) {
            this.setState({ value });
            this.props.onChange && this.props.onChange(value);
        }
    };

    onBlur = (event) => {
        this.props.onBlur && this.props.onBlur(this.props.fieldId, event.target.value);
    };

    render() {
        return (
            <StyledVerticalDiv className={this.props.className}>
                <FieldTitle>{this.props.title}</FieldTitle>
                {this.props.rows > 1 ? (
                    <StyledTextArea
                        rows={this.props.rows}
                        onChange={this.onChange}
                        onBlur={this.onBlur}
                        value={this.state.value}
                    />
                ) : (
                    <StyledInput
                        type={this.props.type}
                        value={this.state.value}
                        onChange={this.onChange}
                        onBlur={this.onBlur}
                    />
                )}
            </StyledVerticalDiv>
        );
    }
}

const StyledVerticalDiv = styled(VerticalDiv)`
    font-size: 12px;

    @media ${dimension.md} {
        width: inherit;
    }
`;

const StyledTextArea = styled.textarea`
    border-radius: 3px;
`;

const StyledInput = styled.input`
    height: inherit;
    border-radius: 3px;
    text-indent: 0;

    && {
        -moz-appearance: textfield;

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
    }
`;
