import axios from 'axios';
import { saveAs } from 'file-saver';
import { MOCKED_DATA } from '../debug/Debug';
import { HTTP_CODE } from '../res/Constants';
import { removeNullProperties } from '../utils/Utils';
import { MIME_TYPE_OCTET_STREAM, ZIP_EXTENSION } from './ServiceConstants';

const DOWNLOAD_PHOTOS = '/photos/export';
const PHOTOS = '/photos';
const DELETE_PHOTO = (photoId) => `/photos/${photoId}`;

/**
 *
 * @param {Object} filters
 * @param {number} fromDateAsLong
 * @param {number} toDateAsLong
 * @param {Function} onErrorCallback
 */
export function downloadPhotosByDates(
    filters,
    fromDateAsLong,
    toDateAsLong,
    onSuccessCallback,
    onErrorCallback
) {
    console.log('DOWNLOAD PHOTOS PARAMS', { filters, fromDateAsLong, toDateAsLong });

    if (filters === null) {
        console.log('filters are null');
        onErrorCallback('No filters selected.');
        return;
    }

    const params = removeNullProperties({
        ...filters,
        from: fromDateAsLong,
        to: toDateAsLong
    });

    axios({ method: 'get', url: DOWNLOAD_PHOTOS, responseType: 'arraybuffer', params })
        .then((response) => {
            if (response.status === HTTP_CODE.OK) {
                if (response.data.byteLength === 0) {
                    // zip is empty => no photos found
                    onErrorCallback('No photos found.');
                } else {
                    const blob = new Blob([response.data], { type: MIME_TYPE_OCTET_STREAM });
                    saveAs(blob, `photos.${ZIP_EXTENSION}`);
                    onSuccessCallback();
                }
            } else if (onErrorCallback != null) {
                onErrorCallback(response);
            }
        })
        .catch((error) => {
            if (onErrorCallback != null) {
                onErrorCallback(error);
            }
        });
}

/**
 *
 * @param {number[]} ids
 * @param {Function} onErrorCallback
 */
export function downloadPhotosByIds(ids, onErrorCallback) {
    axios({
        method: 'get',
        url: DOWNLOAD_PHOTOS,
        responseType: 'arraybuffer',
        params: { ids: ids.toString() }
    })
        .then((response) => {
            if (response.status === HTTP_CODE.OK) {
                if (response.data.byteLength === 0) {
                    // zip is empty => no photos found
                    onErrorCallback('No photos found.');
                } else {
                    const blob = new Blob([response.data], { type: MIME_TYPE_OCTET_STREAM });
                    saveAs(blob, `photos.${ZIP_EXTENSION}`);
                }
            } else if (onErrorCallback != null) {
                onErrorCallback(response);
            }
        })
        .catch((error) => {
            if (onErrorCallback != null) {
                onErrorCallback(error);
            }
        });
}

/**
 * @param {number} photoId
 * @param {function} onSuccessCallback
 * @param {function} onFailureCallback
 */
export function deletePhoto(photoId, onSuccessCallback, onFailureCallback) {
    if (MOCKED_DATA) {
        onSuccessCallback(true);
        return;
    }
    axios({ method: 'delete', url: DELETE_PHOTO(photoId) })
        .then((result) => {
            if (result.status === HTTP_CODE.OK) {
                onSuccessCallback && onSuccessCallback(result.data);
            } else {
                onFailureCallback && onFailureCallback();
            }
        })
        .catch((error) => {
            console.log('DELETE PHOTO ERROR', error);
            onFailureCallback && onFailureCallback();
        });
}

/**
 *
 * @param {File} photo
 * @param {String} type
 * @param {String} reportPk
 * @param {function} onSuccessCallback
 * @param {function} onFailureCallback
 */
export function uploadPhoto(photo, type, reportPk, onSuccessCallback, onFailureCallback) {
    const formData = new FormData();
    formData.append('file', photo);
    formData.append(
        'type',
        new Blob([type], {
            type: 'text/plain'
        })
    );
    formData.append(
        'reportPk',
        new Blob([reportPk], {
            type: 'text/plain'
        })
    );

    axios({
        method: 'post',
        url: PHOTOS,
        headers: { 'Content-Type': 'multipart/form-data' },
        data: formData
    })
        .then((result) => {
            if (result.status === HTTP_CODE.OK) {
                onSuccessCallback && onSuccessCallback();
            } else {
                onFailureCallback && onFailureCallback();
            }
        })
        .catch(() => {
            onFailureCallback && onFailureCallback();
        });
}
