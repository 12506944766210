import React, { PureComponent } from 'react';
import { Col, Row } from 'reactstrap';
import styled from 'styled-components';
import NavigationBar from '../../components/NavigationBar/NavigationBar';
import TitleBar from '../../components/TitleBar';
import colors from '../../res/styles/Colors';
import dimension from '../../res/styles/Responsive';
import buttonTheme from '../../res/styles/theme_button.module.scss';
import { getClients, updateClientReportData } from '../../service/ClientService';
import { toastSuccess, toastWarning } from '../../ui/ToastHelper';
import CustomerFields from './components/CustomerFields';

const reportDatesRegEx = /^(\d\d-\d\d;)+$/;
const maxDayForMonth = [undefined, 31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

const helpText = `
The app will notify technicians who have customer techforms when the first
report before one of the indicated delivery dates is synchronized. This
functionality can be enabled or disabled via checkbox.
`;

class CustomerReportDates extends PureComponent {
    state = {
        originalClients: [],
        clients: [],
        editedClients: {}
    };

    componentDidMount() {
        getClients((clients) => {
            clients.sort((a, b) => a.code.localeCompare(b.code));
            this.setState({ originalClients: [...clients], clients: [...clients] });
        });
    }

    updateClient = (client) => {
        const editedClients = { ...this.state.editedClients };
        editedClients[client.code] = client;
        const clientIndex = this.state.clients.findIndex((c) => c.code === client.code);
        const updatedClients = [...this.state.clients];
        updatedClients[clientIndex] = client;
        this.setState({ clients: updatedClients, editedClients: editedClients });
    };

    onRevert = () => {
        this.setState({ clients: [...this.state.originalClients], editedClients: [] });
    };

    onApply = () => {
        const editedClients = Object.values({ ...this.state.editedClients });
        const invalidClients = editedClients.filter((client) => {
            const reportDates = client.reportDates;
            // empty dates are valid
            if (reportDates == null || reportDates.length === 0) {
                return false;
            }
            // check dates syntax
            if (!reportDatesRegEx.test(reportDates)) {
                return true;
            }
            // check dates semantics
            const dates = reportDates.split(';');
            const datesValidity = dates.map((date) => {
                if (date.length === 0) {
                    return true;
                }
                const [dayText, monthText] = date.split('-');
                const day = Number(dayText);
                const month = Number(monthText);
                return maxDayForMonth[month] != null && maxDayForMonth[month] >= day;
            });
            return datesValidity.some((validity) => !validity);
        });

        if (invalidClients.length > 0) {
            const invalidClientCodes = invalidClients.map((client) => client.code);
            toastWarning(
                `Invalid Report Dates for the following clients: ${invalidClientCodes.join(', ')}`
            );
        } else {
            updateClientReportData(editedClients, (updatedClients) => {
                if (updatedClients != null) {
                    toastSuccess('Updates correctly applied');
                }
            });
        }
    };

    render() {
        return (
            <div>
                <NavigationBar />
                <TitleBar title='Customer Report Dates' help={helpText} />
                <HeaderRow style={{ paddingTop: '15px', fontSize: '14px' }}>
                    <Col xs='10' sm='8' md='6' lg='5' className='mx-auto'>
                        <p>
                            Update Report Dates in the following format:
                            <br />
                            "day-month;day-month;" (e.g. "31-01;28-02;30-03;15-04;")
                        </p>
                        <HeaderContainer>
                            <span>Report Dates</span>
                            <span>First Report Notification</span>
                        </HeaderContainer>
                    </Col>
                </HeaderRow>
                <div style={{ position: 'absolute', top: '34vh', bottom: '68px', width: '100%' }}>
                    <CustomerReportDatesPage>
                        <ScrollableColumnContainer>
                            <ScrollableColumn>
                                <FieldsCol xs='10' sm='8' md='6' lg='5' className='mx-auto'>
                                    {this.state.clients.map((client) => (
                                        <CustomerFields
                                            key={client.code}
                                            customer={client}
                                            onReportDatesChange={this.updateClient}
                                            onFirstReportNotificationChange={this.updateClient}
                                        />
                                    ))}
                                </FieldsCol>
                            </ScrollableColumn>
                        </ScrollableColumnContainer>
                    </CustomerReportDatesPage>
                </div>
                <FooterRow
                    style={{
                        paddingTop: '15px',
                        position: 'absolute',
                        bottom: '0',
                        width: '100%'
                    }}>
                    <Col xs='10' sm='8' md='6' lg='5' className='mx-auto'>
                        <ButtonContainer>
                            <RevertButton onClick={this.onRevert} style={{ margin: '0' }}>
                                REVERT
                            </RevertButton>
                            <ApplyButton onClick={this.onApply} style={{ margin: '0' }}>
                                APPLY
                            </ApplyButton>
                        </ButtonContainer>
                    </Col>
                </FooterRow>
            </div>
        );
    }
}

const CustomerReportDatesPage = styled(Row)`
    /* min-height: 74vh; */
    height: 100%;
    /* padding-top: 50px; */
    background-color: ${colors.light_gray};

    @media ${dimension.md} {
        display: flex;
        /* min-height: 81vh; */
    }
`;

const ScrollableColumnContainer = styled(Col)`
    height: 100%;
    display: flex;
    flex-flow: column;
`;

const ScrollableColumn = styled.div`
    display: flex;
    flex-flow: column;
    flex: 1 1 auto;
    overflow-y: auto;
`;

const ButtonContainer = styled(Row)`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 0 15px;
`;

const HeaderContainer = styled(ButtonContainer)`
    color: ${colors.dark_green};
`;

const HeaderRow = styled(Row)`
    background-color: ${colors.light_gray};
    min-height: 15vh;
`;

const FooterRow = styled(Row)`
    background-color: ${colors.light_gray};
`;

const RevertButton = styled.button.attrs({
    className: buttonTheme.red
})`
    && {
        width: 40%;
        margin: inherit;

        @media ${dimension.md} {
            width: inherit;
            padding: 0 35px;
        }
    }
`;

const ApplyButton = styled.button.attrs({
    className: buttonTheme.green
})`
    && {
        width: 40%;
        margin: inherit;

        @media ${dimension.md} {
            width: 50%;
            padding: 0 35px;
        }
    }
`;

const FieldsCol = styled(Col)`
    & > input,
    select {
        margin-bottom: 20px;
    }
    & > label {
        margin: 0 0 12px 0;
        display: inline-block;
    }
`;

export default CustomerReportDates;
