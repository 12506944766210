import { toast } from 'react-toastify';

const toastId = 0;
const toastOptions = {
    position: toast.POSITION.BOTTOM_RIGHT,
    autoClose: 5000,
    hideProgressBar: true,
    newestOnTop: true,
    draggable: false,
    toastId
};

const toastPersistentOptions = {
    ...toastOptions,
    autoClose: false,
    closeOnClick: false
};

/**
 * Show the toast info one at a time.
 * @param {string} text
 */
export function toastInfo(text, persistent) {
    dismissToast();
    toast.info(text, persistent ? toastPersistentOptions : toastOptions);
}

/**
 * Show the toast success one at a time.
 * @param {string} text
 */
export function toastSuccess(text) {
    dismissToast();
    toast.success(text, toastOptions);
}

/**
 * Show the toast error one at a time.
 * @param {string} text
 */
export function toastError(text) {
    dismissToast();
    toast.error(text, toastOptions);
}

/**
 * Show the toast warning one at a time.
 * @param {string} text
 */
export function toastWarning(text) {
    dismissToast();
    toast.warn(text, toastOptions);
}

export function dismissToast() {
    toast.dismiss(toastId);
}
