import moment from 'moment';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import styled from 'styled-components';
import Checkbox from '../../../../components/Checkbox';
import ConfirmDialog from '../../../../components/ConfirmDialog';
import colors from '../../../../res/styles/Colors';
import dimension from '../../../../res/styles/Responsive';
import { HorizontalDiv, VerticalDiv } from './../../../../components/Common';
import LinePoint from './../../../../components/Date/LinePoint';

const iconDownload = require('../../../../res/images/cac_download_green.png');
const iconTrash = require('../../../../res/images/cac_trash_black.png');

export default class PictureCell extends PureComponent {
    static whyDidYouRender = true;

    static propTypes = {
        className: PropTypes.string,
        pictureData: PropTypes.object.isRequired,
        isPhotoSelected: PropTypes.bool,
        onPhotoDeleted: PropTypes.func,
        onPhotoSelected: PropTypes.func
    };

    static defaultProps = {
        isPhotoSelected: false,
        onPhotoSelected: () => {},
        onPhotoDeleted: () => {}
    };

    state = {
        isDeleteDialogOpen: false
    };

    onDeletePictureClick = () => {
        this.setState({ isDeleteDialogOpen: true });
    };

    onDeleteConfirm = (photoId, confirmed) => {
        this.setState({ isDeleteDialogOpen: false });
        if (confirmed) {
            this.props.onPhotoDeleted(photoId);
        }
    };

    render() {
        const { url, name, date, id, author } = this.props.pictureData;
        const encodedPath = url + '/' + encodeURIComponent(name);

        return (
            <Container className={this.props.className}>
                <StyledDateContainer className='d-none -d-md-block'>
                    <p style={{ textAlign: 'center', fontWeight: 'bold' }}>
                        {moment(date).format('DD')}
                    </p>
                    <p className='text-dark-gray'>{moment(date).format('MMM/YYYY')}</p>
                </StyledDateContainer>
                <StyledLinePoint color={colors.light_green} />
                <PictureDataContainer>
                    <StyledInfoContainer>
                        <p className='d-md-none text-dark-gray'>
                            {moment(date).format('DD/MM/YYYY')}
                        </p>
                        <StyledTitle>{author}</StyledTitle>
                        <StyledPhotoName className='d-none d-md-block'>{name}</StyledPhotoName>
                        <Buttons
                            className='d-none d-md-flex'
                            encodedPath={encodedPath}
                            onDeletePictureClick={this.onDeletePictureClick}
                        />
                    </StyledInfoContainer>
                    <PictureContainer>
                        <Checkbox
                            customId={id.toString()}
                            isSelected={this.props.isPhotoSelected}
                            onChange={this.props.onPhotoSelected}
                        />
                        <img src={encodedPath} alt='' />
                    </PictureContainer>
                    <StyledPhotoName className='d-md-none'>{name}</StyledPhotoName>
                    <Buttons
                        className='d-md-none'
                        encodedPath={encodedPath}
                        onDeletePictureClick={this.onDeletePictureClick}
                    />
                </PictureDataContainer>
                <ConfirmDialog
                    isOpen={this.state.isDeleteDialogOpen}
                    elementId={id}
                    onConfirm={this.onDeleteConfirm}
                    text='Are you sure you want to remove this photo?'
                />
            </Container>
        );
    }
}

const Buttons = ({ className, encodedPath, onDeletePictureClick }) => {
    return (
        <StyledInfoFooter className={className}>
            <a href={encodedPath} target='_blank' rel='noopener noreferrer'>
                <StyledIcon src={iconDownload} alt='' />
            </a>
            <a onClick={onDeletePictureClick}>
                <StyledIcon src={iconTrash} alt='' />
            </a>
        </StyledInfoFooter>
    );
};

const Container = styled(HorizontalDiv)`
    .high-b-margin {
        margin-bottom: 0.4rem;
    }

    .text-dark-gray {
        color: ${colors.darker_gray};
    }
`;

const StyledDateContainer = styled(VerticalDiv)`
    flex-shrink: 0;
    width: 75px;

    p {
        margin: 0;
    }
`;

const StyledLinePoint = styled(LinePoint)`
    flex-shrink: 0;
    width: 30px;
    height: auto;

    @media ${dimension.md} {
        width: 40px;
    }
`;

const PictureContainer = styled.div`
    width: 80vw;
    display: flex;
    background-color: ${colors.lighter_green};
    margin-bottom: 10px;
    padding: 10px;

    @media ${dimension.md} {
        width: 400px;
    }

    img {
        width: 100%;
        height: 100%;
        margin: auto;
    }
`;

const PictureDataContainer = styled.div`
    padding: 0 10px;
    margin-bottom: 20px;

    @media ${dimension.md} {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    p {
        margin-bottom: 0.2rem;
    }
`;

const StyledInfoContainer = styled.div`
    width: 60%;
    padding-right: 20px;

    & > * {
        word-break: break-all;
    }
`;

const StyledInfoFooter = styled(HorizontalDiv)`
    margin-bottom: 10px;
`;

const StyledIcon = styled.img`
    height: 15px;
    margin-right: 20px;
`;

const StyledPhotoName = styled.p.attrs({
    className: 'text-dark-gray high-b-margin'
})`
    word-break: break-all;
`;

const StyledTitle = styled.p.attrs({
    className: 'high-b-margin'
})`
    font-weight: bold;
`;
