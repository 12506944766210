const colors = {
    red: '#EF403E',
    dark_red: '#B11F26',
    light_red: '#FCCED5',
    lighter_red: '#FDEBEF',
    green: '#26B566',
    light_green: '#90D9B1',
    lighter_green: '#E6F6EC',
    dark_green: '#0B924D',
    gray: '#CCCCCC',
    light_gray: '#F5F5F5',
    dark_gray: '#979797',
    darker_gray: '#656565'
};

export default colors;
