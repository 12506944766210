import PropTypes from 'prop-types';
import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import styled from 'styled-components';
import { CANCEL, CONFIRM } from '../../../res/Constants';
import themeButton from '../../../res/styles/theme_button.module.scss';
import colors from '../../../res/styles/Colors';

PermissionDialog.propTypes = {
    isOpen: PropTypes.bool,
    zoneName: PropTypes.string,
    selectedSpecies: PropTypes.arrayOf(PropTypes.string),
    allSpecies: PropTypes.arrayOf(PropTypes.object),
    permissionData:  PropTypes.arrayOf(PropTypes.string),
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func
};

PermissionDialog.defaultProps = {
    isOpen: false,
    onConfirm: () => {},
    onCancel: () => {}
};

export default function PermissionDialog(props) {

    const [permissionData, setPermissionData] = React.useState(new Set(props.selectedSpecies.map((specie) => specie)));
    const [speciesCodesSelected, setSpeciesCodesSelected] = React.useState(new Set(props.selectedSpecies.map((specie) => specie)));
    const [isCheckedAll, setCheckedAll] = React.useState(props.allSpecies.length === props.selectedSpecies.length);

    const onConfirm = React.useCallback(() => {
        setPermissionData(speciesCodesSelected)
        props.onConfirm([...speciesCodesSelected]);
    }, [props.onConfirm, speciesCodesSelected]);

    const onCancel = React.useCallback(() => {
        props.onConfirm([...permissionData]);
    }, [props.onCancel]);

    const onSpecieSelected = (specie) => {
        const newSpecieCodesSelected = new Set(speciesCodesSelected);
        if (speciesCodesSelected.has(specie)) {
            newSpecieCodesSelected.delete(specie);
            setCheckedAll(false)
        } else {
            newSpecieCodesSelected.add(specie);
            setCheckedAll(newSpecieCodesSelected.size === props.allSpecies.size)
        }
        setSpeciesCodesSelected(newSpecieCodesSelected);
    };

    const onSelectAll = () => {
        setSpeciesCodesSelected( new Set(isCheckedAll ? []: props.allSpecies.map((specie) => specie.speciesCode)));
        setCheckedAll(!isCheckedAll)
    };

    return (
        <Modal style={{ maxWidth: '90vw', marginLeft: '5vw', marginRight: '5vw', marginTop: '5vw'}} 
         isOpen={props.isOpen}>
          <div style={{flexDirection: 'column', width: '100%'}}>
            <StyledModalBody>
                <DialogTitle>Zone {props.zoneName}</DialogTitle>
                <SubHeaderContainer>
                    <CheckboxContainer>
                        { isCheckedAll?
                        <img style={{height: "19px", width: "18px"}} 
                            src={require('../../../res/images/cac_selected_checkbox.png')} onClick={() => onSelectAll()}/>
                        : 
                        <img style={{height: "19px", width: "18px"}} 
                            src={require('../../../res/images/cac_unselected_checkbox.png')} onClick={() => onSelectAll()}/>
                        }
                        <StyleCheckBoxText onClick={onSelectAll}>Select all</StyleCheckBoxText>
                    </CheckboxContainer>
                    <StyledButtonContainerDiv>
                        <div style={{ width: "100%", justifyContent: "flex-end", display: "flex"}}>
                            <button className={themeButton.red} onClick={onCancel} style={{height: '30px'}}>
                                {CANCEL.toUpperCase()}
                            </button>

                            <button className={themeButton.green} onClick={onConfirm} style={{height: '30px'}}>
                                {CONFIRM.toUpperCase()}
                            </button>
                        </div>
                    </StyledButtonContainerDiv>
                </SubHeaderContainer>
            </StyledModalBody>
                <div style={{height: '1px', width: '100%', background: colors.gray, marginBottom: '5px'}}/>
                    <ScrollableDiv speciesLength={props.allSpecies.length}>
                        {props.allSpecies.map((specie) => (
                            <SpeciesItem
                                data={specie}
                                key={specie.pk}
                                isSelected={speciesCodesSelected.has(specie.speciesCode) || isCheckedAll }
                                onSpecieSelected={onSpecieSelected}/>
                        ))}
                    </ScrollableDiv>
                <div style={{height: '100px', width: '100%', marginBottom: '5px'}}/> 
          </div>
        </Modal>
    );
}

function SpeciesItem(props) {
    return (
        <CheckboxItemContainer>
            {props.isSelected ? 
            <img src={require('../../../res/images/cac_selected_checkbox.png')} onClick={() => props.onSpecieSelected(props.data.speciesCode)}/>
            : 
            <img src={require('../../../res/images/cac_unselected_checkbox.png')} onClick={() => props.onSpecieSelected(props.data.speciesCode)}/>
            }
            <p>{props.data.speciesName}</p>
        </CheckboxItemContainer>
    );
}

const DialogTitle = styled.p`
    width: 20%;
    font-size: 24px;
    font-weight: bold;
    color: black;
    margin-left: 5%;
    margin-top: 5%;

    @media (max-width: 768px){
        width: 100%;
        margin-left: 40%;
    }
`;

const SubHeaderContainer = styled.div`
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: center;

    @media (max-width: 1024px){
        width: 80%;
    }

    @media (max-width: 768px){
        width: 100%;
    }

    @media (max-width: 375px){
        flex-direction: column-reverse;
        flex-wrap: wrap;
        margin-left: -20px;
    }
`;

const StyledModalBody = styled(ModalBody)`
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    button {
        width: 150px;
    }

    @media (max-width: 768px){
        width: 100%;
        flex-wrap: wrap;
    }
`;

const CheckboxContainer = styled.div`
    width: 150px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 5%;

    @media (max-width: 375px){
        width: 100%;
    }
`;

const StyleCheckBoxText = styled.p`
    font-size: 14px;
    font-weight: bold;
    color: ${colors.green};
    margin-left: 20%;
`;

const StyledButtonContainerDiv = styled.div`
    width: 50%;
    margin-top: 5%;
    margin-left: 10%;

    button {
        width: 120px;
        margin-left: 5%;
    }

    @media (max-width: 1024px){
        margin-left: 10%;
    }

    @media (max-width: 768px){
        margin-left: 5%;
    }

    @media (max-width: 375px){
        width: 100%;
    }
`;

const ScrollableDiv = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    overflow-y: auto;

    @media (min-width: 768px){
        height: ${props => 39 + (props.speciesLength / 4) * 40}px;
    }

    @media (max-width: 768px){
        height: ${props => (props.speciesLength / 3) * 40}px;
    }

    @media (max-width: 576px){
        height: ${props => (props.speciesLength / 2) * 40}px;
    }

    @media (max-width: 375px){
        height: ${props => props.speciesLength * 40}px;
    }
`;

const CheckboxItemContainer = styled.div`
    width: 150px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 5%;
    height: 40px;

    @media (min-width: 768px){
        width: 200px;
    }

    @media (max-width: 768px){
        width: 30%;
    }

    @media (max-width: 576px){
        width: 50%;
    }

    @media (max-width: 375px){
        width: 100%;
        justify-content: flex-start;
        margin-left: 15%;
    }

    p {
        font-size: 14px;
        margin-left: 30px;
        margin-top: 22px;
    }

    span {
        justify-content: center;
    }
     
`;