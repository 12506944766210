import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import styled from 'styled-components';
import Checkbox from '../../../../components/Checkbox';
import { VerticalDiv } from '../../../../components/Common';
import dimension from '../../../../res/styles/Responsive';
import themeButton from '../../../../res/styles/theme_button.module.scss';
import { downloadPhotosByIds } from '../../../../service/PhotoService';
import colors from './../../../../res/styles/Colors';
import { deletePhoto } from './../../../../service/PhotoService';
import { toastError } from './../../../../ui/ToastHelper';
import PictureCell from './PictureCell';
import UploadDialog from './UploadDialog';

export default class PicturesPane extends PureComponent {
    static propTypes = {
        techFormData: PropTypes.object
    };

    state = {
        photosSelected: new Set(),
        photoIdsDeleted: new Set(),
        photoUploadVisible: false,
        selectAll: false
    };

    onPhotoSelected = (photoId, isSelected) => {
        this.setState((state) => {
            const newPhotosSelected = new Set([...state.photosSelected]);
            if (isSelected) {
                newPhotosSelected.add(photoId);
            } else {
                newPhotosSelected.delete(photoId);
            }
            const newSelectAll =
                newPhotosSelected.size === this.props?.techFormData?.photosList?.length;
            return {
                photosSelected: newPhotosSelected,
                selectAll: newSelectAll
            };
        });
    };

    onPhotoDeleted = (photoId) => {
        deletePhoto(
            photoId,
            () =>
                this.setState((state) => {
                    const newPhotosDeleted = new Set([...state.photoIdsDeleted]);
                    newPhotosDeleted.add(photoId.toString());
                    return {
                        photoIdsDeleted: newPhotosDeleted
                    };
                }),
            () => {
                toastError('An error occurred.');
            }
        );
    };

    togglePhotoUploadVisibility = () => {
        this.setState((state) => ({
            photoUploadVisible: !state.photoUploadVisible
        }));
    };

    onSelectAll = () => {
        if (!this.state.selectAll && this.props.techFormData?.photosList?.length > 0) {
            const newPhotosSelected = new Set(
                this.props.techFormData.photosList.map((p) => p.id.toString())
            );
            this.setState({ photosSelected: newPhotosSelected, selectAll: true });
        } else if (this.state.selectAll) {
            this.setState({ photosSelected: new Set(), selectAll: false });
        }
    };

    onPhotoDownload = () => {
        if (this.state.photosSelected.size === 0) {
            toastError('No selected pictures.');
        } else {
            downloadPhotosByIds(Array.from(this.state.photosSelected), (error) =>
                toastError(error || 'Server error.')
            );
        }
    };

    render() {
        const photosList = this.props.techFormData?.photosList ?? [];
        const pictures =
            photosList.length > 0 ? (
                photosList
                    .filter((photo) => !this.state.photoIdsDeleted.has(photo.id.toString()))
                    .map((photo) => (
                        <PictureCell
                            key={photo.name}
                            pictureData={photo}
                            isPhotoSelected={this.state.photosSelected.has(photo.id.toString())}
                            onPhotoDeleted={this.onPhotoDeleted}
                            onPhotoSelected={this.onPhotoSelected}
                        />
                    ))
            ) : (
                <p style={{ textAlign: 'center' }}>No pictures found</p>
            );

        return (
            <StyledDiv>
                <StyledButtonsContainer>
                    <StyledButtonWithImage
                        className={themeButton.green}
                        onClick={this.togglePhotoUploadVisibility}>
                        <img src={require('../../../../res/images/cac_camera.png')} alt='upload' />
                        UPLOAD PHOTO
                    </StyledButtonWithImage>
                    <div className='d-space' />
                    <StyledButtonWithImage
                        className={themeButton.green}
                        onClick={this.onPhotoDownload}>
                        <img
                            src={require('../../../../res/images/cac_download_btn.png')}
                            alt='download'
                        />
                        DOWNLOAD SEL.
                    </StyledButtonWithImage>
                </StyledButtonsContainer>
                <StyledSelectAllContainer>
                    <Checkbox
                        isHorizontal
                        isEditable
                        isSelected={this.state.selectAll}
                        text='Select all'
                        onChange={this.onSelectAll}
                    />
                </StyledSelectAllContainer>
                <StyledPicturesContainer>{pictures}</StyledPicturesContainer>
                {this.props.techFormData && (
                    <UploadDialog
                        isVisible={this.state.photoUploadVisible}
                        toggleVisibility={this.togglePhotoUploadVisibility}
                        techFormData={this.props.techFormData}
                    />
                )}
            </StyledDiv>
        );
    }
}

const StyledButtonsContainer = styled.div`
    width: 90%;
    display: inline-flex;
    margin: auto;
    justify-content: center;

    .d-space {
        min-width: 30px;

        @media ${dimension.md} {
            width: 100px;
        }
    }
`;

const StyledSelectAllContainer = styled.div`
    margin: 20px 40px auto auto;
    color: ${colors.green};

    @media ${dimension.md} {
        margin-top: 0;
    }
`;

const StyledButtonWithImage = styled.button`
    width: 100%;
    padding-right: 10px;

    img {
        height: 15px;
        margin-right: 10px;
    }

    @media ${dimension.md} {
        width: 200px;
    }
`;

const StyledPicturesContainer = styled.div`
    padding: 0;

    @media ${dimension.md} {
        padding: 30px;
    }
`;

const StyledDiv = styled(VerticalDiv)`
    background-color: white;
    margin: auto;
    padding: 20px 0;
    padding-bottom: 20px;
`;
