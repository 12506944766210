/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { HTTP_CODE } from '../res/Constants';

const CLIENTS_API = '/clients';
const UPDATE_CLIENT_REPORT_DATA = `${CLIENTS_API}/report/data`;
const SEND_PUSH_MESSAGE = `${CLIENTS_API}/push`;
const CLIENTS_STORAGE = 'clients';

export function getClients(callback) {
    const cachedClients = sessionStorage.getItem(CLIENTS_STORAGE);
    if (cachedClients != null) {
        return callback(JSON.parse(cachedClients));
    }
    axios({ method: 'get', url: CLIENTS_API })
        .then((result) => {
            if (result.status === HTTP_CODE.OK && result.data != null) {
                sessionStorage.setItem(CLIENTS_STORAGE, JSON.stringify(result.data));
            }
            callback(result.data);
        })
        .catch(() => {
            callback([]);
        });
}

export function saveClient(client, onSuccessCallback, onFailureCallback) {
    axios({
        method: 'post',
        url: CLIENTS_API,
        data: client
    })
        .then((result) => {
            if (result.status === HTTP_CODE.OK) {
                // invalidate cached clients
                sessionStorage.removeItem(CLIENTS_STORAGE);
                if (onSuccessCallback != null) onSuccessCallback();
            } else {
                if (onFailureCallback != null) onFailureCallback();
            }
        })
        .catch((error) => {
            console.log(error);
            if (onFailureCallback != null) onFailureCallback();
        });
}

export function updateClientReportData(clientList, callback) {
    axios({ method: 'post', url: UPDATE_CLIENT_REPORT_DATA, data: clientList })
        .then((result) => {
            callback(result.data);
        })
        .catch(() => {
            callback(null);
        });
}

/**
 *
 * @param {string} pushMessage
 * @param {number[]} clientCodes
 */
export function sendPushNotification(
    pushMessage,
    clientCodes,
    onSuccessCallback,
    onFailureCallback
) {
    axios({
        method: 'post',
        url: SEND_PUSH_MESSAGE,
        data: {
            message: pushMessage,
            clientCodes
        }
    })
        .then((result) => {
            if (result.status === HTTP_CODE.OK) {
                onSuccessCallback && onSuccessCallback(result.data);
            } else {
                onFailureCallback && onFailureCallback();
            }
        })
        .catch(() => {
            onFailureCallback && onFailureCallback();
        });
}
