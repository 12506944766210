/* eslint-disable global-require */
import axios from 'axios';
import FileSaver from 'file-saver';
import { CAC_TYPE } from '../res/Constants';
import { removeNullProperties } from '../utils/Utils';
import { getCurrentUserDataAsync } from './AccountService';
import { loadCacType } from './CacTypeService';
import { EXCEL_EXTENSION, MIME_TYPE_EXCEL } from './ServiceConstants';

const GET_TECHFORM_LIST = '/techforms';
const GET_TECHFORM_LIST_SIZE = `${GET_TECHFORM_LIST}/size`;
const DOWNLOAD_EXCEL = `${GET_TECHFORM_LIST}/export`;

export async function getTechformListSize(filters) {
    const currentCacType = loadCacType();
    return (
        await axios({
            method: 'get',
            url: GET_TECHFORM_LIST_SIZE,
            params: await createParamsForTechformList(filters, currentCacType)
        })
    ).data;
}

export async function getTechformList(cacType, filters) {
    const filtersByType = cacType === CAC_TYPE.CROP ? filters.crop : filters.nursery;
    if (cacType == null || filtersByType == null) return null;

    const params = await createParamsForTechformList(filtersByType, cacType);
    const { data } = await axios({
        method: 'get',
        url: GET_TECHFORM_LIST,
        params
    });
    return data;
}

async function createParamsForTechformList(filters, currentCacType) {
    const params = removeNullProperties({ ...filters, type: currentCacType });
    const userData = await getCurrentUserDataAsync();
    if (params.clientCode == null && userData.client != null) {
        params.clientCode = userData.client.code;
    }
    return params;
}

export function downloadExcel(filters, lines, onSuccessCallback, onErrorCallback) {
    console.log('FILTERS', filters);
    if (filters === null) {
        console.log('filters are null');
        onErrorCallback('No filters selected.');
        return;
    }
    axios({
        method: 'get',
        url: DOWNLOAD_EXCEL,
        responseType: 'blob',
        params: {
            format: EXCEL_EXTENSION,
            lines,
            ...filters
        }
    })
        .then((response) => {
            const blob = new Blob([response.data], {
                type: MIME_TYPE_EXCEL
            });
            const fileName = `crop-reportworksheet-${lines}.${EXCEL_EXTENSION}`;
            FileSaver.saveAs(blob, fileName);
            onSuccessCallback && onSuccessCallback();
        })
        .catch((error) => onErrorCallback && onErrorCallback(error));
}
