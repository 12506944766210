import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { HorizontalDiv } from '../../components/Common';
import Dropdown from '../../components/Dropdown';
import NavigationBar from '../../components/NavigationBar/NavigationBar';
import TitleBar from '../../components/TitleBar';
import { BOLT, DEVELOP, SANITY, STAND } from '../../res/Constants';
import buttonTheme from '../../res/styles/theme_button.module.scss';
import Checkbox from './../../components/Checkbox';
import { VerticalDiv } from './../../components/Common';
import colors from './../../res/styles/Colors';
import dimension from './../../res/styles/Responsive';
import { getSettingList, saveSettings } from './../../service/SettingService';
import { toastError } from './../../ui/ToastHelper';
import { ControlPanelPage, Input } from './components/Common';

const standKey = `${STAND}Threshold`;
const developKey = `${DEVELOP}Threshold`;
const boltKey = `${BOLT}Threshold`;
const sanityKey = `${SANITY}Threshold`;
const settingKeys = [standKey, developKey, boltKey, sanityKey];

const developOptions = ['1', '2', '3', '4'];
const TRUE_VALUE = '1';
const FALSE_VALUE = '0';

export default function AlertParameters() {
    const history = useHistory();
    const [settings, setSettings] = React.useState(null);

    React.useEffect(() => {
        async function fetchSettings() {
            const fetchedSettings = await getSettingList(settingKeys);
            const settingsObject = {};
            fetchedSettings.forEach((setting) => (settingsObject[setting.key] = setting.value));
            setSettings(settingsObject);
        }
        fetchSettings();
    }, []);

    const onChangeValue = (fieldKey, newValue) => {
        setSettings({ ...settings, [fieldKey]: newValue });
    };

    const onSave = () => {
        const settingsToSave = Object.entries(settings).map((entry) => ({
            key: entry[0],
            value: entry[1]
        }));
        saveSettings(
            settingsToSave,
            () => {
                history.goBack();
            },
            () => {
                toastError('An error occurred');
            }
        );
    };

    return (
        <div>
            <NavigationBar />
            <TitleBar title='Edit Alert Parameters' />
            <ControlPanelPage>
                <StyledContainer>
                    <HorizontalDiv style={{ marginBottom: '0px' }}>
                        <StyledTitle>Parameter</StyledTitle>
                        <StyledTitle>Value</StyledTitle>
                    </HorizontalDiv>
                    {settings && (
                        <>
                            <HorizontalDiv>
                                <StyledParameterName>{STAND}</StyledParameterName>
                                <InputNumber
                                    defaultValue={settings[standKey]}
                                    onChange={(e) => onChangeValue(standKey, e.target.value)}
                                />
                            </HorizontalDiv>
                            <HorizontalDiv>
                                <StyledParameterName>{DEVELOP}</StyledParameterName>
                                <Dropdown
                                    id='develop'
                                    options={developOptions}
                                    emptyOption={false}
                                    defaultValue={parseInt(settings[developKey])}
                                    onChange={(value) => onChangeValue(developKey, value)}
                                />
                            </HorizontalDiv>
                            <HorizontalDiv>
                                <StyledParameterName>{BOLT}</StyledParameterName>
                                <InputNumber
                                    defaultValue={settings[boltKey]}
                                    onChange={(e) => onChangeValue(boltKey, e.target.value)}
                                />
                            </HorizontalDiv>
                            <HorizontalDiv>
                                <StyledParameterName>{SANITY}</StyledParameterName>
                                <Checkbox
                                    isEditable
                                    isSelected={settings[sanityKey] === TRUE_VALUE}
                                    onChange={(_, value) =>
                                        onChangeValue(
                                            sanityKey,
                                            value === true ? TRUE_VALUE : FALSE_VALUE
                                        )
                                    }
                                />
                            </HorizontalDiv>
                            <StyledButtonSave onClick={onSave}>
                                SAVE NEW PARAMETERS
                            </StyledButtonSave>
                        </>
                    )}
                </StyledContainer>
            </ControlPanelPage>
        </div>
    );
}

function InputNumber(props) {
    return <Input type='number' defaultValue={props.defaultValue} onChange={props.onChange} />;
}

const StyledTitle = styled.p`
    text-align: center;
    width: 150px;
    color: ${colors.green};

    @media ${dimension.md} {
        width: 200px;
    }
`;

const StyledParameterName = styled.p`
    text-align: center;
    width: 150px;
    margin: 0;
    color: ${colors.darker_gray};
    @media ${dimension.md} {
        width: 200px;
    }
`;

const StyledButtonSave = styled.button.attrs({
    className: buttonTheme.green
})`
    width: 80%;
    margin: 20px auto 0 auto;
`;

const StyledContainer = styled(VerticalDiv)`
    margin: 0 auto;

    & > div {
        margin: 10px 0;
    }

    input {
        width: 150px;
        margin: auto;
    }

    select {
        width: 150px;
        margin: auto;
        height: 30px;
        padding-left: 5px;
    }

    label {
        width: 150px;
        margin-right: auto;
        margin-left: auto;
    }
`;
