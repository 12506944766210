import React from 'react';
import styled from 'styled-components';
import { VerticalDiv } from '../../../components/Common';
import colors from '../../../res/styles/Colors';
import dimension from '../../../res/styles/Responsive';
import TechItem from './TechItem';

export default function TechList({ techFormList, onItemClick }) {
    return (
        <TechListContainer>
            {techFormList &&
                techFormList.map((item) => (
                    <TechItem
                        className='item'
                        key={item.techFormPk}
                        techFormData={item}
                        onItemClick={onItemClick}
                    />
                ))}
        </TechListContainer>
    );
}

const TechListContainer = styled(VerticalDiv).attrs({
    className: 'list-container'
})`
    background-color: ${colors.light_gray};
    margin: auto;
    width: 95%;

    @media ${dimension.md} {
        margin: 20px 20px 0 0;
    }
`;
