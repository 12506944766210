import axios from 'axios';
import { saveAs } from 'file-saver';
import { HTTP_CODE } from '../res/Constants';
import { canReadExtraReport } from './PermissionService';
import { EXCEL_EXTENSION, MIME_TYPE_EXCEL } from './ServiceConstants';

const BASE_URL = '/report/extra';
const GET_EXTRA_REPORTS = `${BASE_URL}/list`;
const READ_EXTRA_REPORT = `${BASE_URL}/read`;
const UNREAD_EXTRA_REPORTS = `${BASE_URL}/unread`;

let extraReportPksRead = new Set();

export function resetReportPksRead() {
    extraReportPksRead = new Set();
}

/**
 *
 * @param {number} year
 * @param {Function(Object[])} callback
 */
export function getExtraReportsByYear(year, callback) {
    axios({
        method: 'get',
        url: GET_EXTRA_REPORTS,
        params: {
            year
        }
    })
        .then((result) => {
            if (result.data == null) {
                callback([]);
                return;
            }
            callback(result.data);
        })
        .catch((error) => {});
}

export function readExtraReport(extraReportPk, onSuccessCallback) {
    if (canReadExtraReport() && !extraReportPksRead.has(extraReportPk)) {
        axios({
            method: 'post',
            url: READ_EXTRA_REPORT,
            params: {
                id: extraReportPk
            }
        }).then((result) => {
            if (result.status === 200) {
                extraReportPksRead.add(extraReportPk);
                if (onSuccessCallback != null) onSuccessCallback();
            }
        });
    }
}

export function getUnreadExtraReportsCount(onSuccessCallback) {
    axios.get(UNREAD_EXTRA_REPORTS).then((result) => {
        if (result.status === 200) onSuccessCallback(result.data);
    });
}

/**
 * @param {number[]} extraReportPks
 * @param {function} onSuccessCallback
 * @param {function} onFailureCallback
 */
export function exportExtraReportList(extraReportPks, onSuccessCallback, onFailureCallback) {
    axios({
        method: 'GET',
        url: GET_EXTRA_REPORTS,
        responseType: 'blob',
        params: {
            ids: extraReportPks.toString(),
            format: EXCEL_EXTENSION
        }
    })
        .then((result) => {
            if (result.status === HTTP_CODE.OK) {
                const blob = new Blob([result.data], {
                    type: MIME_TYPE_EXCEL
                });
                saveAs(blob, `cralert.${EXCEL_EXTENSION}`);
                if (onSuccessCallback != null) onSuccessCallback();
            }
        })
        .catch(() => {
            if (onFailureCallback != null) {
                onFailureCallback();
            }
        });
}
