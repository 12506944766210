import PropTypes from 'prop-types';
import React from 'react';
import { isMobileOnly } from 'react-device-detect';
import { Row } from 'reactstrap';
import styled from 'styled-components';
import { ColNoPadding } from '../../../../../components/Common';
import ExpandIcon from '../../../../../components/ExpandIcon';
import InfoText from '../../../../../components/InfoText';
import ListItem from '../../../../../components/ListItem';
import { A, B, CAC_TYPE, OP } from '../../../../../res/Constants';
import { GreenColumn, GreenTimeLine, RedColumn, RedTimeLine, TitleRow } from './Common';

GreenRedTimeLine.propTypes = {
    cacType: PropTypes.number.isRequired,
    title: PropTypes.string,
    lines: PropTypes.shape({
        lineA: PropTypes.shape({
            title: PropTypes.string,
            dates: PropTypes.arrayOf(
                PropTypes.shape({
                    name: PropTypes.string,
                    id: PropTypes.string,
                    date: PropTypes.number
                })
            ),
            extraContent: PropTypes.shape({
                name: PropTypes.string,
                content: PropTypes.string
            })
        }).isRequired,
        lineB: PropTypes.shape({
            title: PropTypes.string,
            dates: PropTypes.arrayOf(
                PropTypes.shape({
                    name: PropTypes.string,
                    id: PropTypes.string,
                    date: PropTypes.number
                })
            ),
            extraContent: PropTypes.shape({
                name: PropTypes.string,
                content: PropTypes.string
            })
        }).isRequired,
        canBeEditable: PropTypes.bool
    }),
    lineCount: PropTypes.number,
    onSave: PropTypes.func,
    onDateEdited: PropTypes.func
};

function GreenRedTimeLine({
    cacType,
    title,
    lines,
    lineCount,
    canBeEditable = false,
    onDateEdited,
    onSave
}) {
    const onDateEditedParser = (line, id, date) => {
        if (onDateEdited) onDateEdited(line, id, date);
    };

    return lines == null ? null : isMobileOnly ? (
        <MobileLayout
            cacType={cacType}
            title={title}
            lines={lines}
            lineCount={lineCount}
            canBeEditable={canBeEditable}
            onDateEdited={onDateEditedParser}
            onSave={onSave}
        />
    ) : (
        <DesktopLayout
            cacType={cacType}
            lines={lines}
            lineCount={lineCount}
            canBeEditable={canBeEditable}
            onDateEdited={onDateEditedParser}
            onSave={onSave}
        />
    );
}

const DesktopLayout = ({ cacType, lines, lineCount, canBeEditable, onDateEdited, onSave }) => {
    return (
        <Row>
            <GreenColumn md='5'>
                <ListItem>
                    {() => (
                        <Line
                            line={lineCount > 1 ? A : OP}
                            lineData={lines.lineA}
                            canBeEditable={canBeEditable}
                            onDateEdited={(id, date) => onDateEdited(A, id, date)}
                            onSave={onSave}
                        />
                    )}
                </ListItem>
            </GreenColumn>
            {cacType === CAC_TYPE.CROP && lineCount > 1 && (
                <RedColumn
                    md={{
                        size: 5,
                        offset: 1
                    }}>
                    <ListItem>
                        {() => (
                            <Line
                                line={B}
                                lineData={lines.lineB}
                                canBeEditable={canBeEditable}
                                onDateEdited={(id, date) => onDateEdited(B, id, date)}
                                onSave={onSave}
                            />
                        )}
                    </ListItem>
                </RedColumn>
            )}
        </Row>
    );
};

const MobileLayout = ({
    cacType,
    title,
    lines,
    lineCount,
    canBeEditable,
    onDateEdited,
    onSave
}) => {
    const expandableChildren = (
        <Row>
            <GreenColumn>
                <Line
                    line={lineCount > 1 ? A : OP}
                    lineData={lines.lineA}
                    canBeEditable={canBeEditable}
                    onDateEdited={(id, date) => onDateEdited(A, id, date)}
                    onSave={onSave}
                />
            </GreenColumn>
            {cacType === CAC_TYPE.CROP && lineCount > 1 && (
                <RedColumn>
                    <Line
                        line={B}
                        lineData={lines.lineB}
                        canBeEditable={canBeEditable}
                        onDateEdited={(id, date) => onDateEdited(B, id, date)}
                        onSave={onSave}
                    />
                </RedColumn>
            )}
        </Row>
    );

    return (
        <StyledListItem expandableChildren={expandableChildren}>
            {(renderProps) => (
                <>
                    <TitleRow>
                        <ColNoPadding xs='8' md='12'>
                            <h5>{title}</h5>
                        </ColNoPadding>
                        <ColNoPadding className='d-md-none' xs='auto'>
                            <ExpandIcon onClick={renderProps.toggleExpand} />
                        </ColNoPadding>
                    </TitleRow>
                </>
            )}
        </StyledListItem>
    );
};

const Line = ({ line, lineData, canBeEditable, onSave, onDateEdited }) => {
    return (
        <>
            <h5>{`Line ${line}`}</h5>
            {line === A || line === OP ? (
                <GreenTimeLine
                    title={lineData.title}
                    dates={lineData.dates}
                    canBeEditable={canBeEditable}
                    onSave={() => onSave && onSave(A)}
                    onDateEdited={onDateEdited}
                />
            ) : (
                <RedTimeLine
                    title={lineData.title}
                    dates={lineData.dates}
                    canBeEditable={canBeEditable}
                    onSave={() => onSave && onSave(B)}
                    onDateEdited={onDateEdited}
                />
            )}
            {lineData.extraContent && (
                <StyledInfotext
                    title={lineData.extraContent.name}
                    content={lineData.extraContent.content}
                />
            )}
        </>
    );
};

const StyledListItem = styled(ListItem)`
    .inner-container-expandable {
        padding: 0;
    }
`;

const StyledInfotext = styled(InfoText)`
    padding-bottom: 15px;
`;

export default GreenRedTimeLine;
