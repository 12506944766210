import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';
import { EmptyDiv, HorizontalDiv, ImgButton, VerticalDiv } from '../../../../../components/Common';
import ListItem from '../../../../../components/ListItem';
import { canEditGrowing } from '../../../../../service/PermissionService';
import { updateTechFormNotes } from '../../../../../service/TechFormDetailService';
import EditableInfoText from '../../CrAlerts/EditableInfoText';

const saveIcon = require('../../../../../res/images/cac_save_icon.png');
const cancelIcon = require('../../../../../res/images/cac_cancel_icon.png');

GeneralRemarks.propTypes = {
    className: PropTypes.string,
    techFormData: PropTypes.shape({
        notes: PropTypes.string,
        techFormPk: PropTypes.number
    })
};

function GeneralRemarks({ className, techFormData }) {
    const initialContent = techFormData?.notes ?? '';
    const [isContentEditable, setIsContentEditable] = useState(false);
    const [content, setContent] = useState(initialContent);
    const [latestSavedContent, setLatestSavedContent] = useState(initialContent);

    const onSave = () => {
        setIsContentEditable(false);
        if (latestSavedContent !== content) {
            const { techFormPk } = techFormData;
            updateTechFormNotes(content, techFormPk, (result) => {
                if (result) {
                    setLatestSavedContent(content);
                }
            });
        }
    };

    const onCancel = () => {
        setContent(latestSavedContent);
        setIsContentEditable(false);
    };

    return (
        <div className={className}>
            <ListItem>
                {() => (
                    <VerticalDiv>
                        <ContainerDiv>
                            <VerticalDiv>
                                <h5>General Remarks</h5>
                            </VerticalDiv>
                            {isContentEditable ? (
                                <HorizontalDiv>
                                    <ImgButton
                                        src={saveIcon}
                                        alt='save the general remarks'
                                        onClick={onSave}
                                    />
                                    <EmptyDiv width='20px' />
                                    <ImgButton
                                        src={cancelIcon}
                                        alt='cancel the editing'
                                        onClick={onCancel}
                                    />
                                </HorizontalDiv>
                            ) : (
                                canEditGrowing() && (
                                    <ImgButton
                                        src={require('../../../../../res/images/cac_edit_green.png')}
                                        alt='edit the general remarks'
                                        onClick={() => setIsContentEditable(true)}
                                    />
                                )
                            )}
                        </ContainerDiv>
                        <EditableInfoText
                            isEditable={isContentEditable}
                            initialValue={content}
                            rows={3}
                            onFieldEdited={(_, text) => setContent(text)}
                        />
                    </VerticalDiv>
                )}
            </ListItem>
        </div>
    );
}

const ContainerDiv = styled(HorizontalDiv)`
    justify-content: space-between;
`;

export default GeneralRemarks;
