import get from 'lodash/get';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { AppContext } from '../../../../App';
import { canEditReport } from '../../../../service/PermissionService';
import { createEmptyReport, saveReport } from '../../../../service/ReportService';
import DialogEditing from './components/DialogEditing';
import ReportItem from './components/ReportItem';

ReportsPane.propTypes = {
    techFormData: PropTypes.shape({
        reportsList: PropTypes.array,
        line: PropTypes.number
    }),
    isCreatingReport: PropTypes.bool,
    onReportCreationFinished: PropTypes.func
};

ReportsPane.defaultProps = {
    techFormData: {
        reportsList: [],
        line: 0
    },
    isCreatingReport: false
};

export default function ReportsPane({ techFormData, isCreatingReport, onReportCreationFinished }) {
    const appContext = React.useContext(AppContext);
    const [isEditingReport, setIsEditingReport] = useState(false);

    const [reportList, setReportList] = useState(null);
    useEffect(() => setReportList(techFormData != null ? techFormData.reportsList : []), [techFormData]);

    const [editedReport, setEditedReport] = useState(null);

    useEffect(() => {
        if (isCreatingReport === true) {
            const newReport = get(reportList, '0') == null ? createEmptyReport() : reportList[0];
            setEditedReport({ ...newReport, pk: null, date: null });
            setIsEditingReport(true);
        }
    }, [isCreatingReport, reportList]);

    const onSaveReport = (linesData) => {
        setIsEditingReport(false);
        saveReport(editedReport.pk, techFormData, linesData, (savedReport) => {
            let newList = [...reportList];
            if (editedReport.pk != null) {
                // update existing report in the list
                newList = reportList.map((report) =>
                    report.pk === savedReport.pk ? savedReport : report
                );
            } else {
                // add the new report in front of the list
                newList.unshift(savedReport);
            }
            setReportList(newList);
        });
        onReportCreationFinished();
    };

    const onCancelEditing = () => {
        setIsEditingReport(false);
        onReportCreationFinished();
    };

    const onReportEdit = (index) => {
        setEditedReport(reportList[index]);
        setIsEditingReport(true);
    };

    const dialogOpen = isEditingReport || isCreatingReport;

    return (
        <>
            <div className='list-container'>
                {reportList &&
                    reportList.map((report, index) => (
                        <ReportItem
                            className='item'
                            linesCount={techFormData.line}
                            reportData={report}
                            cacType={appContext.cacType}
                            isEditable={canEditReport(index, report.techId)}
                            onEdit={() => onReportEdit(index)}
                            key={index}
                        />
                    ))}
            </div>
            {dialogOpen && techFormData != null && (
                <DialogEditing
                    isOpen
                    reportData={editedReport}
                    cacType={appContext.cacType}
                    linesCount={techFormData.line}
                    onSave={onSaveReport}
                    onCancel={onCancelEditing}
                />
            )}
        </>
    );
}
