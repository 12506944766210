import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import ReactToPrint from 'react-to-print';
import PrintableCrAlert from './PrintableCrAlert/PrintableCrAlert';

let tmpTitle;

class CrAlertPrinter extends PureComponent {
    static propTypes = {
        crAlert: PropTypes.object.isRequired
    };

    onBeforePrint = () => {
        tmpTitle = document.title;
        document.title = this.props.crAlert.title;
    };

    onAfterPrint = () => {
        document.title = tmpTitle;
    };

    render() {
        return (
            <>
                <ReactToPrint
                    trigger={() => this.props.children}
                    content={() => this.componentRef}
                    onBeforePrint={this.onBeforePrint}
                    onAfterPrint={this.onAfterPrint}
                />
                <PrintableCrAlert
                    ref={(el) => (this.componentRef = el)}
                    crAlert={this.props.crAlert}
                />
            </>
        );
    }
}

export default CrAlertPrinter;
