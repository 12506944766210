import * as _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import {
    EmptyDiv,
    FlexDiv,
    HorizontalDiv,
    ImgButton,
    VerticalDiv
} from '../../../components/Common';
import Dropdown from '../../../components/Dropdown';
import dimension from '../../../res/styles/Responsive';
import buttonTheme from '../../../res/styles/theme_button.module.scss';
import { getName } from '../../../service/VisitService';
import { toastWarning } from '../../../ui/ToastHelper';
import { timeWithoutSeconds } from '../../../utils/DateFormatter';
import { PanelFieldTitle } from '../../ControlPanel/components/Common';
import { MaterialDatePicker } from '../Components/Common';
import StaffInput from '../Components/StaffInput';

VisitItemEditing.propTypes = {
    clients: PropTypes.array,
    staffList: PropTypes.array,
    visit: PropTypes.object,
    onCancel: PropTypes.func,
    onSave: PropTypes.func,
    onDelete: PropTypes.func
};

export default function VisitItemEditing(props) {
    const [visit, setVisit] = React.useState(props.visit);
    const [hasPendingRequest, setPendingRequest] = React.useState(false);

    React.useEffect(() => {
        setVisit(visit);
    }, [props.visit]);

    const onFieldChange = (e) => {
        const name = e?.target?.name;
        let value = e?.target?.value;

        if (name != null && value != null) {
            if (name === 'date') value = moment(value).valueOf();
            setVisit((curr) => ({ ...curr, [name]: value }));
        }
    };

    const onClientChange = (clientName) => {
        const selClient = props?.clients.find((c) => c.code === clientName) || null;
        setVisit((curr) => ({ ...curr, client: selClient }));
    };

    const onStaffAdd = (name) => {
        if (name == null || name === '') {
            return toastWarning('Staff not selected.');
        }
        const userFromStaff = props.staffList.find((u) => getName(u) === name);
        if (visit.users.includes(userFromStaff) || visit.otherUsers.includes(name)) {
            return toastWarning('User already assigned.');
        }
        if (userFromStaff != null) {
            setVisit((curr) => ({ ...curr, users: [...curr.users, userFromStaff] }));
        } else {
            setVisit((curr) => ({ ...curr, otherUsers: [...curr.otherUsers, name] }));
        }
    };

    const onRemoveAssignedUser = (name) => {
        const userFromStaff = visit?.users?.find((u) => getName(u) === name);
        if (userFromStaff != null) {
            setVisit((curr) => ({ ...curr, users: _.without(curr.users, userFromStaff) }));
        } else {
            setVisit((curr) => ({
                ...curr,
                otherUsers: _.without(curr.otherUsers, name)
            }));
        }
    };

    const onSave = () => {
        if (visit?.date == null || isNaN(visit?.date)) {
            return toastWarning('Date is required.');
        }
        setPendingRequest(true);
        props.onSave(visit);
    };

    const defaultClient = _.find(props.clients, { 'code': visit?.client?.code })?.code;

    const clientsOptions = React.useMemo(() => {
        return props.clients.map((c) => c.code);
    }, [props.clients]);

    const staffOptions = React.useMemo(() => {
        const alreadyAssignedNames = visit?.users?.map((u) => u?.username);
        const staffNames = props.staffList.map((u) => getName(u));
        return _.difference(staffNames, alreadyAssignedNames);
    }, [props, visit]);

    return (
        <div style={{ backgroundColor: 'white', padding: '15px' }}>
            <FlexDiv justifyContent='space-between' style={{ paddingBottom: '20px' }}>
                <HorizontalDiv>
                    <h5 style={{ marginBottom: '0' }}>DATE</h5>
                    <EmptyDiv width='20px' />
                    <MaterialDatePicker
                        name='date'
                        value={moment(visit.date).format(moment.HTML5_FMT.DATE)}
                        onChange={onFieldChange}
                    />
                </HorizontalDiv>
                {props.visit.pk > 0 && (
                    // hide delete button for cloning operation
                    <ImgButton
                        src={require('../../../res/images/cac_trash_visits.png')}
                        onClick={props.onDelete}
                    />
                )}
            </FlexDiv>
            <div style={{ display: 'grid', gridTemplateColumns: '3fr 1fr' }}>
                <StyledInputsContainer>
                    <VisitItemEditingInput
                        className={'material-input'}
                        title='Pick-up Time'
                        type='time'
                        name='time'
                        value={timeWithoutSeconds(visit?.time) ?? ''}
                        onChange={onFieldChange}
                    />
                    <VerticalDiv>
                        <PanelFieldTitle style={{ marginBottom: '0' }}>Customer</PanelFieldTitle>
                        <Dropdown
                            className='material-input'
                            id='client'
                            style={{ width: '100%' }}
                            emptyOption={true}
                            options={clientsOptions}
                            value={defaultClient}
                            onChange={onClientChange}
                        />
                    </VerticalDiv>
                    <VisitItemEditingInput
                        className='material-input'
                        title='Contacts'
                        type='text'
                        name='contacts'
                        value={visit?.contacts ?? ''}
                        onChange={onFieldChange}
                    />
                    <VisitItemEditingInput
                        className='material-input'
                        title='Pick-up Place'
                        type='text'
                        value={visit?.place ?? ''}
                        name='place'
                        onChange={onFieldChange}
                    />
                    <StyledStaffInputContainer>
                        <StyledStaffInput
                            inputClassName='material-input'
                            staff={staffOptions}
                            onStaffAdd={onStaffAdd}
                        />
                        <AssignedStaffContainer>
                            {visit.users.map((user) => (
                                <AssignedUser
                                    key={user.pk}
                                    name={getName(user)}
                                    onRemove={() => onRemoveAssignedUser(getName(user))}
                                />
                            ))}
                            {visit.otherUsers.map((name) => (
                                <AssignedUser
                                    key={name}
                                    name={name}
                                    onRemove={() => onRemoveAssignedUser(name)}
                                />
                            ))}
                        </AssignedStaffContainer>
                    </StyledStaffInputContainer>
                </StyledInputsContainer>
                <div style={{ marginLeft: '20px' }}>
                    <PanelFieldTitle style={{ marginBottom: '0' }}>Notes</PanelFieldTitle>
                    <textarea
                        style={{ width: '90%' }}
                        className='material-input'
                        rows='5'
                        value={visit?.notes ?? ''}
                        name='notes'
                        onChange={onFieldChange}></textarea>
                </div>
            </div>
            <FlexDiv direction='row' justifyContent='flex-end'>
                <button
                    style={{ width: '100px' }}
                    className={buttonTheme.gray}
                    disabled={hasPendingRequest}
                    onClick={props.onCancel}>
                    CANCEL
                </button>
                <EmptyDiv width='10px' />
                <button
                    style={{ width: '100px' }}
                    className={buttonTheme.red}
                    disabled={hasPendingRequest}
                    onClick={onSave}>
                    DONE
                </button>
            </FlexDiv>
        </div>
    );
}

VisitItemEditingInput.propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
    value: PropTypes.string,
    type: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func
};

function VisitItemEditingInput(props) {
    return (
        <VerticalDiv>
            <PanelFieldTitle style={{ marginBottom: '0' }}>{props.title}</PanelFieldTitle>
            <input
                className={props.className}
                type={props.type}
                name={props.name}
                value={props.value}
                onChange={props.onChange}></input>
        </VerticalDiv>
    );
}

const AssignedStaffContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
`;

function AssignedUser({ name, onRemove }) {
    return (
        <FlexDiv direction='row' alignItems='center'>
            <ImgButton
                src={require('../../../res/images/cac_remove_staff.png')}
                onClick={onRemove}
            />
            <p style={{ fontSize: '12px', marginBottom: '0' }}>{name}</p>
        </FlexDiv>
    );
}

const StyledInputsContainer = styled.div`
    display: grid;
    grid-template-columns: 3fr 3fr 4fr 4fr;
    grid-gap: 2rem;

    & input {
        max-width: 150px;
    }
`;

const StyledStaffInputContainer = styled(VerticalDiv)`
    grid-column: 1 / 4;
    width: min-content;

    @media ${dimension.lg} {
        width: min-content;
    }
`;

const StyledStaffInput = styled(StaffInput)`
    && {
        input {
            max-width: initial !important;
            width: 200px;
            border: none;
        }
        button {
            margin-left: 20px;
            margin-bottom: 0;
        }
    }
`;
