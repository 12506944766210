import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { FlexDiv } from '../../../components/Common';
import dimension from '../../../res/styles/Responsive';
import themeButton from '../../../res/styles/theme_button.module.scss';
import { PanelFieldTitle } from '../../ControlPanel/components/Common';

StaffInput.propTypes = {
    className: PropTypes.string,
    inputClassName: PropTypes.string,
    staff: PropTypes.array,
    onStaffAdd: PropTypes.func
};

export default function StaffInput(props) {
    const [user, setUser] = React.useState(null);
    const [usersCount, setUsersCount] = React.useState(0);

    const onStaffAdd = () => {
        setUsersCount((curr) => curr + 1);
        props.onStaffAdd(user);
    };

    return (
        <FlexDiv direction='row' alignItems='flex-end' className={props?.className}>
            <StaffContainer direction='column'>
                <PanelFieldTitle>Staff Assigned:</PanelFieldTitle>
                <StyledInputSelect
                    inputClassName={props?.inputClassName}
                    defaultValue={''}
                    options={props.staff}
                    onChange={(user) => setUser(user)}
                    key={usersCount}
                />
            </StaffContainer>
            <StyledAddButton className={themeButton.green} onClick={onStaffAdd}>
                ADD
            </StyledAddButton>
        </FlexDiv>
    );
}

const InputSelect = (props) => {
    return (
        <div className={props.className}>
            <span className='input-container'>
                <input
                    className={props?.inputClassName}
                    list='input-select'
                    name={props.name}
                    defaultValue={props.defaultValue?.trimEnd() ?? ''}
                    onChange={(e) => props.onChange(e.target.value)}></input>
            </span>
            <datalist id='input-select'>
                {props.options.map((v, index) => (
                    <option value={v} key={index}></option>
                ))}
            </datalist>
        </div>
    );
};

const StyledAddButton = styled.button`
    padding: 0 10px;
    margin: 0 0 10px auto;

    @media ${dimension.md} {
        padding: 0 25px;
    }
`;

const StyledInputSelect = styled(InputSelect)`
    input {
        margin-right: -10px;
        text-indent: 10px;
    }

    input::-webkit-calendar-picker-indicator {
        display: none;
    }

    .input-container {
        display: flex;
    }

    .input-container:after {
        border: solid transparent;
        content: ' ';
        height: 0;
        width: 0;
        pointer-events: none;
        border-color: rgba(0, 0, 0, 0);
        border-top-color: #000000;
        border-width: 5px;
        margin-top: -2px;
        z-index: 100;
        position: relative;
        top: 10px;
        right: 5px;
    }
`;

const StaffContainer = styled(FlexDiv)`
    width: 80%;

    && * {
        width: 100%;
    }
`;
