import * as _ from 'lodash';
import findIndex from 'lodash/findIndex';
import React from 'react';
import { useParams } from 'react-router-dom';
import { TabContent, TabPane } from 'reactstrap';
import styled from 'styled-components';
import { AppContext } from '../../App';
import LoadingWheel from '../../components/LoadingWheel';
import NavigationTabs from '../../components/NavigationTabs';
import { NEW_REPORT } from '../../res/Constants';
import buttonTheme from '../../res/styles/theme_button.module.scss';
import Button from './../../components/Button';
import colors from './../../res/styles/Colors';
import dimension from './../../res/styles/Responsive';
import { canCreateReport, canSeeCrAlerts } from './../../service/PermissionService';
import { getTechFormDetail, updateCrAlert } from './../../service/TechFormDetailService';
import { toastInfo, toastSuccess } from './../../ui/ToastHelper';
import CrAlertList from './tabs/CrAlerts/CrAlertList';
import Growing from './tabs/Growing/Growing';
import PicturesPane from './tabs/Pictures/PicturesPane';
import ReportsPane from './tabs/Reports/ReportsPane';

const CR_ALERTS_TAB_NAME = 'CR-ALERTS';
const TABS = ['GROWING', 'PICTURES', CR_ALERTS_TAB_NAME, 'REPORTS'];
const GROWING_TAB = 0;
const PICTURES_TAB = 1;
const CR_ALERTS_TAB = 2;
const REPORTS_TAB = 3;

export default function TechFormDetail(props) {
    const { techformId } = useParams();
    const appContext = React.useContext(AppContext);
    const [activeTab, setActiveTab] = React.useState(GROWING_TAB);
    const [reportIsBeingCreated, setReportIsBeingCreated] = React.useState(false);
    const [loading, setLoading] = React.useState(true);
    const [techformDetail, setTechformDetail] = React.useState(null);

    React.useEffect(() => {
        getTechFormDetail(techformId, (techFormDetail) => {
            if(techFormDetail == null) {
                setLoading(false);
                return;
            }
            // change the current cac type if the user visited the page using a URL link
            // and the current cac type is different from the techform one
            if (appContext.cacType != techFormDetail.cacType) {
                // change only if different to avoid useless re-rendering
                appContext.onCacTypeChange(techFormDetail.cacType);
            }
            setTechformDetail(techFormDetail);
            setLoading(false);
        });
    }, []);

    const onTabSelection = (num) => {
        if (loading === true) {
            return;
        }
        const newActiveTab = !canSeeCrAlerts() && num == CR_ALERTS_TAB ? num + 1 : num;
        setActiveTab(newActiveTab);
    };

    const onReportCreationFinished = () => {
        setReportIsBeingCreated(false);
    };

    const onNewReportClick = () => {
        if (loading === true) {
            return;
        }
        // move the the reports tab
        if (activeTab !== REPORTS_TAB) {
            setActiveTab(REPORTS_TAB);
        }
        setReportIsBeingCreated(true);
    };

    const onCrAlertUpdated = (crAlertData) => {
        if (crAlertData.pk != null) {
            toastInfo('Updating CR-Alert...');
        } else {
            toastInfo('Creating CR-Alert...');
        }
        updateCrAlert(crAlertData, (updatedCrAlert) => {
            if (updatedCrAlert != null) {
                toastSuccess('CR-Alert successfully updated.');
                let newExtraReportList;
                if (crAlertData.pk != null) {
                    // update existing CrAlert
                    const itemIndex = findIndex(
                        techformDetail.extraReportList,
                        (extraReport) => extraReport.pk === crAlertData.pk
                    );
                    newExtraReportList = [...techformDetail.extraReportList];
                    newExtraReportList[itemIndex] = crAlertData;
                } else {
                    // add new CrAlert
                    newExtraReportList = [updatedCrAlert, ...techformDetail.extraReportList];
                }
                setTechformDetail((curr) => ({ ...curr, extraReportList: newExtraReportList }));
            }
        });
    };

    const visibleTabs = canSeeCrAlerts() ? TABS : _.without(TABS, CR_ALERTS_TAB_NAME);
    const { growerName, custCode, varietyDesc, extraReportList } = techformDetail || {};
    const subTitle = [custCode, varietyDesc].join(' ');

    return (
        <div>
            <HeaderSpace>
                {canCreateReport() && (
                    <StyledButtonNewReport
                        className={buttonTheme.red}
                        text={NEW_REPORT}
                        onClick={onNewReportClick}
                    />
                )}
            </HeaderSpace>
            <HeaderDetail>
                <div className='d-nav-header'>
                    <h5>{growerName || ''}</h5>
                    <p>{subTitle}</p>
                    <NavigationTabs
                        values={visibleTabs}
                        activeTab={activeTab}
                        mainColor={colors.green}
                        dropdownClassName={buttonTheme.green}
                        onTabSelection={onTabSelection}
                    />
                </div>
            </HeaderDetail>
            <StyledDiv>
                {loading ? (
                    <LoadingWheel />
                ) : (
                    <StyledTabContent activeTab={activeTab}>
                        <div style={{ textAlign: 'start', padding: '10px' }}>
                            <h5>{TABS[activeTab]}</h5>
                        </div>
                        <TabPane tabId={GROWING_TAB}>
                            <Growing techFormData={techformDetail} />
                        </TabPane>
                        <TabPane tabId={PICTURES_TAB}>
                            <PicturesPane techFormData={techformDetail} />
                        </TabPane>
                        {canSeeCrAlerts() && (
                            <TabPane tabId={CR_ALERTS_TAB}>
                                <CrAlertList
                                    list={extraReportList}
                                    onCrAlertUpdated={onCrAlertUpdated}
                                    techFormData={techformDetail}
                                    canCreateNewAlert={true}
                                />
                            </TabPane>
                        )}
                        <TabPane tabId={REPORTS_TAB}>
                            <ReportsPane
                                techFormData={techformDetail}
                                isCreatingReport={reportIsBeingCreated}
                                onReportCreationFinished={onReportCreationFinished}
                            />
                        </TabPane>
                    </StyledTabContent>
                )}
            </StyledDiv>
        </div>
    );
}

const contentWidthSmartphone = '95%';
const contentWidthDesktop = '90%';

const StyledTabContent = styled(TabContent)`
    height: 100%;
    margin: 0 auto auto;
    width: ${contentWidthSmartphone};
    padding-bottom: 20px;

    @media ${dimension.md} {
        width: ${contentWidthDesktop};
    }

    & > div {
        width: 100%;
    }

    & > * {
        height: 100%;
    }
`;

const StyledDiv = styled.div`
    display: flex;
    min-height: 72vh;
    background-color: ${colors.light_gray};
`;

const StyledButtonNewReport = styled(Button)`
    && {
        width: 40%;
        margin-top: auto;
        margin-bottom: auto;
        margin-left: auto;
        margin-right: 5%;

        @media ${dimension.md} {
            width: 200px;
            padding: 0 20px;
        }
    }
`;

const HeaderSpace = styled.div`
    height: 8vh;
    display: flex;
    background-color: ${colors.green};
`;

const HeaderDetail = styled.div`
    height: 20vh;
    background: linear-gradient(
        to bottom,
        ${colors.green} 46%,
        ${colors.dark_green} 4%,
        ${colors.light_gray} 50%
    );

    .d-nav-header {
        height: 20vh;
        width: ${contentWidthSmartphone};
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: auto;
        background-color: white;
        border: 1px solid ${colors.dark_gray};
        box-shadow: 0 1px 2px ${colors.dark_gray};
        border-radius: 3px;
        text-align: center;

        & > h5 {
            margin-top: auto;
        }

        @media ${dimension.md} {
            width: ${contentWidthDesktop};
        }
    }
`;
