import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { ROGUING_REMARKS } from '../../../../../res/Constants';
import { extractRoguingRemarks } from '../../../../../service/CommonFunctions';
import GreenRedTimeLine from './../components/GreenRedTimeLine';

class Roguing extends PureComponent {
    static propTypes = {
        cacType: PropTypes.number.isRequired,
        techFormData: PropTypes.object.isRequired
    };

    render() {
        const lines = extractRoguingRemarks(this.props.techFormData);
        return (
            <GreenRedTimeLine
                cacType={this.props.cacType}
                title={ROGUING_REMARKS}
                lines={lines}
                lineCount={this.props.techFormData.line}
            />
        );
    }
}

export default Roguing;
