import PropTypes from 'prop-types';
import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import dimension from '../res/styles/Responsive';
import { randomId } from '../utils/Utils';
import InfoIcon from './InfoIcon';

TitleBar.propTypes = {
    title: PropTypes.string.isRequired,
    help: PropTypes.bool,
    hasBack: PropTypes.bool
};

TitleBar.defaultProps = {
    hasBack: true,
    help: false
};

export default function TitleBar(props) {
    const history = useHistory();

    const onBackClick = () => {
        history.goBack();
    };

    return (
        <TitleBarContainer>
            <div className='titlebar-content'>
                {props.hasBack && (
                    <img
                        alt=''
                        src={require('../res/images/cac_chevron_back.png')}
                        onClick={onBackClick}
                        style={{ cursor: 'pointer' }}
                    />
                )}
                <p style={{ paddingRight: props.hasBack ? '36px' : '0' }}>{props.title}</p>
                {props.help && (
                    <InfoIcon id={randomId()} image='cac_question_icon.png' content={props.help} />
                )}
            </div>
        </TitleBarContainer>
    );
}

const TitleBarContainer = styled.div`
    background-color: white;

    @media ${dimension.md} {
        height: auto;
    }

    .titlebar-content {
        height: 9vh;
        display: flex;
        width: 95%;
        margin: auto;
        padding-top: 10px;
        padding-bottom: 15px;
        text-align: center;

        & > * {
            margin-top: auto;
            margin-bottom: auto;
        }

        & > p {
            margin-left: auto;
            margin-right: auto;
            font-weight: bold;
        }
    }
`;
