import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
    CAC_TYPE,
    ESTIMATED_BULBS,
    ESTIMATED_YIELD,
    ReportCharactersNames
} from '../../../../../res/Constants';
import {
    extractLinesData,
    REPORT_HARVEST_DATA,
    REPORT_YIELD
} from '../../../../../service/ReportService';
import { isReportCharacterNumericValid } from './../../../../../service/CommonFunctions';
import EditableLine from './EditableLine';

EditableReportItem.propTypes = {
    linesCount: PropTypes.number,
    reportData: PropTypes.object,
    cacType: PropTypes.number,
    onReportEdited: PropTypes.func
};

EditableReportItem.defaultProps = {
    reportData: {},
    onReportEdited: () => {}
};

const NUMERIC_FIELDS = new Set([ReportCharactersNames.stand, ReportCharactersNames.bolt]);

export default function EditableReportItem(props) {
    const [linesData, setLinesData] = useState(
        extractLinesData(props.linesCount, props.reportData)
    );
    const [invalidFields, setInvalidFields] = useState(new Set());

    useEffect(() => {
        props.onReportEdited(linesData, invalidFields.size === 0);
    }, [linesData, invalidFields, props.onReportEdited]);

    const onLineValuesChange = (lineName, fieldId, value) => {
        const newLineData = {
            ...linesData[lineName],
            [fieldId]: value
        };
        setLinesData({ ...linesData, [lineName]: newLineData });
        if (NUMERIC_FIELDS.has(fieldId) && !isReportCharacterNumericValid(value)) {
            setInvalidFields((currentInvalidFields) => {
                currentInvalidFields.add(fieldId);
                return currentInvalidFields;
            });
        } else {
            setInvalidFields((currentInvalidFields) => {
                currentInvalidFields.delete(fieldId);
                return currentInvalidFields;
            });
        }
    };

    const onYieldChange = (event) => {
        const value = event?.target?.value;
        if (value != null) {
            onLineValuesChange('lineU', ReportCharactersNames.expYield, value);
        }
    };

    const onHarvestDataChange = (event) => {
        const value = event?.target?.value;
        if (value != null) {
            onLineValuesChange('lineU', ReportCharactersNames.harvestData, value);
        }
    };

    return (
        <>
            {linesData?.lineA?.lineName && (
                <EditableLine
                    lineName={linesData.lineA.lineName}
                    initialData={linesData.lineA}
                    onValuesEdited={(fieldId, value) => onLineValuesChange('lineA', fieldId, value)}
                />
            )}
            {props.linesCount === 2 && linesData?.lineB?.lineName != null && (
                <EditableLine
                    lineName={linesData.lineB.lineName}
                    initialData={linesData.lineB}
                    onValuesEdited={(fieldId, value) => onLineValuesChange('lineB', fieldId, value)}
                />
            )}
            {props.cacType === CAC_TYPE.CROP ? (
                <>
                    <h5 style={{ textAlign: 'center' }}>{ESTIMATED_YIELD}</h5>
                    <StyledInput
                        type='text'
                        defaultValue={linesData?.lineU?.[ReportCharactersNames.expYield] || ''}
                        onChange={onYieldChange}
                        id={REPORT_YIELD}
                    />
                </>
            ) : (
                <>
                    <h5 style={{ textAlign: 'center' }}>{ESTIMATED_BULBS}</h5>
                    <StyledInput
                        type='text'
                        defaultValue={linesData?.lineU?.[ReportCharactersNames.expYield] || ''}
                        onChange={onYieldChange}
                        id={REPORT_YIELD}
                    />
                    <h5 style={{ textAlign: 'center' }}>Harvest Data</h5>
                    <StyledInput
                        type='text'
                        defaultValue={linesData?.lineU?.[ReportCharactersNames.harvestData] || ''}
                        onChange={onHarvestDataChange}
                        id={REPORT_HARVEST_DATA}
                    />
                </>
            )}
        </>
    );
}

const StyledInput = styled.input`
    height: 30px;
    width: 100%;
    border-radius: 5px;
    box-shadow: 0 1px 2px grey;
    margin-bottom: 10px;
    text-indent: 10px;

    :focus {
        outline: none;
    }
`;
