import moment from 'moment';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import styled from 'styled-components';
import Button from '../../../components/Button';
import { VerticalDiv } from '../../../components/Common';
import DateInput from '../../../components/DateInput';
import buttonTheme from '../../../res/styles/theme_button.module.scss';
import { INPUT_DATE_PATTERN } from '../../../utils/DateFormatter';

export default class DownloadPhotoDialog extends PureComponent {
    static propTypes = {
        isModalOpen: PropTypes.bool,
        toggleDialog: PropTypes.func,
        onPhotosDownload: PropTypes.func
    };

    state = {
        from: moment()
            .subtract(1, 'month')
            .format(INPUT_DATE_PATTERN),
        to: moment().format(INPUT_DATE_PATTERN)
    };

    onChangeValue(key, value) {
        this.setState({
            [key]: value
        });
    }

    render() {
        return (
            <Modal
                centered={true}
                size='sm'
                isOpen={this.props.isModalOpen}
                toggle={this.props.toggleDialog}>
                <ModalBody>
                    <StyledPhotoDialog className='dialog-photo-download'>
                        <h5 className='title'>Period</h5>
                        <Button
                            text='DOWNLOAD ALL'
                            className={[buttonTheme.green, 'fit-content', 'mx-auto'].join(' ')}
                            onClick={() => this.props.onPhotosDownload()}
                        />
                        <p>or choose a report range:</p>
                        <VerticalDiv>
                            <DateInput
                                labelText='From:'
                                name='from'
                                isHorizontal={true}
                                defaultValue={this.state.from}
                                onChange={(key, value) => this.onChangeValue(key, value)}
                            />
                            <DateInput
                                labelText='To:'
                                name='to'
                                isHorizontal={true}
                                defaultValue={this.state.to}
                                onChange={(key, value) => this.onChangeValue(key, value)}
                            />
                        </VerticalDiv>
                        <div className='d-photo-dialog-footer'>
                            <Button
                                text='CANCEL'
                                className={[buttonTheme.red, 'w-40'].join(' ')}
                                onClick={() => this.props.toggleDialog()}
                            />
                            <div className='d-space' />
                            <Button
                                text='OK'
                                className={buttonTheme.green}
                                type='submit'
                                onClick={() =>
                                    this.props.onPhotosDownload(this.state.from, this.state.to)
                                }
                            />
                        </div>
                    </StyledPhotoDialog>
                </ModalBody>
            </Modal>
        );
    }
}

const StyledPhotoDialog = styled(VerticalDiv)`
    margin: auto;
    padding-top: 15px;
    background-color: white;

    .title {
        text-align: center;
        margin-bottom: 30px;
    }

    > * {
        margin-bottom: 15px;
        text-align: center;
    }

    .d-photo-dialog-footer {
        display: flex;
        justify-content: space-between;

        button {
            width: 50%;
        }
    }

    .d-space {
        width: 20px;
    }
`;
