import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

RadioButton.propTypes = {
    text: PropTypes.string,
    isSelected: PropTypes.bool,
    onChange: PropTypes.func
};

RadioButton.defaultProps = {
    isSelected: false,
    onChange: () => {}
};

export default function RadioButton(props) {
    return (
        <StyledLabel>
            {props.text}
            <input
                type='radio'
                name='radio'
                checked={props.isSelected}
                onChange={props.onChange}></input>
            <span className='checkmark' />
        </StyledLabel>
    );
}

const StyledLabel = styled.label`
    display: block;
    position: relative;
    padding: 0 10px 0 30px;
    cursor: pointer;
    user-select: none;

    /* Hide the browser's default radio button */
    & input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }

    /* Custom radio button */
    .checkmark {
        position: absolute;
        top: 2px;
        left: 0;
        height: 20px;
        width: 20px;
        background-color: #eee;
        border-radius: 50%;
    }

    /* On mouse-over, add a grey background color */
    &:hover input ~ .checkmark {
        background-color: #ccc;
    }

    /* When the radio button is checked, add a blue background */
    & input:checked ~ .checkmark {
        background-color: #2196f3;
    }

    /* Create the indicator (the dot/circle - hidden when not checked) */
    .checkmark:after {
        content: '';
        position: absolute;
        display: none;
    }

    /* Show the indicator (dot/circle) when checked */
    & input:checked ~ .checkmark:after {
        display: block;
    }

    /* Style the indicator (dot/circle) */
    & .checkmark:after {
        top: 7px;
        left: 7px;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background: white;
    }
`;
