export const USER_ROLES = Object.freeze({
    admin: 'ROLE_ADMIN',
    commercial: 'ROLE_COMMERCIAL',
    financial: 'ROLE_FINANCIAL',
    tech: 'ROLE_TECH',
    client: 'ROLE_CLIENT'
});

export const CAC_TYPE = Object.freeze({
    CROP: 1,
    NURSERY: 2,
    VISITS: 3
});

export const WORK_IN_PROGRESS = 'Funzionalità in sviluppo';
export const CROP_REPORT = 'CROP REPORT';
export const NURSERY_REPORT = 'NURSERY REPORT';
export const CUSTOMER_VISITS = 'CUSTOMER VISITS';
export const NEW_REPORT = 'NEW REPORT';
export const SOWING = 'Sowing';
export const SOWING_INFO = 'Sowing Info';
export const ROGUING_REMARKS = 'Roguing Remarks';
export const FLOWERING_DATES = 'Flowering Dates';
export const CR_ALERTS = 'CR-Alerts';
export const LINE_A = 'Line A';
export const LINE_B = 'Line B';
export const LINE_OP = 'Line OP';
export const A = 'A';
export const B = 'B';
export const U = 'U';
export const OP = 'OP';
export const LINES = [A, B, U];
export const GENERAL = 'General';

export const FIRST_YEAR_OF_ACTIVITY = 2007;
export const MAX_NUMBER_OF_TECHFORMS = 200;

// Commong Strings
export const SAVE = 'Save';
export const CANCEL = 'Cancel';
export const CONFIRM = 'Confirm';
export const EDIT = 'Edit';
export const LOGOUT = 'Logout';
export const SETTINGS = 'Settings';
export const DOWNLOAD_CROP_APP = 'Download Crop Report App';
export const DOWNLOAD_NURSERY_APP = 'Download Nursery Report App';
export const DOWNLOAD_CUSTOMER_VISITS_APP = 'Download Customer Visits App';
export const DOWNLOAD_INVENTORY_APP = 'Download Inventory App';

// Report Characters
export const DEVELOPMENT = 'Development';
export const STANDING = 'Standing';
export const UNIFORMITY = 'Uniformity';
export const BOLTING = 'Bolting';
export const DISEASES_GRAVITY = 'Diseases Gravity';
export const DISEASES_DESCRIPTION = 'Diseases Description';
export const ESTIMATED_YIELD = 'Estimated Yield';
export const ESTIMATED_BULBS = 'Estimated Bulbs';
export const EST_YIELD = 'Est. Yield';
export const EST_BULBS = 'Est. Bulbs';
export const REMARKS = 'Remarks';

// Params
export const DEVELOP = 'Develop';
export const STAND = 'Stand';
export const BOLT = 'Bolt';
export const SANITY = 'Sanity';

export const HTTP_CODE = {
    OK: 200,
    BAD_REQUEST: 400,
    UNAUTHORIZED: 401,
    NOT_FOUND: 404,
    CONFLICT: 409,
    SERVER_ERROR: 500
};

export const SERVER_CODE_MAPPER = {
    400: 'Bad request data',
    401: 'Unauthorized user',
    404: 'Page not found',
    409: 'User already exists',
    500: 'Internal server error'
};

export const SORT_TYPE = {
    ASCENDANT: 'asc',
    DESCENDANT: 'desc'
};

export const TECHFORM_MAIN_FIELDS = {
    ZONE: {
        key: 'zoneCode',
        value: 'Zone'
    },
    CUSTOMER: {
        key: 'custCode',
        value: 'Customer'
    },
    SPECIES: {
        key: 'speciesCode',
        value: 'Species'
    },
    SECTOR: {
        key: 'sectorCode',
        value: 'Sector'
    },
    VARIETY: {
        key: 'varietyDesc',
        value: 'Variety'
    },
    GROWER: {
        key: 'growerName',
        value: 'Grower'
    }
};

export const TECHFORM_MAIN_FIELDS_VALUES = Object.values(TECHFORM_MAIN_FIELDS).map((e) => e.value);
export const TECHFORM_MAIN_FIELDS_KEYS = Object.values(TECHFORM_MAIN_FIELDS).map((e) => e.key);

export const TECHFORM_FILTER_FIELDS = TECHFORM_MAIN_FIELDS_VALUES.filter(
    (e) => e.value !== 'Sector'
);

export const ExtraReportCharacterNames = {
    NOTES: 'Notes',
    DESTROYED_REASON: 'DestroyedReason',
    DESTROYED_HA: 'DestroyedHa',
    NET_HA: 'NetHa',
    INDEMNITY: 'Indemnity',
    INFORMED_CUSTOMER: 'InformedCustomer'
};

export const HarvestValues = {
    S: 'Standard',
    F: 'Female-only',
    Y: 'F+M separate',
    X: 'F+M mixed'
};

export const OrganicValues = {
    E: 'Conventional',
    G: 'Organic'
};

export const TunnelValues = {
    P: 'Open Field',
    T: 'Tunnel'
};

export const FloweringDatesNames = {
    startFlowering: 'Start',
    startFullFlowering: 'Start Full',
    endFullFlowering: 'End Full',
    endFlowering: 'End',
    pinching: 'Pinching',
    harvesting: 'Harvesting'
};

export const FloweringDatesCharacterNames = {
    startFlowering: 'Start',
    startFullFlowering: 'StartFull',
    endFlowering: 'End',
    endFullFlowering: 'EndFull',
    pinching: 'Pinch',
    harvesting: 'HarvestDate'
};

export const ReportCharactersNames = {
    stand: STAND,
    develop: DEVELOP,
    uniform: 'Uniformity',
    bolt: BOLT,
    diseasesDescription: 'DiseasesDescription',
    diseasesGravity: 'DiseasesGravity',
    remarks: 'Remarks',
    roguing: 'RoguingRemarks',
    expYield: 'Yield',
    harvestData: 'HarvestData'
};

export const UNIFORMITY_VALUES = { 'No': '0', 'Yes': '1' };

export const REPORT_CHARACTERS_NUMERIC_BOUNDS = {
    max: 100,
    min: 0
};

export const NUMERIC_VALUE_TOO_HIGH = `Max value is ${REPORT_CHARACTERS_NUMERIC_BOUNDS.max}`;
export const NUMERIC_VALUE_TOO_LOW = `Minimum value is ${REPORT_CHARACTERS_NUMERIC_BOUNDS.min}`;

export const PHOTO_TYPES = [
    {
        name: 'Landscape',
        value: 'LAND'
    },
    {
        name: 'Line A',
        value: 'A'
    },
    {
        name: 'Line B',
        value: 'B'
    },
    {
        name: 'Diseases',
        value: 'DIS'
    },
    {
        name: 'Off Type',
        value: 'OFF'
    }
];

export const APP_DOWNLOADS = {
    CROP: 'https://cropreport.cacseeds.it/crsandroid/index.html',
    NURSERY: 'https://cropreport.cacseeds.it/ncrsandroid/index.html',
    CUSTOMER_VISITS: 'https://cropreport.cacseeds.it/visitsandroid/index.html',
    INVENTORY: 'https://cropreport.cacseeds.it/inventoryandroid/index.html'
};
