import React from 'react';

const Button = ({ id, style, className, type, onClick, text, enabled }) => {
    const disabled = enabled != null && !enabled;
    return (
        <button
            id={id}
            style={style}
            className={['btn', className].join(' ')}
            disabled={disabled ? 'disabled' : ''}
            type={type}
            onClick={onClick}>
            {text}
        </button>
    );
};
export default Button;
