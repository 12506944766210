import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { EmptyDiv } from '../../../components/Common';
import { CAC_TYPE } from '../../../res/Constants';
import colors from '../../../res/styles/Colors';
import dimension from '../../../res/styles/Responsive';
import buttonTheme from '../../../res/styles/theme_button.module.scss';

TooManyResultsMessage.propTypes = {
    cacType: PropTypes.number,
    resultsCount: PropTypes.number.isRequired,
    onContinue: PropTypes.func.isRequired,
    onExcelDownload: PropTypes.func.isRequired,
    onPhotosDownload: PropTypes.func.isRequired
};

export default function TooManyResultsMessage(props) {
    return (
        <div style={{ width: '100%' }}>
            <StyledEmptyError>
                You are loading {props.resultsCount} techform and it will require time.
                <br />
                What do you prefer to do?
            </StyledEmptyError>
            <ButtonsContainer>
                <FooterButton className={buttonTheme.red} onClick={() => props.onExcelDownload(1)}>
                    EXPORT 1 VIEW
                </FooterButton>
                <ButtonSeparator />
                {props.cacType === CAC_TYPE.CROP && (
                    <>
                        <FooterButton
                            className={buttonTheme.red}
                            onClick={() => props.onExcelDownload(2)}>
                            EXPORT 2 VIEW
                        </FooterButton>
                        <ButtonSeparator />
                    </>
                )}
                <FooterButton className={buttonTheme.red} onClick={props.onPhotosDownload}>
                    DOWNLOAD PHOTOS
                </FooterButton>
                <ButtonSeparator />
                <FooterButton className={buttonTheme.green} onClick={props.onContinue}>
                    CONTINUE
                </FooterButton>
            </ButtonsContainer>
        </div>
    );
}

const ButtonSeparator = () => {
    return <EmptyDiv width='20px' height='20px' />;
};

const ButtonsContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    @media ${dimension.md} {
        flex-direction: row;
        justify-content: center;
    }
`;

const FooterButton = styled.button`
    width: 150px;
`;

const StyledEmptyError = styled.p`
    color: ${colors.red};
    margin: auto;
    text-align: center;
    padding: 30px 20px;
`;
