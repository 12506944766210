import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Col, FormGroup, Input, Label, Modal, ModalBody, Row } from 'reactstrap';
import styled from 'styled-components';
import Dropdown from '../../../../components/Dropdown';
import themeButton from '../../../../res/styles/theme_button.module.scss';
import { uploadPhoto } from '../../../../service/PhotoService';
import { ImgButton } from './../../../../components/Common';
import { PHOTO_TYPES } from './../../../../res/Constants';
import { toastError } from './../../../../ui/ToastHelper';

const saveIcon = require('../../../../res/images/cac_save_icon.png');
const cancelIcon = require('../../../../res/images/cac_cancel_icon.png');

UploadDialog.propTypes = {
    isVisible: PropTypes.bool,
    toggleVisibility: PropTypes.func,
    techFormData: PropTypes.object
};

export default function UploadDialog(props) {
    const [imgType, setImgType] = useState(null);
    const [imgSelected, setImgSelected] = useState(null);
    const [reportPkSelected, setReportPkSelected] = useState(null);

    useEffect(() => {
        setImgType(null);
        setImgSelected(null);
        setReportPkSelected(null);
    }, [props.isVisible]);

    const setImageType = (event) => {
        setImgType(event.target.value);
    };

    const onUploadConfirm = () => {
        if (imgSelected == null) {
            toastError('No image uploaded.');
        } else if (imgType == null) {
            toastError('No image type selected.');
        } else if (reportPkSelected == null) {
            toastError('No report selected.');
        } else {
            uploadPhoto(imgSelected, imgType, reportPkSelected, () => {
                props.toggleVisibility();
            });
        }
    };

    const onSelectImage = (event) => {
        const { files } = event.target;
        if (files != null) {
            setImgSelected(files[0]);
        }
    };

    const onReportSelected = (selectedValue) => {
        if (selectedValue !== '') {
            const report = reportsData.filter((value) => value.name === selectedValue);
            setReportPkSelected(report[0].reportPk);
        }
    };

    const reportsData = props.techFormData.reportsList.map((obj) => ({
        reportPk: obj.pk,
        name:
            obj.techId != null
                ? `${moment(obj.date).format('DD/MM/YYYY')} - ${obj.techId}`
                : `${moment(obj.date).format('DD/MM/YYYY')}`
    }));

    return (
        <Modal isOpen={props.isVisible} centered>
            <ModalBody>
                <Row>
                    <PickPhotoButton className={themeButton.green}>
                        <label>
                            <input type='file' onChange={onSelectImage} />
                            UPLOAD NEW PHOTO
                        </label>
                    </PickPhotoButton>
                </Row>
                <Row>
                    <Col xs={{ size: 'auto', offset: 4 }}>
                        {imgSelected == null ? '' : imgSelected.name}
                    </Col>
                </Row>
                <Row>
                    <Col xs='4' style={{ textAlign: 'right' }}>
                        Report
                    </Col>
                    <Col>
                        <StyledDropdown
                            options={reportsData.map((value) => value.name)}
                            onChange={onReportSelected}
                        />
                        {PHOTO_TYPES.map((obj, index) => (
                            <FormGroup check key={index}>
                                <Label check>
                                    <Input
                                        type='radio'
                                        name='type'
                                        value={obj.value}
                                        onClick={setImageType}
                                    />{' '}
                                    {obj.name}
                                </Label>
                            </FormGroup>
                        ))}
                    </Col>
                </Row>
                <Row>
                    <Col xs={{ offset: 8, size: 1 }}>
                        <ImgButton
                            src={saveIcon}
                            alt='confirm the upload'
                            onClick={onUploadConfirm}
                        />
                    </Col>
                    <Col xs={{ size: 1, offset: 1 }}>
                        <ImgButton
                            src={cancelIcon}
                            alt='cancel the upload'
                            onClick={props.toggleVisibility}
                        />
                    </Col>
                </Row>
            </ModalBody>
        </Modal>
    );
}

const StyledDropdown = styled(Dropdown)`
    width: 100%;
    font-size: 12px;
`;

const PickPhotoButton = styled.button`
    width: 70%;
    margin: auto;
    margin-bottom: 20px;

    label {
        margin-bottom: 0;
        cursor: pointer;
        width: 100%;
        height: 100%;
        transform: translateY(30%);
    }

    input[type='file'] {
        display: none;
    }
`;
