import pickBy from 'lodash/pickBy';

const uuidv4 = require('uuid/v4');

export function removeNullProperties(object) {
    const safeObjectCopy = { ...object };
    Object.entries(safeObjectCopy).forEach(([key, value]) => {
        if (value == null) {
            delete safeObjectCopy[key];
        }
    });
    return safeObjectCopy;
}

/**
 * Removes nested objects
 * @param {object}
 */
export const removeNestedObject = (object) => pickBy(object, (value) => typeof value !== 'object');

// [a-zA-Z] starting letter required by HTML rules for ids
export const randomId = () => `a${uuidv4()}`.replace(/-/g, '');

export const isTrue = (value) => value === true || value === 'true';
