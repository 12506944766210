import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import styled from 'styled-components';
import EditableText from '../../../../components/EditableText';
import InfoText from './../../../../components/InfoText';

export default class EditableInfoText extends PureComponent {
    static propTypes = {
        className: PropTypes.string,
        type: PropTypes.string,
        title: PropTypes.string,
        initialValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        isEditable: PropTypes.bool,
        rows: PropTypes.number,
        fieldId: PropTypes.string,
        onFieldEdited: PropTypes.func
    };

    static defaultProps = {
        isEditable: false,
        hasLongText: false,
        rows: 1,
        type: 'text',
        initialValue: '',
        title: ''
    };

    render() {
        return (
            <>
                {this.props.isEditable ? (
                    <StyledEditableText
                        type={this.props.type}
                        className={['editable', this.props.className].join(' ')}
                        title={this.props.title}
                        initialValue={this.props.initialValue}
                        rows={this.props.rows}
                        fieldId={this.props.fieldId}
                        onBlur={this.props.onFieldEdited}
                    />
                ) : (
                    <InfoText
                        className={['info', this.props.className].join(' ')}
                        title={this.props.title}
                        content={this.props.initialValue}
                    />
                )}
            </>
        );
    }
}

const StyledEditableText = styled(EditableText)`
    input,
    textarea {
        font-size: 13px;
    }
`;
