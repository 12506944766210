import React from 'react';
import { useHistory } from 'react-router-dom';
import {
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Nav,
    NavItem,
    NavLink
} from 'reactstrap';
import styled from 'styled-components';
import { AppContext } from '../../App';
import {
    CAC_TYPE,
    CROP_REPORT,
    CUSTOMER_VISITS,
    NURSERY_REPORT,
    USER_ROLES
} from '../../res/Constants';
import { getCurrentRole, getCurrentUserDataAsync, logout } from '../../service/AccountService';
import { getUnreadExtraReportsCount } from '../../service/ExtraReportService';
import { canSeeCrAlerts } from '../../service/PermissionService';
import { isCurrentUserAdmin } from './../../service/AccountService';
import { ButtonNoStyle } from './../Common';
import DownloadApplicationIcon from './components/DownloadApplicationIcon';
import HamburgerMenu from './components/HamburgerMenu';
import UserDropDown from './components/UserDropDown';
import './header.scss';

const CAC_SECTIONS = [
    { label: CROP_REPORT, type: CAC_TYPE.CROP },
    { label: NURSERY_REPORT, type: CAC_TYPE.NURSERY },
    { label: CUSTOMER_VISITS, type: CAC_TYPE.VISITS }
];

export default function NavigationBar() {
    const history = useHistory();
    const appContext = React.useContext(AppContext);

    const [username, setUsername] = React.useState(null);
    const [unreadCount, setUnreadCount] = React.useState(0);
    const [dropdownOpen, setDropdownOpen] = React.useState(false);

    React.useEffect(() => {
        getCurrentUserDataAsync().then((userData) => {
            if (userData != null) {
                setUsername(userData.username);
                if (userData.role !== USER_ROLES.client) {
                    getUnreadExtraReportsCount((unreadCount) => setUnreadCount(unreadCount));
                }
            }
        });
    }, []);

    const onSectionChange = (cacType) => {
        appContext.onCacTypeChange(cacType);
    };

    const handleLogout = () => {
        logout((result) => {
            history.push('/');
            appContext.clearCache();
            if (result.status !== 200) {
                console.log('logout failed');
            }
        });
    };

    const onAlertBadgeClick = () => {
        history.push('/reports/extra');
    };

    const onSettingsBadgeClick = () => {
        history.push('/controlpanel');
    };

    const toggleDropDown = () => {
        setDropdownOpen((curr) => !curr);
    };

    const cacSectionsFilteredByRole =
        getCurrentRole() === USER_ROLES.client ? CAC_SECTIONS.slice(0, -1) : CAC_SECTIONS;

    return (
        <div id='header-container'>
            <div style={{ margin: '0 10px' }}>
                <img
                    id='logo'
                    className='responsive-margin'
                    src={require('../../res/images/cac_logo_negative.png')}
                    alt='CAC logo'
                />
            </div>
            <div className='nav-menu'>
                <Nav pills className='d-md-none'>
                    <Dropdown nav isOpen={dropdownOpen} toggle={toggleDropDown}>
                        <DropdownToggle nav caret>
                            {cacSectionsFilteredByRole.find(
                                (section) => section.type === appContext.cacType
                            )?.label ?? CROP_REPORT}
                        </DropdownToggle>
                        <DropdownMenu>
                            {cacSectionsFilteredByRole
                                .filter((section) => section.type != appContext.cacType)
                                .map((section) => (
                                    <DropdownItem
                                        key={section.type}
                                        onClick={() => onSectionChange(section.type)}>
                                        {section.label}
                                    </DropdownItem>
                                ))}
                        </DropdownMenu>
                    </Dropdown>
                </Nav>
                <Nav pills className='d-none d-md-flex'>
                    {cacSectionsFilteredByRole.map((section) => (
                        <NavItem key={section.type}>
                            <NavLink
                                href='#'
                                active={appContext.cacType === section.type}
                                onClick={() => onSectionChange(section.type)}>
                                {section.label}
                            </NavLink>
                        </NavItem>
                    ))}
                </Nav>
            </div>
            <div className='d-right-buttons d-lateral-icons responsive-margin'>
                {isCurrentUserAdmin() && (
                    <img
                        className='header-icon d-none d-lg-block'
                        src={require('../../res/images/cac_settings.png')}
                        alt='Settings'
                        onClick={onSettingsBadgeClick}
                        style={{ cursor: 'pointer' }}
                    />
                )}
                <DownloadApplicationIcon className='d-none d-lg-block' />
                <UserDropDown
                    className='d-none d-lg-block'
                    handleLogout={handleLogout}
                    username={username}
                />
                {canSeeCrAlerts() && (
                    <StyledNavButton onClick={onAlertBadgeClick}>
                        {unreadCount > 0 && (
                            <StyledBadge>
                                <p>{unreadCount}</p>
                            </StyledBadge>
                        )}
                        <img
                            className='header-icon'
                            src={require('../../res/images/cac_alert.png')}
                            alt='Alert icon'
                        />
                    </StyledNavButton>
                )}
                <HamburgerMenu
                    className='d-lg-none'
                    username={username}
                    onLogout={handleLogout}
                    onSettings={onSettingsBadgeClick}
                />
            </div>
        </div>
    );
}

const StyledNavButton = styled(ButtonNoStyle)`
    flex-shrink: 0;
    height: 26px !important;
`;

const StyledBadge = styled.div`
    position: absolute;
    margin-left: 8px;
    margin-top: -2px;
    border-radius: 10px;
    background-color: red;

    p {
        size: 10px;
        margin: 0;
        color: white;
        padding: 1px 2px;
    }
`;
