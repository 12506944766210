import React from 'react';
import styled from 'styled-components';
import LinePoint from './LinePoint';
import { InputDate } from '../Common';
import { getDateOrEmpty } from '../../utils/DateFormatter';

const DateCompact = ({
    className,
    date,
    content,
    hasLine = true,
    color = 'black',
    isEditable = false,
    id,
    onChange
}) => {
    const formattedDate = getDateOrEmpty(date, 'DD/MMM/YYYY');

    return (
        <div className={className}>
            <StyledLinePointCompact color={color} hasLine={hasLine} circlePosition='bottom' />
            {isEditable ? (
                <StyledInputDate
                    id={id}
                    color={color}
                    dateAsLong={date}
                    onChange={(event) => {
                        onChange && onChange(id, event.target.value);
                    }}
                />
            ) : (
                <p>{formattedDate}</p>
            )}
            <StyledContent color={color}>{content}</StyledContent>
        </div>
    );
};

const StyledContent = styled.p`
    color: ${({ color }) => color};
`;

const StyledLinePointCompact = styled(LinePoint)`
    margin: auto;
    width: 30px;
    height: ${({ hasLine }) => (hasLine ? '20px' : 'auto')};
`;

const StyledInputDate = styled(InputDate)`
    && {
        height: 30px;
        width: 160px;
        margin: 3px auto;

        :focus {
            box-shadow: none;
            border-color: ${({ color }) => color};
        }
    }
`;

export default DateCompact;
