import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { isMobileOnly } from 'react-device-detect';
import styled from 'styled-components';
import { ColNoPadding } from '../../../../../components/Common';
import ExpandIcon from '../../../../../components/ExpandIcon';
import InfoText from '../../../../../components/InfoText';
import ListItem from '../../../../../components/ListItem';
import { SOWING_INFO } from '../../../../../res/Constants';
import dimension from '../../../../../res/styles/Responsive';
import { TitleRow } from '../components/Common';

export default class SowingInfo extends React.PureComponent {
    static propTypes = {
        techFormData: PropTypes.object.isRequired
    };

    render() {
        const sowingDate = this.props.techFormData.sowingTransA[0]?.date;
        const bulbs = this.props.techFormData.plantsNumberA;

        const expandableChildren = (
            <div>
                <InfoText
                    title='Sowing Date'
                    content={
                        sowingDate == null
                            ? '-'
                            : moment(sowingDate)
                                  .format('DD/MM/YYYY')
                                  .toString()
                    }
                />
                <InfoText title='Stockseed Quantity' content={bulbs.toString()} />
            </div>
        );

        return (
            <StyledListItem expandableChildren={expandableChildren} isExpanded={!isMobileOnly}>
                {(renderProps) => (
                    <TitleRow className='d-md-none'>
                        <ColNoPadding xs='8'>
                            <h5>{SOWING_INFO}</h5>
                        </ColNoPadding>
                        <ColNoPadding xs='auto'>
                            <ExpandIcon onClick={renderProps.toggleExpand} />
                        </ColNoPadding>
                    </TitleRow>
                )}
            </StyledListItem>
        );
    }
}

const StyledListItem = styled(ListItem)`
    width: 100%;

    .inner-container {
        @media ${dimension.md} {
            padding: 0;
        }
    }

    .inner-container-expandable {
        padding: 15px;
    }
`;
