import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import Button from '../../../components/Button';
import Checkbox from '../../../components/Checkbox';
import { CAC_TYPE } from '../../../res/Constants';
import dimension from '../../../res/styles/Responsive';
import buttonTheme from '../../../res/styles/theme_button.module.scss';
import { isTrue } from '../../../utils/Utils';
import { AppContext } from './../../../App';
import colors from './../../../res/styles/Colors';
import ButtonDropDown from './ButtonDropDown';
import ButtonRadio from './ButtonRadio';
import InputDropDown from './InputDropDown';

TechFilters.propTypes = {
    filters: PropTypes.object,
    prevFilters: PropTypes.object,
    onFiltersReset: PropTypes.func,
    onFiltersApply: PropTypes.func
};

TechFilters.defaultProps = {
    prevFilters: {},
    onFiltersApply: () => {},
    onFiltersReset: () => {}
};

export default function TechFilters(props) {
    const [currentFilters, setCurrentFilters] = React.useState({ ...props.prevFilters });
    const appContext = React.useContext(AppContext);

    const updateCurrentFilters = (filterName, value) => {
        setCurrentFilters({ ...currentFilters, [filterName]: value });
    };

    function buildFilterObject() {
        return {
            year:
                currentFilters.year ||
                props.filters.year.values[props.filters.year.defaultValueIndex].name,
            clientCode: currentFilters.clientCode,
            zoneCode: currentFilters.zoneCode,
            growerPk: currentFilters.growerPk,
            speciesCode: currentFilters.speciesCode,
            tunnel: sanitizeEmptyFilter(currentFilters.tunnel),
            develop: sanitizeEmptyFilter(currentFilters.develop),
            stand: sanitizeEmptyFilter(currentFilters.stand),
            organic: sanitizeEmptyFilter(currentFilters.organic),
            diseases: currentFilters.diseases ?? false,
            onlyLastReport: currentFilters?.onlyLastReport
        };
    }

    const onReset = () => {
        props.onFiltersReset();
    };

    const onFiltersApply = () => {
        props.onFiltersApply(buildFilterObject());
    };

    const defaultYear =
        props.prevFilters?.year ||
        props.filters.year.values[props.filters.year.defaultValueIndex].name;
    const defaultZone = currentFilters.zoneCode || props.filters.zoneCode?.label;
    const defaultClientCode = currentFilters.clientCode || props.filters.clientCode?.label;
    const growerPkIndex = props.filters.growerPk?.values?.findIndex(
        (item) => item.value === currentFilters.growerPk
    );
    const defaultGrowerPk =
        props.filters.growerPk?.values[growerPkIndex]?.name || props.filters.growerPk?.label;
    const speciesIndex = props.filters.speciesCode.values.findIndex(
        (item) => item.value === currentFilters.speciesCode
    );
    const defaultSpecies =
        props.filters.speciesCode.values[speciesIndex]?.name || props.filters.speciesCode.label;
    const diseasesSelected = isTrue(currentFilters.diseases) || isTrue(props.prevFilters?.diseases);
    const defaultTunnelIndex =
        props.filters.tunnel?.values?.findIndex(
            (item) => item.name === props.prevFilters?.tunnel
        ) || props.filters.tunnel?.defaultValueIndex;

    const filterDevelop = props.filters.develop;
    const defaultDevelopIndex =
        filterDevelop?.values?.findIndex((item) => item.name === props.prevFilters?.develop) ||
        filterDevelop?.defaultValueIndex;

    const filterOrganic = props.filters.organic;
    const defaultOrganicIndex =
        filterDevelop?.values?.findIndex((item) => item.name === props.prevFilters?.organic) ||
        filterOrganic?.defaultValueIndex;

    return (
        <FiltersContainer>
            <h5>Filters</h5>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between'
                }}>
                {props.filters.year && (
                    <ButtonDropDown
                        className={[buttonTheme.green, 'w-50'].join(' ')}
                        defaultValue={defaultYear}
                        values={props.filters.year.values}
                        hasEmptyValue={false}
                        onChangeValue={(value) => updateCurrentFilters('year', value)}
                    />
                )}
                <div style={{ width: '30px' }} />
                {props.filters.zoneCode && (
                    <ButtonDropDown
                        className={[buttonTheme.lighterGreen, 'w-50'].join(' ')}
                        defaultValue={defaultZone}
                        values={props.filters.zoneCode.values}
                        onChangeValue={(value) => updateCurrentFilters('zoneCode', value)}
                    />
                )}
            </div>
            {props.filters.clientCode && (
                <InputDropDown
                    className={buttonTheme.green}
                    defaultValue={defaultClientCode}
                    values={props.filters.clientCode.values}
                    onChangeValue={(value) => updateCurrentFilters('clientCode', value)}>
                    <input type='text'></input>
                </InputDropDown>
            )}
            {appContext.cacType === CAC_TYPE.NURSERY && props.filters.growerPk && (
                <ButtonDropDown
                    className={buttonTheme.green}
                    defaultValue={defaultGrowerPk}
                    values={props.filters.growerPk.values}
                    keys={props.filters.growerPk.values.map((item) => item.value)}
                    onChangeValue={(value) => {
                        const selectedGrower = props.filters.growerPk.values.find(
                            (item) => item.name === value
                        );
                        updateCurrentFilters('growerPk', selectedGrower?.value);
                    }}
                />
            )}
            {props.filters.speciesCode && (
                <InputDropDown
                    className={buttonTheme.green}
                    defaultValue={defaultSpecies}
                    values={props.filters.speciesCode.values}
                    onChangeValue={(value) => {
                        const selectedSpecies = props.filters.speciesCode.values.find(
                            (item) => item.name === value
                        );
                        updateCurrentFilters('speciesCode', selectedSpecies?.value);
                    }}
                />
            )}
            {appContext.cacType === CAC_TYPE.CROP && props.filters.tunnel && (
                <div>
                    <h6 className='filter-title'>{props.filters.tunnel.label}</h6>
                    <ButtonRadio
                        className={buttonTheme.lighterGreen}
                        onActiveStyle={buttonTheme.green}
                        defaultValueIndex={defaultTunnelIndex}
                        values={props.filters.tunnel.values}
                        onChangeValue={(value) => updateCurrentFilters('tunnel', value)}
                    />
                </div>
            )}
            {props.filters.develop && (
                <div className='d-filter'>
                    <h6 className='filter-title'>{props.filters.develop.label}</h6>
                    <ButtonRadio
                        className={buttonTheme.lighterGreen}
                        onActiveStyle={buttonTheme.green}
                        defaultValueIndex={defaultDevelopIndex}
                        values={props.filters.develop.values}
                        onChangeValue={(value) => updateCurrentFilters('develop', value)}
                    />
                </div>
            )}
            {props.filters.organic && (
                <div className='d-filter'>
                    <h6 className='filter-title'>{props.filters.organic.label}</h6>
                    <ButtonRadio
                        className={buttonTheme.lighterGreen}
                        onActiveStyle={buttonTheme.green}
                        defaultValueIndex={defaultOrganicIndex}
                        values={props.filters.organic.values}
                        onChangeValue={(value) => updateCurrentFilters('organic', value)}
                    />
                </div>
            )}
            {props.filters.stand && (
                <input
                    className='input-field'
                    type='text'
                    defaultValue={props.prevFilters?.stand || ''}
                    placeholder={props.filters.stand.label}
                    onBlur={(event) => updateCurrentFilters('develop', event.target.value)}
                />
            )}
            {props.filters.diseases && (
                <Checkbox
                    text={props.filters.diseases.label}
                    isHorizontal={true}
                    isSelected={diseasesSelected}
                    onChange={(_, value) => updateCurrentFilters('diseases', value)}
                />
            )}
            <div className='d-filters-footer'>
                <Button
                    text='APPLY'
                    className={[buttonTheme.red, 'w-40'].join(' ')}
                    onClick={onFiltersApply}
                />
                <DivSpace />
                <Button
                    text='RESET'
                    className={[buttonTheme.textGreen, 'w-40'].join(' ')}
                    onClick={onReset}
                />
            </div>
        </FiltersContainer>
    );
}

const sanitizeEmptyFilter = (value) => (value === '' ? null : value);

const FiltersContainer = styled.div`
    flex-shrink: 0;
    margin: auto;
    padding-top: 15px;
    width: 80%;
    padding: 15px;
    background-color: white;

    & > *:not(:last-child) {
        margin-bottom: 15px;
    }

    @media ${dimension.md} {
        margin: 20px;
        width: 260px;
        height: fit-content;
    }

    .d-filter {
        margin-top: 15px;
        margin-bottom: 15px;
    }

    .filter-title {
        color: ${colors.dark_gray};
    }

    .d-filters-footer {
        display: flex;
    }
`;

const DivSpace = styled.div`
    width: 20px;
`;
