import styled from 'styled-components';
import dimension from '../../../res/styles/Responsive';

export const MaterialDatePicker = styled.input.attrs((props) => ({
    type: props.type || 'date'
}))`
    width: 150px;
    font-size: 14px;
    background-color: inherit;
    border: none;
    border-bottom: solid 1px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    &:focus {
        outline: none;
    }

    @media ${dimension.md} {
        width: auto;
        font-size: auto;
    }
`;
