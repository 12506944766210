import React from 'react';
import { FormGroup, Label } from 'reactstrap';
import styled from 'styled-components';
import { InputDate } from './Common';

const DateInput = ({ name, labelText, isHorizontal, onChange, defaultValue }) => {
    return (
        <>
            <StyledFormGroup horizontal={isHorizontal}>
                <StyledLabel for={name}>{labelText}</StyledLabel>
                <InputDate
                    name={name}
                    id={name}
                    dateAsString={defaultValue}
                    onChange={(event) => onChange(name, event.target.value)}
                />
            </StyledFormGroup>
        </>
    );
};

const StyledFormGroup = styled(FormGroup)`
    ${props =>
        props.horizontal &&
        `
    display: flex;`}
`;

const StyledLabel = styled(Label)`
    && {
        width: 50px;
        text-align: left;
        margin-top: auto;
        margin-bottom: auto;
        margin-right: 10px;
    }
`;

export default DateInput;
