import React from 'react';
import styled from 'styled-components';
import colors from '../../../res/styles/Colors';
import PermissionPane from './PermissionPane';

const THRESHOLD_SPECIES = 7;

export default function ZoneItem ({ permissionData, zoneData, speciesData, onConfirm}){

    const [permissions, setPermissions] = React.useState(permissionData);
    const [isDialogVisible, setDialogVisibility] = React.useState(false);

    React.useEffect(() => {
        setPermissions(permissionData)
    }, [permissionData]);

    const showPermissionDialog = () => {
        setDialogVisibility(true);
    };

    const onPermissionsEditingFinished = (selectedSpecies) => {
        setDialogVisibility(false);

        const updatedPermissions = selectedSpecies.map(species => {
            return { zoneCode: zoneData.zone, speciesCode: species};
        });

        onConfirm(updatedPermissions, zoneData.zone);
        setPermissions(selectedSpecies);

    };

    return (
        <ZoneItemContainer key={zoneData.zone}>
            <ZoneLabel>Zone {zoneData.zone}</ZoneLabel>

            <StyledButtonContainer>
                <StyledButton onClick={showPermissionDialog}>SPECIES</StyledButton>
            </StyledButtonContainer> 
            <InfoContainer>
                { permissions.length > 0 ? 
                    permissions.length === speciesData.length || permissions.length >= speciesData.length - THRESHOLD_SPECIES ? 
                        <BlackBoldLabel>ALL</BlackBoldLabel> 
                        : 
                        <BlackBoldLabel>{permissions.slice(0, THRESHOLD_SPECIES).join(', ')}</BlackBoldLabel>
                    :
                    <RedBoldLabel>NONE</RedBoldLabel> 
                }
                
                { permissions.length > THRESHOLD_SPECIES && permissions.length < speciesData.length ?
                    permissions.length < speciesData.length - THRESHOLD_SPECIES ?
                    <MoreLabel>and {permissions.length - THRESHOLD_SPECIES} more </MoreLabel>
                    :
                    <ExtraInfoContainer>
                        <ExceptLabel>Except: </ExceptLabel> 
                        <BlackRegularLabel>{speciesData.map(s => s.speciesCode).filter(p => !permissions.includes(p)).join(', ')}
                        </BlackRegularLabel>
                    </ExtraInfoContainer>
                    :
                    null
                }

            </InfoContainer> 

            <PermissionPane
                zoneName={zoneData.zone}
                selectedSpecies={permissions}
                allSpecies={speciesData}
                isOpen={isDialogVisible}
                onPermissionsEditingFinished={onPermissionsEditingFinished}/>

        </ZoneItemContainer>
    );
}

const ZoneItemContainer = styled.div`
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 80px;
    background: white;

    @media (max-width: 768px){
        height: 10%;
        flex-wrap: wrap;
    }
`;

const ZoneLabel = styled.p`
    font-size: 18px;
    color: ${colors.green};
    font-weight: bold;
    text-align: end;
    width: 30%;
    margin-right: 30px;

    @media (max-width: 768px){
        text-align: center;
        margin-right: 0;
        margin-top: 30px;
        width: 100%;
    }

    @media (min-width: 768px){
        margin-left: -20px;
        width: 30%;
    }

    @media (min-width: 1024px){
        text-align: end;
        width: 30%;
        margin-right: 30px;
    }
`;

const StyledButtonContainer = styled.div`
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    
    @media (max-width: 768px){
        width: 100%;
        margin-top: -10px;
    }

    @media (min-width: 768px){
        display: flex;
        justify-content: center;
        align-items: center;    
        width: 30%;
    }

    @media (min-width: 1024px){
        margin-top: 0px;
        display: flex;
        justify-content: center;
        align-items: center;    
    }
`;

const StyledButton = styled.button`
    color: white;
    height: 30px;
    width: 210px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border: none;
    background: ${colors.green};

    @media (min-width: 768px){
        width: 210px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;    
    }

    @media (min-width: 1024px){
        width: 210px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
`;

const InfoContainer = styled.div`
    width: 40%;
    display: flex;
    flex-direction: column;

    @media (max-width: 768px){
        width: 100%;
        margin-top: 5px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;    
    }

    @media (min-width: 768px){
        width: 50%;
    }

    @media (min-width: 1024px){
        width: 60%;
    }
 `;

 const ExtraInfoContainer = styled.div`
    display: 'flex';
    flex-direction: 'row';
    height: '30px';
    
    @media (max-width: 768px){
        display: flex;
        justify-content: center;
        align-items: center;    
        height: '15px';
    }

    @media (min-width: 768px){
        width: 100%;
        display: flex;
        flex-direction: 'row';
    }
 `;

const BlackBoldLabel = styled.p`
    width: 40%;
    margin-top: 25px;
    font-size: 12px;
    color: ${colors.black};
    font-weight: bold;
    margin-left: 20px;

    @media (max-width: 768px){
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;    
        height: 20px;
    }

    @media (min-width: 768px){
        width: 100%;
    }
 `;

 const RedBoldLabel = styled.p`
    width: 40%;
    margin-top: 25px;
    font-size: 12px;
    color: ${colors.red};
    font-weight: bold;
    margin-left: 20px;

    @media (max-width: 768px){
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;    
        height: 20px;
    }

    @media (min-width: 768px){
        width: 100%;
    }
 `;

 const BlackRegularLabel = styled.p`
    width: 70%;
    font-size: 12px;
    color: ${colors.black};
    font-weight: normal;
    margin-top: -12px;
    margin-left: 5px;

    @media (max-width: 768px){
        width: 100%;
    }
 `;

 const MoreLabel = styled.p`
    width: 70px;
    align-color: center;
    font-size: 12px;
    color: ${colors.green};
    font-weight: bold;
    margin-left: 30%;
    margin-top: -12px;
 `;

 const ExceptLabel = styled.p`
    width: 45px;
    font-size: 12px;
    color: ${colors.green};
    font-weight: bold;
    margin-left: 20px;
    margin-top: -12px;
`;