import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import NavigationBar from '../../components/NavigationBar/NavigationBar';
import SimpleFooter from '../../components/SimpleFooter';
import { dismissToast, toastError } from '../../ui/ToastHelper';

/**
 * @param {Object} props The history props.
 * It must contains a state key with errorCode and errorText
 */
const ErrorPage = (props) => {
    useEffect(() => {
        return () => {
            dismissToast();
        };
    }, []);

    const description =
        props.location.state != null
            ? `Error ${props.location.state.errorCode}: ${props.location.state.errorText}`
            : 'Error: Page not found';
    toastError(description);

    return (
        <div>
            <NavigationBar />
            <div className='page-content'>
                <ErrorDescription>{description}</ErrorDescription>
            </div>
            <SimpleFooter className='footer' {...props} />
        </div>
    );
};

const ErrorDescription = styled.p`
    text-align: center;
    padding-top: 20vh;
`;

export default withRouter(ErrorPage);
