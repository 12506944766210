import React from 'react';
import styled from 'styled-components';
import Dropdown from '../../components/Dropdown';
import NavigationBar from '../../components/NavigationBar/NavigationBar';
import RadioButton from '../../components/RadioButton';
import TitleBar from '../../components/TitleBar';
import buttonTheme from '../../res/styles/theme_button.module.scss';
import Checkbox from './../../components/Checkbox';
import { HorizontalDiv, VerticalDiv } from './../../components/Common';
import { getClients, saveClient } from './../../service/ClientService';
import { toastError, toastSuccess } from './../../ui/ToastHelper';
import { ControlPanelPage, PanelFieldTitle } from './components/Common';

const UI_REPORTS_STATE = 'uiReportsState';
const UI_SHOW_TECHID = 'uiShowTechId';
const UI_YIELD_STATE = 'uiYieldState';

const SHOW_TECH_ID = 1;
const HIDE_TECH_ID = 2;

const ALL_REPORTS = 1;
const LAST_REPORT = 2;

const YIELD_STARTING = 2;
const YIELD_NOT_STARTING = 1;

export default function CustomerView() {
    const [clientsMap, setClientsMap] = React.useState(new Map());
    const [currentCustomerData, setCurrentCustomerData] = React.useState();

    React.useEffect(() => {
        getClients((clients) => {
            const map = new Map();
            clients.forEach((client) => map.set(client.code, client));
            setClientsMap(map);
            setCurrentCustomerData(clients[0]);
        });
    }, []);

    const onClientChange = (clientCode) => {
        setCurrentCustomerData(clientsMap.get(clientCode));
    };

    const onChange = (fieldKey, value) => {
        setCurrentCustomerData((curr) => ({ ...curr, [fieldKey]: value }));
    };

    const onSave = () => {
        saveClient(
            currentCustomerData,
            () => {
                clientsMap.set(currentCustomerData.code, currentCustomerData);
                toastSuccess('Customer View successfully saved');
            },
            () => {
                toastError('An error occurred');
            }
        );
    };

    const clientCodes = Array.from(clientsMap.values(), (client) => client.code);

    return (
        <div>
            <NavigationBar />
            <TitleBar title='Edit Customer View' />
            <ControlPanelPage>
                <StyledContainer>
                    <PanelFieldTitle>Customer</PanelFieldTitle>
                    <Dropdown
                        id='customer'
                        emptyOption={false}
                        options={clientCodes}
                        onChange={onClientChange}
                    />
                    {currentCustomerData && (
                        <>
                            <HorizontalCheckboxContainer>
                                <Checkbox
                                    isEditable
                                    customId={UI_SHOW_TECHID}
                                    isSelected={
                                        currentCustomerData[UI_SHOW_TECHID] === SHOW_TECH_ID
                                    }
                                    onChange={(id, value) =>
                                        onChange(id, value === true ? SHOW_TECH_ID : HIDE_TECH_ID)
                                    }
                                />
                                <p>Show Tech Id</p>
                            </HorizontalCheckboxContainer>
                            <HorizontalDiv>
                                <RadioButton
                                    text='Show All Report'
                                    isSelected={
                                        currentCustomerData[UI_REPORTS_STATE] === ALL_REPORTS
                                    }
                                    onChange={() => onChange(UI_REPORTS_STATE, ALL_REPORTS)}
                                />
                                <RadioButton
                                    text='Show Last Report'
                                    // 1 = true and 2 = false on the DB
                                    isSelected={
                                        currentCustomerData[UI_REPORTS_STATE] === LAST_REPORT
                                    }
                                    onChange={() => onChange(UI_REPORTS_STATE, LAST_REPORT)}
                                />
                            </HorizontalDiv>
                            <HorizontalCheckboxContainer>
                                <Checkbox
                                    isEditable
                                    customId={UI_YIELD_STATE}
                                    // 2 = true and 1 = false on the DB
                                    isSelected={
                                        currentCustomerData[UI_YIELD_STATE] === YIELD_STARTING
                                    }
                                    onChange={(id, value) =>
                                        onChange(
                                            id,
                                            value === true ? YIELD_STARTING : YIELD_NOT_STARTING
                                        )
                                    }
                                />
                                <p>Show Yield/Ha</p>
                            </HorizontalCheckboxContainer>
                            <StyledButtonSave onClick={onSave}>SAVE CUSTOMER VIEW</StyledButtonSave>
                        </>
                    )}
                </StyledContainer>
            </ControlPanelPage>
        </div>
    );
}

const HorizontalCheckboxContainer = styled(HorizontalDiv)`
    p {
        padding-left: 25px;
    }

    .checkmark {
        top: 3px;
    }
`;

const StyledButtonSave = styled.button.attrs({
    className: buttonTheme.green
})`
    width: 100%;
    margin: 20px auto 0 auto;
`;

const StyledContainer = styled(VerticalDiv)`
    margin: 0 auto;

    input:not([type='radio']) {
        width: 150px;
        margin: auto;
        padding-left: 10px;
    }

    label {
        margin-bottom: 1rem;
    }

    select {
        width: 100%;
        margin: 0 auto 1rem auto;
        height: 30px;
        padding-left: 5px;
    }
`;
