import React from 'react';
import styled from 'styled-components';
import dimension from './../res/styles/Responsive';
import { ButtonNoStyle } from './Common';

/**
 * @deprecated do not use this anymore.
 */
const IconButton = ({ src, alt, className, onClick, children }) => {
    return (
        <StyledIconButton className={className} onClick={onClick}>
            {src && <img src={src} alt={alt} />}
            {children}
        </StyledIconButton>
    );
};

export default IconButton;

const StyledIconButton = styled(ButtonNoStyle)`
    flex-shrink: 0;
    width: 15px !important;
    height: 15px !important;

    @media ${dimension.md} {
        width: 20px !important;
        height: 20px !important;
    }

    & > * {
        width: 100%;
        height: 100%;
    }
`;
