import moment from 'moment';

export const INPUT_DATE_PATTERN = 'YYYY-MM-DD';
const UNIX_DATE_LENGTH = 10;
const UNIX_TO_LONG_FIXER = 1000;

/**
 * @param {number} dateAsLong
 */
export function formatLongToDateString(dateAsLong) {
    // We need to check the dateAsLong length because the Unix date format
    // doesn't include milliseconds and this could lead to bad date conversion
    const sanitizedLongDate =
        dateAsLong.toString.length < UNIX_DATE_LENGTH
            ? dateAsLong
            : dateAsLong * UNIX_TO_LONG_FIXER;
    return moment(sanitizedLongDate).format('DD/MM/YYYY');
}

/**
 * @param {string} dateAsString
 */
export function formatDateStringToLong(dateAsString) {
    return dateAsString == null
        ? null
        : moment(dateAsString, INPUT_DATE_PATTERN).unix() * UNIX_TO_LONG_FIXER;
}

/**
 *
 * @param {number} dateAsLong
 * @param {string} pattern
 * @param {any} alternativeResult
 */
export function getDateOrElse(dateAsLong, pattern, alternativeResult) {
    return typeof dateAsLong === 'number' ? moment(dateAsLong).format(pattern) : alternativeResult;
}

/**
 *
 * @param {number} dateAsLong
 * @param {string} pattern
 */
export function getDateOrEmpty(dateAsLong, pattern) {
    return getDateOrElse(dateAsLong, pattern, '');
}

/**
 * @returns {number} the current date
 */
export function getCurrentDateLong() {
    return moment().unix() * UNIX_TO_LONG_FIXER;
}

export function getCurrentYear() {
    return moment().year();
}

export function timeWithSeconds(time) {
    return time != null && time != '' && time?.length === 5 ? `${time}:00` : time;
}

export function timeWithoutSeconds(time) {
    return time?.length >= 5 ? time.substring(0, 5) : time;
}
