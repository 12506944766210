import moment from 'moment';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { extractExtraReportCharacters } from '../../../../../service/TechFormDetailService';
import { calculateFinalHa } from './../../../../../service/CommonFunctions';
import './extraReportPrintable.scss';

export default class PrintableCrAlert extends PureComponent {
    static propTypes = {
        crAlert: PropTypes.object
    };

    render() {
        const {
            growerName,
            lotNumber,
            clientCode,
            username,
            harvestType,
            bioConv,
            pt,
            varietyName,
            varietyCode,
            speciesCode,
            date
        } = this.props.crAlert;
        const varietyCodeString = varietyCode.toString();
        const {
            netHa,
            destroyedHa,
            destroyedReason,
            informedCustomer,
            indemnity,
            notes
        } = extractExtraReportCharacters(this.props.crAlert.extraReportCharacters);
        const formattedDate = moment(date).format('DD/MM/YYYY');
        const finalHa = calculateFinalHa(netHa.value, destroyedHa.value).toString();

        return (
            <div id='body'>
                <title>{this.props.crAlert.title}</title>
                <div id='report_header'>
                    <div id='report_header_left'>
                        <table>
                            <tbody>
                                <tr>
                                    <td>C.A.C. Soc. Coop. Agr.</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div id='report_header_right'>
                        <table>
                            <tbody>
                                <tr>
                                    <td>Rapportino di coltura del</td>
                                    <td className='report_header_content'>{formattedDate}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div id='report_header_spacer'></div>
                </div>

                <div className='report_data_table'>
                    <table>
                        <tbody>
                            <tr>
                                <td className='side_header'>COLTIVATORE</td>
                                <td className='content'>{growerName}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div className='report_data_table'>
                    <table>
                        <tbody>
                            <tr>
                                <td className='side_header'>IMPEGNATIVA</td>
                                <td className='content'>{lotNumber}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div className='report_data_table'>
                    <table>
                        <tbody>
                            <tr>
                                <td className='side_header'>
                                    SPECIE&nbsp;E&nbsp;VARIETÀ&nbsp;CLIENTE
                                </td>
                                <td className='content'>{varietyName}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className='report_data_table'>
                    <table>
                        <tbody>
                            <tr className='code'>
                                <td id='code_side_header' className='side_header'>
                                    CODICE&nbsp;CAC
                                </td>
                                <td className='code'>{speciesCode[0]}</td>
                                <td className='code'>{speciesCode[1]}</td>
                                <td></td>
                                <td className='code'>{varietyCodeString[0]}</td>
                                <td className='code'>{varietyCodeString[1]}</td>
                                <td className='code'>{varietyCodeString[2]}</td>
                                <td className='code'>{varietyCodeString[3]}</td>
                                <td className='code'>{varietyCodeString[4]}</td>
                                <td></td>
                                <td className='code'>{harvestType}</td>
                                <td></td>
                                <td className='code'>{bioConv}</td>
                                <td></td>
                                <td className='code'>{pt}</td>
                                <td></td>
                                <td className='code'>{clientCode[0]}</td>
                                <td className='code'>{clientCode[1]}</td>
                                <td className='code'>{clientCode[2]}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div className='report_data_table'>
                    <table>
                        <tbody>
                            <tr>
                                <td id='harvest_data_side_header' className='side_header'>
                                    SUPERFICIE
                                </td>
                                <td className='harvest_data_header'>INIZIALE</td>
                                <td className='harvest_data_spacer'></td>
                                <td className='harvest_data_header'>DISTRUTTA</td>
                                <td className='harvest_data_spacer'></td>
                                <td className='harvest_data_header'>RIMASTA</td>
                            </tr>
                            <tr>
                                <td></td>
                                <td className='harvest_data'>{netHa.value}</td>
                                <td className='harvest_data_spacer'></td>
                                <td className='harvest_data'>{destroyedHa.value || 0}</td>
                                <td className='harvest_data_spacer'></td>
                                <td className='harvest_data'>{finalHa}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div id='destroyed_reason_table' className='report_data_table'>
                    <table>
                        <tbody>
                            <tr>
                                <td className='side_header'>MOTIVO DELLA DISTRUZIONE</td>
                            </tr>
                            <tr>
                                <td className='free_text_content'>
                                    <div className='free_text_content_outer'>
                                        <div className='free_text_content_inner'>
                                            {destroyedReason.value || ''}
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div className='report_data_table'>
                    <table>
                        <tbody>
                            <tr className='checkmark'>
                                <td id='informed_client_side_header' className='side_header'>
                                    IL&nbsp;CLIENTE&nbsp;È&nbsp;STATO&nbsp;INFORMATO
                                </td>
                                <td className='checkmark'>
                                    {!!informedCustomer.value && <span>&#10003;</span>}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div className='report_data_table'>
                    <table>
                        <tbody>
                            <tr className='checkmark'>
                                <td id='indemnity_side_header' className='side_header'>
                                    ALL'ATTENZIONE&nbsp;DELL'UFFICIO&nbsp;SOCI
                                </td>
                                <td className='checkmark'>
                                    {!!indemnity.value && <span>&#10003;</span>}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div id='note_table' className='report_data_table'>
                    <table>
                        <tbody>
                            <tr>
                                <td className='side_header'>NOTE</td>
                            </tr>
                            <tr>
                                <td className='free_text_content'>
                                    <div className='free_text_content_outer'>
                                        <div className='free_text_content_inner'>
                                            {notes.value || ''}
                                            <br />
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div id='report_footer'>
                    <div id='report_footer_left'></div>
                    <div id='report_footer_right'>
                        <table>
                            <tbody>
                                <tr>
                                    <td className='report_footer_header'>IL TECNICO</td>
                                </tr>
                                <tr>
                                    <td className='report_footer_content'>{username}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div id='report_footer_spacer'></div>
                </div>
            </div>
        );
    }
}
