import React from 'react';
import styled from 'styled-components';
import { VerticalDiv } from '../Common';

const LinePoint = ({ className, color, circlePosition = 'top' }) => {
    return (
        <div className={className}>
            <Line color={color} circlePosition={circlePosition} />
        </div>
    );
};

const Line = styled(VerticalDiv)`
    width: 2px;
    height: 100%;
    margin: auto;
    background-color: ${({ color }) => color || 'black'};

    :after {
        ${({ circlePosition }) =>
            circlePosition === 'top' ? `margin-bottom: auto;` : `margin-top: auto;`}
        width: 10px;
        height: 10px;
        margin-left: -4px;
        background-color: ${({ color }) => color || 'black'};
        border-radius: 50px;
        content: '';
        display: flex;
    }
`;

export default LinePoint;
