import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

Dropdown.propTypes = {
    className: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.string),
    optgroups: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            options: PropTypes.arrayOf(PropTypes.string)
        })
    ),
    defaultValue: PropTypes.number,
    emptyOption: PropTypes.bool,
    onClick: PropTypes.func,
    onChange: PropTypes.func
};

Dropdown.defaultProps = {
    options: [],
    optgroups: [],
    emptyOption: true
};

function Dropdown(props) {
    const { className, options, optgroups, emptyOption, onClick, onChange, ...rest } = props;

    const validateClick = (event) => {
        if (event.target != null && onClick != null) {
            onClick(event.target.value);
        }
    };

    const validateChange = (event) => {
        if (event.target != null && onChange != null) {
            onChange(event.target.value);
        }
    };

    return (
        <BasicDropdown
            className={className}
            onClick={validateClick}
            onChange={validateChange}
            {...rest}>
            {emptyOption && <option></option>}
            {optgroups.length === 0 &&
                options.length > 0 &&
                options.map((value, index) => <option key={index}>{value}</option>)}
            {options.length === 0 &&
                optgroups.length > 0 &&
                optgroups.map((group) => (
                    <optgroup label={group.label} key={group.label}>
                        {group.options.map((value) => (
                            <option key={value}>{value}</option>
                        ))}
                    </optgroup>
                ))}
        </BasicDropdown>
    );
}

export const BasicDropdown = styled.select`
    border-radius: 5px;
    height: 30px;
    box-shadow: 0 1px 2px grey;
    border: solid 1px #ccc;
    margin-bottom: 10px;

    :focus {
        outline: none;
    }
`;

export default Dropdown;
