import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { isMobileOnly } from 'react-device-detect';
import styled from 'styled-components';
import {
    ClickableText,
    CustomLink,
    EmptyDiv,
    FlexDiv,
    HorizontalDiv,
    ImgButton,
    VerticalDiv
} from '../../../components/Common';
import ExpandIcon from '../../../components/ExpandIcon';
import colors from '../../../res/styles/Colors';
import { getName } from '../../../service/VisitService';
import { timeWithoutSeconds } from '../../../utils/DateFormatter';

const DATE_FORMAT = 'ddd - DD MMM YY';

VisitItem.propTypes = {
    visit: PropTypes.shape({
        date: PropTypes.number.isRequired
    }).isRequired,
    isEditable: PropTypes.bool,
    onDelete: PropTypes.func,
    onEditDesktop: PropTypes.func,
    onCloneDesktop: PropTypes.func
};

export default function VisitItem(props) {
    const [deletionRequest, setDeletionRequest] = React.useState(false);

    const onDelete = () => {
        // prevent click twice
        if (!deletionRequest) {
            setDeletionRequest(true);
            props.onDelete();
        }
    };

    const { users, otherUsers } = props.visit;
    const staff = React.useMemo(() => {
        const usernames = (users || []).map((u) => getName(u));
        return [...usernames, ...otherUsers].join(', ');
    }, [users, otherUsers]);

    return (
        <StyledContainer>
            {isMobileOnly ? (
                <VisitItemMobile {...props} staff={staff} onDelete={onDelete} />
            ) : (
                <VisitItemDesktop
                    {...props}
                    staff={staff}
                    onDelete={onDelete}
                    onEditDesktop={props.onEditDesktop}
                    onCloneDesktop={props.onCloneDesktop}
                />
            )}
        </StyledContainer>
    );
}

const StyledContainer = styled.div`
    p {
        font-size: 13px;
    }
`;

function VisitItemMobile(props) {
    const [isOpen, setOpen] = React.useState(false);
    return (
        <div>
            <HorizontalDiv style={{ backgroundColor: 'white' }}>
                <EmptyDiv
                    width='5px'
                    style={{ backgroundColor: isOpen ? colors.green : 'white' }}
                />
                <div style={{ width: '100%', padding: '10px' }}>
                    <VisitItemTitleBar
                        visit={props.visit}
                        isEditable={props.isEditable}
                        customActionButton={<ExpandIcon onClick={() => setOpen((curr) => !curr)} />}
                    />
                    <FlexDiv justifyContent='space-between'>
                        <VisitItemField
                            style={{ flexBasis: '33%' }}
                            title='Pick-up Time'
                            content={timeWithoutSeconds(props.visit.time) || '-'}
                            contentColor={colors.green}
                        />
                        <VisitItemField
                            style={{ flexBasis: '33%' }}
                            title='Customer'
                            content={props.visit.client?.code || '-'}
                        />
                        <VisitItemField
                            style={{ flexBasis: '33%' }}
                            title='Contacts'
                            content={props.visit.contacts || '-'}
                        />
                        <ImgButton
                            style={{ marginTop: 'auto' }}
                            src={require('../../../res/images/cac_trash_visits.png')}
                            onClick={props.onDelete}
                        />
                    </FlexDiv>
                </div>
            </HorizontalDiv>
            {isOpen && (
                <VerticalDiv style={{ backgroundColor: colors.lighter_green, padding: '15px' }}>
                    <VisitItemField title='Pick-up Place' content={props.visit.place || '-'} />
                    <HorizontalDiv>
                        <VisitItemField
                            style={{ flexBasis: '50%' }}
                            title='Staff Assigned'
                            content={props.staff || '-'}
                        />
                        <VisitItemField
                            style={{ flexBasis: '50%' }}
                            title='Notes'
                            content={props.visit.notes || '-'}
                        />
                    </HorizontalDiv>
                </VerticalDiv>
            )}
        </div>
    );
}

function VisitItemDesktop(props) {
    return (
        <div style={{ backgroundColor: 'white', padding: '15px' }}>
            <VisitItemTitleBar
                visit={props.visit}
                isEditable={props.isEditable}
                onEditDesktop={props.onEditDesktop}
                onCloneDesktop={props.onCloneDesktop}
                customActionButton={
                    <ImgButton
                        src={require('../../../res/images/cac_trash_visits.png')}
                        onClick={props.onDelete}
                    />
                }
            />
            <div style={{ display: 'grid', gridTemplateColumns: '7fr 3fr' }}>
                <div>
                    <div style={{ display: 'grid', gridTemplateColumns: '4fr 4fr 4fr 5fr' }}>
                        <VisitItemField
                            title='Pick-up Time'
                            content={timeWithoutSeconds(props.visit?.time) || '-'}
                            contentColor={colors.green}
                        />
                        <VisitItemField
                            title='Customer'
                            content={props.visit?.client?.code || '-'}
                        />
                        <VisitItemField title='Contact' content={props.visit?.contacts || '-'} />
                        <VisitItemField title='Pick-up Place' content={props.visit?.place || '-'} />
                    </div>
                    <VisitItemField title='Staff Assigned' content={props.staff || '-'} />
                </div>
                <div>
                    <VisitItemField title='Notes' content={props.visit?.notes || '-'} />
                </div>
            </div>
        </div>
    );
}

VisitItemTitleBar.propTypes = {
    visit: PropTypes.shape({
        pk: PropTypes.number,
        date: PropTypes.number.isRequired
    }).isRequired,
    isEditable: PropTypes.bool,
    customActionButton: PropTypes.object,
    onEditDesktop: PropTypes.func,
    onCloneDesktop: PropTypes.func
};

function VisitItemTitleBar(props) {
    const visitToClone = {
        ...props.visit,
        pk: null,
        date: moment(props.visit?.date)
            .add(1, 'day')
            .valueOf()
    };
    return (
        <StyledTitleContainer justifyContent='space-between'>
            <FlexDiv alignItems='center'>
                <h5>{moment(props.visit.date).format(DATE_FORMAT)}</h5>
                {props.isEditable && (
                    <>
                        <EmptyDiv width='20px' />
                        <StyledLink
                            className='d-block d-md-none'
                            color={colors.red}
                            to={{ pathname: `/visits/edit/${props.visit.pk}`, data: props.visit }}>
                            Edit
                        </StyledLink>
                        <ClickableText
                            className='d-none d-md-block'
                            color={colors.red}
                            onClick={props?.onEditDesktop}>
                            Edit
                        </ClickableText>
                        <p
                            style={{
                                padding: '0 8px',
                                fontSize: '9px',
                                transform: 'translateY(-10%)'
                            }}>
                            ●
                        </p>
                        <StyledLink
                            className='d-block d-md-none'
                            color={colors.red}
                            to={{ pathname: '/visits/create', data: visitToClone }}>
                            Clone
                        </StyledLink>
                        <ClickableText
                            className='d-none d-md-block'
                            color={colors.red}
                            onClick={props?.onCloneDesktop}>
                            Clone
                        </ClickableText>
                    </>
                )}
            </FlexDiv>
            {props.customActionButton && props.customActionButton}
        </StyledTitleContainer>
    );
}

const StyledTitleContainer = styled(FlexDiv)`
    p {
        margin-bottom: 0.5rem;
    }
`;

const StyledLink = styled(CustomLink)`
    font-size: 13px;
    margin-bottom: 0.5rem;
`;

VisitItemField.propTypes = {
    title: PropTypes.string,
    content: PropTypes.string,
    contentColor: PropTypes.string
};

function VisitItemField(props) {
    return (
        <VerticalDiv className={props.className} style={props.style}>
            <p style={{ marginBottom: '0', fontWeight: 'bold' }}>{props.title}</p>
            <p style={{ color: props.contentColor }}>{props.content}</p>
        </VerticalDiv>
    );
}
