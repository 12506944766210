/* eslint-disable import/prefer-default-export */
import axios from 'axios';

const GET_USERS = '/users';
const GET_USER_PERMISSIONS = '/users/permissions';
const ADD_USER = '/users';
const UPDATE_USER = '/users';

export function getUsers(callback) {
    axios({ method: 'get', url: GET_USERS })
        .then((result) => {
            callback(result.data);
        })
        .catch(() => {
            callback([]);
        });
}

export function getUserPermissions(userPk, callback) {
    const getUserUrl = `${GET_USER_PERMISSIONS}/${userPk}`;
    axios({ method: 'get', url: getUserUrl })
        .then((result) => {
            callback(result.data);
        })
        .catch(() => {
            callback([]);
        });
}

export function addUser(user, callback) {
    axios({ method: 'post', url: ADD_USER, data: user })
        .then((result) => {
            callback(result.data);
        })
        .catch(() => {
            callback(null);
        });
}

export function updateUser(user, callback) {
    const updateUserUrl = `${UPDATE_USER}/${user.pk}`;
    axios({ method: 'post', url: updateUserUrl, data: user })
        .then((result) => {
            callback(result.data);
        })
        .catch(() => {
            callback(null);
        });
}
