/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { A, BOLT, HTTP_CODE, LINES, OP, ReportCharactersNames, STAND, U } from '../res/Constants';
import { getCurrentDateLong } from '../utils/DateFormatter';
import { CAC_TYPE } from '../res/Constants';

const SAVE_REPORT_CHARACTERS = '/reports/characters';
const SAVE_REPORT = '/reports';

export const REPORT_YIELD = 'expYield';
export const REPORT_HARVEST_DATA = 'harvestData';

/**
 * @typedef {Object} LinesData
 * @property {Object} lineA
 * @property {Object} lineB
 * @property {Object} lineU
 */

/**
 * @param {number} linesCount
 * @param {Object} reportData
 * @returns {LinesData}
 */
export const extractLinesData = (linesCount, reportData) => {
    const result = {};

    LINES.slice(0, linesCount).forEach((lineName) => {
        const objValue = {};
        Object.keys(ReportCharactersNames)
            .filter((name) => name !== REPORT_YIELD && name !== REPORT_HARVEST_DATA)
            .forEach((fieldName) => {
                objValue[ReportCharactersNames[fieldName]] = reportData[`${fieldName}${lineName}`];
            });
        objValue.lineName = linesCount === 1 ? OP : lineName;
        objValue.photosCount = reportData.photosCount;
        const key = `line${lineName}`;
        result[key] = objValue;
    });

    result.lineU = {
        lineName: U,
        [ReportCharactersNames.expYield]: reportData[REPORT_YIELD],
        [ReportCharactersNames.harvestData]: reportData[REPORT_HARVEST_DATA]
    };
    return result;
};

export function createEmptyReport() {
    return Object.keys(ReportCharactersNames).reduce((obj, key) => ({ ...obj, [key]: '' }), {});
}

/**
 * @param {Number} reportPk
 * @param {LinesData} reportData
 * @param {Number} techformType
 * @param {Function} callback
 */
export function saveReportCharacters(reportPk, reportData, techformType, callback) {
    const reportFields = new Set(Object.values(ReportCharactersNames));
    const data = Object.values(reportData).flatMap((obj) =>
        Object.entries(obj)
            .filter((entries) => reportFields.has(entries[0]))
            .map((entries) => ({
                reportPk,
                line: obj.lineName === OP ? techformType === CAC_TYPE.CROP ? A : U : obj.lineName,
                name: entries[0],
                value: sanitizeReportValue(entries[0], entries[1]),
                lastUpdate: getCurrentDateLong()
            }))
            .filter((viewModel) => viewModel.name != null)
    );

    axios({
        method: 'post',
        url: SAVE_REPORT_CHARACTERS,
        data
    })
        .then((result) => {
            if (result.status === HTTP_CODE.OK) {
                callback(result.data);
            }
        })
        .catch();
}

const fieldsDefaultToZero = new Set([BOLT, STAND, 'Uniformity', 'DiseasesGravity']);

/**
 * Bolt, Stand, Uniformity and DiseasesGravity characters should have 0 as default value.
 * Develop should have 1 as default value.
 * Other characters can have NULL as default value.
 */
function sanitizeReportValue(charName, charValue) {
    if (fieldsDefaultToZero.has(charName)) {
        if (charValue === '' || charValue === '-' || charValue == null) {
            return 0;
        }
    }
    if (charName === 'Develop') {
        return charValue >= 1 && charValue <= 4 ? charValue : 1;
    }
    return charValue;
}

export function saveReport(reportPk, techFormData, reportData, callback) {
    if (reportPk == null) {
        const newReport = {
            pk: reportPk,
            techFormPk: techFormData.techFormPk,
            date: getCurrentDateLong()
        };
        axios.post(SAVE_REPORT, newReport).then((result) => {
            if (result.status === HTTP_CODE.OK) {
                const newReportPk = result.data.pk;
                saveReportCharacters(newReportPk, reportData, techFormData.cacType, callback);
            }
        });
    } else {
        saveReportCharacters(reportPk, reportData, techFormData.cacType, callback);
    }
}
