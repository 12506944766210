import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { isMobileOnly } from 'react-device-detect';
import styled from 'styled-components';
import dimension from './../res/styles/Responsive';
import { formatDateStringToLong } from './../utils/DateFormatter';
import { EmptyDiv, getEditIcon, HorizontalDiv, ImgButton, VerticalDiv } from './Common';
import Date from './Date/Date';
import DateCompact from './Date/DateCompact';

const saveIcon = require('../res/images/cac_save_icon.png');
const cancelIcon = require('../res/images/cac_cancel_icon.png');

class Timeline extends PureComponent {
    static propTypes = {
        className: PropTypes.string,
        title: PropTypes.string,
        dates: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string,
                id: PropTypes.string,
                date: PropTypes.number
            }).isRequired
        ),
        titleColor: PropTypes.string,
        timelineColor: PropTypes.string,
        canBeEditable: PropTypes.bool,
        onDateEdited: PropTypes.func,
        onSave: PropTypes.func
    };

    static defaultProps = {
        dates: []
    };

    state = {
        isEditable: false
    };

    onEditing = () => {
        this.setState({
            isEditable: true
        });
    };

    onCancel = () => {
        this.setState({
            isEditable: false
        });
    };

    onSave = () => {
        this.props.onSave && this.props.onSave();
        this.setState({
            isEditable: false
        });
    };

    onDateEdited = (id, newDate) => {
        this.onDateEdited && this.props.onDateEdited(id, formatDateStringToLong(newDate));
    };

    render() {
        const title = (
            <Title className='title' color={this.props.titleColor}>
                {this.props.title}
            </Title>
        );
        return (
            <StyledVerticalDiv className={this.props.className}>
                {this.state.isEditable && (
                    <EditingButtonsContainer>
                        <ImgButton src={saveIcon} alt='save the dates' onClick={this.onSave} />
                        <ImgButton
                            src={cancelIcon}
                            alt='cancel the editing'
                            onClick={this.onCancel}
                        />
                    </EditingButtonsContainer>
                )}
                <TitleContainer>
                    {title}
                    {this.props.canBeEditable && !this.state.isEditable && (
                        <>
                            <EmptyDiv width='10px' />
                            <ImgButton
                                src={getEditIcon(this.props.titleColor)}
                                alt='edit the dates'
                                onClick={this.onEditing}></ImgButton>
                        </>
                    )}
                </TitleContainer>
                {this.props.dates.map((value, index) => (
                    <ResponsiveDate
                        key={index}
                        date={value.date}
                        content={value.name}
                        id={value.id}
                        hasLine={index > 0}
                        color={this.props.timelineColor}
                        isEditable={this.state.isEditable}
                        onChange={this.onDateEdited}
                    />
                ))}
            </StyledVerticalDiv>
        );
    }
}

const ResponsiveDate = (props) => {
    return (
        <>
            {isMobileOnly ? (
                <StyledDateCompact {...props} />
            ) : (
                <StyledDate {...props} hasLine={true} />
            )}
        </>
    );
};

const StyledVerticalDiv = styled(VerticalDiv)`
    padding: 15px 0;

    & > .content {
        color: ${({ color }) => color || 'black'};
    }
`;

const StyledDate = styled(Date)`
    p {
        font-size: 13px;
        margin-bottom: 1rem;
    }
`;

const StyledDateCompact = styled(DateCompact)`
    p {
        margin-bottom: 0;
        font-size: 13px;
        text-align: center;
    }
`;

const TitleContainer = styled(HorizontalDiv)`
    justify-content: center;

    @media ${dimension.md} {
        justify-content: start;
    }
`;

const Title = styled.h6`
    color: ${({ color }) => color};
`;

const EditingButtonsContainer = styled(HorizontalDiv)`
    justify-content: space-around;
    padding-bottom: 5px;
`;

export default Timeline;
