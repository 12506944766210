import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { VerticalDiv } from '../../../components/Common';
import colors from '../../../res/styles/Colors';
import ZoneItem from './ZoneItem';

export default class ZoneList extends PureComponent {
    
    static propTypes = {
        zoneList:  PropTypes.arrayOf(PropTypes.object),
        speciesList:  PropTypes.arrayOf(PropTypes.object),
        permissionsList:  PropTypes.arrayOf(PropTypes.object),
        onConfirm: PropTypes.func
    };
   

    getPermissionsByZone = (zone) => {
        const zonePermissions = [];
        if(this.props.permissionsList != null){
            this.props.permissionsList.forEach((permission) => {
                if(permission.zone.code == zone.zone) {
                    zonePermissions.push(permission.species.code);
                }
            });    
        }
        return zonePermissions;
    };

    render(){
        return (
            <ZoneListContainer>
                {this.props.zoneList &&
                    this.props.zoneList.map((zone, index) => (
                        <ZoneItem
                            key={index}
                            className='zone'
                            zoneData={zone}
                            speciesData={this.props.speciesList}
                            permissionData={this.getPermissionsByZone(zone)}
                            onConfirm={this.props.onConfirm}
                        />
                    ))}
            </ZoneListContainer>
        );    
    }
}

const ZoneListContainer = styled(VerticalDiv)`
    background-color: ${colors.light_gray};
    margin-top: 5%;
    width: 100%;
`;
