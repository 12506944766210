import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { BrowserView, MobileView } from 'react-device-detect';
import { Nav, NavItem, NavLink } from 'reactstrap';
import styled from 'styled-components';
import colors from './../res/styles/Colors';
import SimpleButtonDropdown from './SimpleButtonDropdown';

class NavigationTabs extends PureComponent {
    static propTypes = {
        activeTab: PropTypes.number,
        mainColor: PropTypes.string.isRequired,
        dropdownClassName: PropTypes.string.isRequired,
        values: PropTypes.arrayOf(PropTypes.string),
        onTabSelection: PropTypes.func
    };

    state = {
        activeTabIndex: this.props.activeTab
    };

    static getDerivedStateFromProps(props, state) {
        // fix needed because hiding the CR-Alert section leads
        // to a bad behaviour of the tab due to wrong index
        return { activeTabIndex: Math.min(props.activeTab, props.values.length - 1) };
    }

    onTabSelection = (index) => {
        this.setState({ activeTabIndex: index });
        this.props.onTabSelection && this.props.onTabSelection(index);
    };

    render() {
        return (
            <>
                <MobileView viewClassName='w-100' style={{ marginBottom: 'auto' }}>
                    <StyledDropdown
                        values={this.props.values}
                        text={this.props.values[this.state.activeTabIndex]}
                        className={this.props.dropdownClassName}
                        onChangeValue={(index) => this.onTabSelection(index)}
                    />
                </MobileView>
                <BrowserView viewClassName='w-100' style={{ marginTop: 'auto' }}>
                    <StyledNav tabs>
                        {this.props.values.map((item, index) => (
                            <StyledNavItem key={[item, index].join('')}>
                                <StyledNavLink
                                    maincolor={this.props.mainColor}
                                    className={classnames({
                                        active: this.state.activeTabIndex === index
                                    })}
                                    onClick={() => this.onTabSelection(index)}>
                                    {item}
                                </StyledNavLink>
                            </StyledNavItem>
                        ))}
                    </StyledNav>
                </BrowserView>
            </>
        );
    }
}

const StyledNavLink = styled(NavLink)`
    && {
        color: black;
        border-radius: 0;
        font-weight: bold;

        &.active {
            color: ${(props) => props.maincolor};
            border: 1px solid transparent;
            border-bottom: 3px solid ${(props) => props.maincolor};
        }

        &:focus {
            border: 1px solid transparent;
        }

        &:hover {
            background-color: ${colors.light_gray};
        }
    }
`;

const StyledNavItem = styled(NavItem)`
    flex-grow: 1;
`;

const StyledNav = styled(Nav)`
    width: 100%;
    margin-top: auto;
    justify-content: space-around;
    border: 1px solid transparent;

    * {
        user-select: none;
    }
`;

const StyledDropdown = styled(SimpleButtonDropdown)`
    width: 80%;
    margin: auto;
`;

export default NavigationTabs;
