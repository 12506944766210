import PropTypes from 'prop-types';
import React from 'react';
import {
    APP_DOWNLOADS,
    DOWNLOAD_CROP_APP,
    DOWNLOAD_CUSTOMER_VISITS_APP,
    DOWNLOAD_NURSERY_APP,
    DOWNLOAD_INVENTORY_APP,
    LOGOUT,
    SETTINGS,
    USER_ROLES
} from '../../../res/Constants';
import { getCurrentRole, isCurrentUserAdmin } from '../../../service/AccountService';
import PopoverMenu, { PopoverButtonItem } from '../../PopoverMenu';

HamburgerMenu.propTypes = {
    className: PropTypes.string,
    username: PropTypes.string,
    onLogout: PropTypes.func,
    onSettings: PropTypes.func
};

export default function HamburgerMenu(props) {
    const [popoverOpen, setPopoverOpen] = React.useState(false);

    const onTogglePopover = () => {
        setPopoverOpen((curr) => !curr);
    };

    return (
        <div className={props.className}>
            <img
                className='hamburger-icon'
                id='hamburger-icon'
                src={require('../../../res/images/cac_hamburger.png')}
                alt='Settings'
                onClick={onTogglePopover}
                style={{ cursor: 'pointer' }}
            />
            <PopoverMenu
                target='hamburger-icon'
                isOpen={popoverOpen}
                header={props.username}
                onToggle={onTogglePopover}>
                <PopoverButtonItem style={{ textAlign: 'center' }} onClick={props.onLogout}>
                    {LOGOUT}
                </PopoverButtonItem>
                {isCurrentUserAdmin() && (
                    <PopoverButtonItem style={{ textAlign: 'center' }} onClick={props.onSettings}>
                        {SETTINGS}
                    </PopoverButtonItem>
                )}
                {getCurrentRole() !== USER_ROLES.client && (
                    <>
                        <PopoverButtonItem
                            as='a'
                            style={{ textAlign: 'center' }}
                            href={APP_DOWNLOADS.CROP}
                            target='_blank'>
                            {DOWNLOAD_CROP_APP}
                        </PopoverButtonItem>
                        <PopoverButtonItem
                            as='a'
                            style={{ textAlign: 'center' }}
                            href={APP_DOWNLOADS.NURSERY}
                            target='_blank'>
                            {DOWNLOAD_NURSERY_APP}
                        </PopoverButtonItem>
                        <PopoverButtonItem
                            as='a'
                            style={{ textAlign: 'center' }}
                            href={APP_DOWNLOADS.CUSTOMER_VISITS}
                            target='_blank'>
                            {DOWNLOAD_CUSTOMER_VISITS_APP}
                        </PopoverButtonItem>
                        <PopoverButtonItem
                            as='a'
                            style={{ textAlign: 'center' }}
                            href={APP_DOWNLOADS.INVENTORY}
                            target='_blank'>
                            {DOWNLOAD_INVENTORY_APP}
                        </PopoverButtonItem>
                    </>
                )}
            </PopoverMenu>
        </div>
    );
}
