export const CAC_TYPE_STORAGE = 'cacType';

export function saveCacType(type) {
    localStorage.setItem(CAC_TYPE_STORAGE, type);
}

export function loadCacType() {
    const currentType = localStorage.getItem(CAC_TYPE_STORAGE);

    return JSON.parse(currentType);
}
