import React from 'react';
import styled from 'styled-components';
import AppVersion from '../service/InfoService';
import colors from './../res/styles/Colors';
import dimension from './../res/styles/Responsive';

const SimpleFooter = (props) => {
    return (
        <StyledFooter className='row footer'>
            <p className='d-block d-md-none'>
                Cooperativa Agricola Cesenate Società Cooperativa <br />
                Via Calcinaro 1450, 47521 Martorano di Cesena (FC) <br />
                P.IVA/C.F. 00144040409
            </p>
            <p className='d-none d-md-block'>
                Cooperativa Agricola Cesenate Società Cooperativa - Via Calcinaro 1450, 47521
                Martorano di Cesena (FC) - P.IVA/C.F. 00144040409
            </p>
            <StyledAppVersion>Ver. {AppVersion()}</StyledAppVersion>
        </StyledFooter>
    );
};

const StyledFooter = styled.div`
    width: 100%;
    padding-left: 20px;
    font-size: 10px;
    color: white;
    background-color: ${colors.red};
    border-top: 2px solid ${colors.dark_red};

    p {
        margin: 0;
    }

    @media ${dimension.md} {
        font-size: 16px;

        p {
            margin: auto 0;
        }
    }
`;

const StyledAppVersion = styled.p`
    && {
        margin: auto 10px auto auto;
    }
`;

export default SimpleFooter;
