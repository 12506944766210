import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { Row } from 'reactstrap';
import styled from 'styled-components';
import { ColNoPadding } from '../../../../../components/Common';
import ExpandIcon from '../../../../../components/ExpandIcon';
import InfoText from '../../../../../components/InfoText';
import ListItem from '../../../../../components/ListItem';
import { LINE_A, LINE_B, LINE_OP, SOWING } from '../../../../../res/Constants';
import { GreenColumn, GreenTimeLine, RedColumn, RedTimeLine, TitleRow } from '../components/Common';

const PLANT_NUMBER = 'Plant Number';
const TRANSPLANT_DATES = 'Transplant Dates';
const NURSERY_DATE = 'Nursery Dates';

export default class SowingTransplating extends PureComponent {
    static propTypes = {
        techFormData: PropTypes.object.isRequired
    };

    render() {
        return (
            <>
                {isMobileOnly ? (
                    <SowingMobile techFormData={this.props.techFormData} />
                ) : (
                    <SowingDesktop techFormData={this.props.techFormData} />
                )}
            </>
        );
    }
}

const SowingMobile = ({ techFormData }) => {
    const expandableChildren = (
        <Row>
            <GreenColumn>
                <LineA techFormData={techFormData} />
            </GreenColumn>
            {techFormData.line > 1 && (
                <RedColumn>
                    <LineB techFormData={techFormData} />
                </RedColumn>
            )}
        </Row>
    );

    return (
        <StyledListItem expandableChildren={expandableChildren}>
            {(renderProps) => (
                <TitleRow>
                    <ColNoPadding xs='8' md='12'>
                        <h5>{SOWING}</h5>
                        <p style={{ marginBottom: '0' }}>Transplanting Info</p>
                    </ColNoPadding>
                    <ColNoPadding className='d-md-none' xs='auto'>
                        <ExpandIcon onClick={renderProps.toggleExpand} />
                    </ColNoPadding>
                </TitleRow>
            )}
        </StyledListItem>
    );
};

const SowingDesktop = ({ techFormData }) => {
    return (
        <Row>
            <GreenColumn md='5'>
                <ListItem>{() => <LineA techFormData={techFormData} />}</ListItem>
            </GreenColumn>
            {techFormData.line > 1 && (
                <RedColumn
                    md={{
                        size: 5,
                        offset: 1
                    }}>
                    <ListItem>{() => <LineB techFormData={techFormData} />}</ListItem>
                </RedColumn>
            )}
        </Row>
    );
};

const LineA = ({ techFormData }) => {
    const { line, plantsNumberA, sowingTransA, nurserySowingA } = techFormData;

    return (
        <>
            <h5>{line > 1 ? LINE_A : LINE_OP}</h5>
            <InfoText title={PLANT_NUMBER} content={plantsNumberA} />
            <GreenTimeLine title={TRANSPLANT_DATES} dates={sowingTransA} />
            <GreenTimeLine title={NURSERY_DATE} dates={nurserySowingA} />
        </>
    );
};

const LineB = ({ techFormData }) => {
    const { plantsNumberB, sowingTransB, nurserySowingB } = techFormData;

    return (
        <>
            <h5>{LINE_B}</h5>
            <InfoText title={PLANT_NUMBER} content={plantsNumberB} />
            <RedTimeLine title={TRANSPLANT_DATES} dates={sowingTransB} />
            <RedTimeLine title={NURSERY_DATE} dates={nurserySowingB} />
        </>
    );
};

const StyledListItem = styled(ListItem)`
    .inner-container-expandable {
        padding: 0;
    }
`;
