import * as _ from 'lodash';
import { PropTypes } from 'prop-types';
import React, { PureComponent } from 'react';
import onClickOutside from 'react-onclickoutside';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Nav } from 'reactstrap';
import styled from 'styled-components';
import colors from '../res/styles/Colors';
import { randomId } from '../utils/Utils';

const EMPTY_VALUE = {
    label: '',
    key: randomId()
};

class SimpleInputDropdown extends PureComponent {
    static propTypes = {
        options: PropTypes.arrayOf(
            PropTypes.shape({
                label: PropTypes.string.isRequired,
                key: PropTypes.string.isRequired
            })
        ).isRequired,
        className: PropTypes.string,
        text: PropTypes.string,
        isInput: PropTypes.bool,
        isScrollable: PropTypes.bool,
        hasEmptyValue: PropTypes.bool,
        keys: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
        onChangeValue: PropTypes.func
    };

    static defaultProps = {
        isScrollable: false,
        hasEmptyValue: false,
        keys: []
    };

    state = { dropdownOpen: false, freeText: '' };

    toggle = () => {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen,
            freeText: ''
        });
    };

    handleDropdownItemClick = (index, selectedValue) => {
        if (this.props.onChangeValue == null) {
            return;
        }
        const selectedIndex = this.props.options.findIndex((v) => _.isEqual(v, selectedValue));
        this.props.onChangeValue(selectedIndex === -1 ? null : selectedIndex);
    };

    handleClickOutside() {
        this.setState({ freeText: '' });
    }

    onChangeFreeText = (event) => {
        const value = event?.target?.value;
        if (value != null) {
            this.setState({ freeText: value });
        }
    };

    render() {
        const options = this.props.hasEmptyValue
            ? [EMPTY_VALUE, ...this.props.options]
            : this.props.options;
        const filteredOptions =
            this.state.freeText.length > 0
                ? options.filter((v) =>
                      v.label?.toLowerCase()?.includes(this.state.freeText?.toLowerCase())
                  )
                : options;
        return (
            <StyledNav className={['button-dropdown', this.props.className].join(' ')}>
                <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
                    <DropdownToggle nav caret>
                        {this.props.isInput && this.state.dropdownOpen ? (
                            <StyledInput autoFocus type='text' onChange={this.onChangeFreeText} />
                        ) : (
                            this.props.text
                        )}
                    </DropdownToggle>
                    {/* .toString() required by React */}
                    {filteredOptions && (
                        <StyledDropdownMenu scrollable={this.props.isScrollable.toString()}>
                            {filteredOptions.map((option, index) => (
                                <StyledDropdownItem
                                    key={index}
                                    onClick={() => this.handleDropdownItemClick(index, option)}>
                                    {option.label}
                                </StyledDropdownItem>
                            ))}
                        </StyledDropdownMenu>
                    )}
                </Dropdown>
            </StyledNav>
        );
    }
}

export default onClickOutside(SimpleInputDropdown);

const StyledInput = styled.input``;

const StyledNav = styled(Nav)`
    border-radius: 50px;

    .dropdown {
        width: 100%;
        text-align: center;
    }

    .dropdown-toggle::after {
        position: absolute;
        right: 13px;
        top: 50%;
        margin-top: -3px;
    }

    .dropdown a,
    .nav-item a,
    .nav-link a {
        background-color: inherit;
        color: inherit;
        font-size: 12px;
    }

    .dropdown-item:hover {
        background-color: ${colors.light_red};
    }

    button:focus {
        outline: none;
    }

    .nav-link.active {
        background-color: ${colors.lighter_green};
        color: $green;
        border-radius: 50px;
    }

    p {
        font-size: 14px;
        margin: auto;
    }
`;

const StyledDropdownMenu = styled(DropdownMenu)`
    &.show {
        width: 100%;
        border-radius: 5px;
        overflow-y: hidden;

        ${({ scrollable }) =>
            scrollable &&
            `
            max-height: 300px;
            overflow-y: scroll; 
            `}
    }
`;

const StyledDropdownItem = styled(DropdownItem)`
    height: 30px;
    padding: 5px 15px;
    font-size: 14px;
`;
