import { SORT_TYPE } from '../res/Constants';

/**
 * Sort a copy of the passed list
 * @param {array[]} list
 * @param {SORT_TYPE} sortType
 * @param {string} propName
 * @returns {array[]} sortedList
 */
export function sort(list, sortType, propName) {
    if (list == null || list.length === 0) {
        return [];
    }
    const sortedList = [...list];
    let comparator = null;
    if (typeof list[0][propName] === 'number') {
        comparator =
            sortType === SORT_TYPE.ASCENDANT
                ? (a, b) => a[propName] - b[propName]
                : (a, b) => b[propName] - a[propName];
    } else if (typeof list[0][propName] === 'boolean') {
        comparator = sortType === SORT_TYPE.ASCENDANT ? (a, b) => b - a : (a, b) => a - b;
    } else {
        comparator =
            sortType === SORT_TYPE.ASCENDANT
                ? (a, b) => a[propName].localeCompare(b[propName])
                : (a, b) => b[propName].localeCompare(a[propName]);
    }
    if (comparator != null) {
        sortedList.sort(comparator);
    }
    return sortedList;
}

export function filterByFields(list, fieldsToFilterOn, valueToSearch) {
    return list.filter((element) =>
        fieldsToFilterOn.some((propName) =>
            (element[propName] ?? '')
                .toString()
                .toLowerCase()
                .includes(valueToSearch.toLowerCase())
        )
    );
}
