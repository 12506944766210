import cloneDeep from 'lodash/cloneDeep';
import without from 'lodash/without';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { FloweringDatesNames, FLOWERING_DATES } from '../../../../../res/Constants';
import { canEditGrowing } from '../../../../../service/PermissionService';
import { updateTechFormCharacters } from '../../../../../service/TechFormDetailService';
import { toastWarning } from '../../../../../ui/ToastHelper';
import { A, B, HTTP_CODE } from './../../../../../res/Constants';
import GreenRedTimeLine from './../components/GreenRedTimeLine';

export default class FloweringDates extends PureComponent {
    static propTypes = {
        cacType: PropTypes.number.isRequired,
        techFormData: PropTypes.object.isRequired
    };

    state = {
        lines: extractLines(this.props.techFormData),
        pendingFieldIds: []
    };

    onDateEdited = (line, id, date) => {
        const selectedLine = getSelectedLine(line);
        this.setState((state) => {
            const newLines = cloneDeep(state.lines);
            newLines[selectedLine].dates = newLines[selectedLine].dates.map((obj) =>
                obj.id !== id
                    ? obj
                    : {
                          ...obj,
                          techFormPk: this.props.techFormData.techFormPk,
                          date,
                          line
                      }
            );
            return {
                lines: newLines,
                pendingFieldIds: [...state.pendingFieldIds, id]
            };
        });
    };

    onSave = (line) => {
        const selectedLine = getSelectedLine(line);
        if (this.state.lines[selectedLine].dates.every((d) => d.date == null)) {
            return;
        } else if (this.state.lines[selectedLine].dates.some((d) => isDateInvalid(d.date))) {
            toastWarning('Wrong date, please check and update it');
        } else {
            const filteredFields = this.state.lines[selectedLine].dates.filter(
                (obj) => this.state.pendingFieldIds.includes(obj.id) && obj.line === line
            );
            if (filteredFields.length > 0) {
                updateTechFormCharacters(filteredFields, (result) => {
                    if (result.status === HTTP_CODE.OK) {
                        const idsSent = filteredFields.map((obj) => obj.id);
                        // remove the sent ids from the pending list
                        this.setState((state) => {
                            return {
                                pendingFieldIds: without(state.editedFields, idsSent)
                            };
                        });
                    }
                });
            }
        }
    };

    render() {
        return (
            <GreenRedTimeLine
                cacType={this.props.cacType}
                title={FLOWERING_DATES}
                lines={this.state.lines}
                lineCount={this.props.techFormData.line}
                canBeEditable={canEditGrowing()}
                onDateEdited={this.onDateEdited}
                onSave={this.onSave}
            />
        );
    }
}

const floweringKeys = Object.keys(FloweringDatesNames);

const extractLines = (techFormData) => {
    return {
        lineA: {
            title: FLOWERING_DATES,
            dates: calculateDates(techFormData, A)
        },
        lineB: {
            title: FLOWERING_DATES,
            dates: calculateDates(techFormData, B)
        }
    };
};

const calculateDates = (techFormData, line) => {
    return floweringKeys.map((keyName) => {
        const techField = `${keyName}${line}`;
        return {
            id: keyName,
            name: FloweringDatesNames[keyName],
            date: techFormData[techField]
        };
    });
};

const getSelectedLine = (line) => (line === A ? 'lineA' : 'lineB');

function isDateInvalid(date) {
    return isNaN(date) || date < 0;
}
