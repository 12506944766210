import * as _ from 'lodash';
import React, { PureComponent } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { AppContext } from '../../../App';
import Button from '../../../components/Button';
import PopoverMenu from '../../../components/PopoverMenu';
import { CAC_TYPE } from '../../../res/Constants';
import buttonTheme from '../../../res/styles/theme_button.module.scss';

export default class DownloadButton extends PureComponent {
    state = {
        photoModalOpen: false,
        popoverOpen: false
    };

    togglePopover = () => {
        this.setState((prevState) => ({
            popoverOpen: !prevState.popoverOpen
        }));
    };

    togglePhotoDialog = () => {
        this.setState((prevState) => ({
            photoModalOpen: !prevState.photoModalOpen
        }));
    };

    render() {
        const buttonText = isMobileOnly ? '...' : 'Export/Download';

        const popoverButtons = [
            { label: 'Export to XLS (1 view)', onClick: () => this.props.onExcelDownload(1) },
            { label: 'Export to XLS (2 view)', onClick: () => this.props.onExcelDownload(2) },
            { label: 'Pictures Download', onClick: () => this.props.onPhotosDownload() }
        ];

        return (
            <AppContext.Consumer>
                {(context) => (
                    <>
                        <Button
                            text={buttonText}
                            id='techformlist-btn-download'
                            style={{ marginLeft: 'auto' }}
                            className={buttonTheme.textRed}
                            type='button'
                            enabled={this.props.downloadEnabled}
                        />
                        <PopoverMenu
                            isOpen={this.state.popoverOpen}
                            target='techformlist-btn-download'
                            body={
                                context.cacType === CAC_TYPE.CROP
                                    ? popoverButtons
                                    : _.without(popoverButtons, popoverButtons[1])
                            }
                            onToggle={this.togglePopover}
                        />
                    </>
                )}
            </AppContext.Consumer>
        );
    }
}
