import { Link } from 'react-router-dom';
import { Col, Input } from 'reactstrap';
import styled from 'styled-components';
import { ReactComponent as EditIconSvg } from '../res/assets/cac_edit.svg';
import colors from '../res/styles/Colors';
import { getDateOrEmpty, INPUT_DATE_PATTERN } from '../utils/DateFormatter';

export const FlexDiv = styled.div`
    display: flex;
    flex-direction: ${({ direction }) => direction || 'row'};
    ${({ justifyContent }) => justifyContent && `justify-content: ${justifyContent}`};
    ${({ alignItems }) => alignItems && `align-items: ${alignItems}`};
    ${({ width }) => width && `width: ${width}`};
    ${({ height }) => height && `height: ${height}`};
`;

export const HorizontalDiv = styled.div`
    display: flex;
    flex-direction: row;
`;

export const VerticalDiv = styled.div`
    display: flex;
    flex-direction: column;
`;

export const EmptyDiv = styled.div`
    width: ${({ width }) => width || 'auto'};
    height: ${({ height }) => height || 'auto'};
`;

export const ColNoPadding = styled(Col)`
    padding: 0;
`;

export const FieldTitle = styled.p.attrs({ className: 'info-text-title' })`
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 0.1rem;
`;

export const CellItemContainer = styled.div.attrs((props) => ({
    className:
        props.hasShadow !== false ? ['cell-shadow', props.className].join(' ') : props.className
}))`
    border-radius: 3px;
    p {
        margin-bottom: 0.1rem;
    }
`;

export const CellItemInner = styled.div.attrs({ className: 'inner-container' })`
    display: flex;
    flex-direction: ${({ direction }) => (direction === 'horizontal' ? 'row' : 'column')};
    padding: 15px;
    background-color: ${({ backgroundColor }) => backgroundColor || 'white'};

    & > *:not(:last-child) {
        margin-bottom: 10px;
    }
`;

export const CellItemExpandend = styled(CellItemInner).attrs({
    className: 'inner-container-expandable'
})`
    background-color: ${({ backgroundColor }) => backgroundColor || colors.lighter_green};
`;

export const IconImg = styled.img`
    width: 20px;
    height: 20px;
`;

export const ButtonNoStyle = styled.button`
    border: none;
    margin: 0;
    padding: 0;
    font-size: 1rem;
    line-height: 1;
    background: transparent;
    -webkit-appearance: none;
`;

export const InputDate = styled(Input).attrs(({ id, dateAsString, dateAsLong }) => ({
    className: 'input-field',
    type: 'date',
    id,
    value: dateAsString || getDateOrEmpty(dateAsLong, INPUT_DATE_PATTERN)
}))`
    && {
        border-radius: 5px;
        text-indent: ${({ textIndent }) => textIndent || 0};
    }
`;

export const EditIcon = styled(EditIconSvg)`
    g {
        fill: ${({ color }) => color || 'black'};
    }
`;

export const ImgButton = styled.img`
    width: 27px !important;
    height: 27px !important;
    padding: 5px;
    cursor: pointer;
`;

export const getEditIcon = (color) =>
    color === 'red' || color === '#EF403E'
        ? require('../res/images/cac_edit_red.png')
        : require('../res/images/cac_edit_green.png');

export const ClickableText = styled.p`
    ${({ color }) => color && `color: ${color}`};
    ${({ bold }) => bold && `font-weight: bold`};
    cursor: pointer;
`;

export const Textarea = styled.textarea`
    border-radius: 3px;
    font-size: 13px;
`;

export const CustomLink = styled(Link)`
    ${({ color }) =>
        color &&
        `
           text-decoration-color: ${color};
           color: ${color}`};
    ${({ bold }) => bold && `font-weight: bold`};

    &:hover {
        ${({ color }) =>
            color &&
            `
           text-decoration-color: ${color};
           color: ${color}`};
    }
`;
