import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { Row } from 'reactstrap';
import styled from 'styled-components';
import ExpandIcon from '../../../../components/ExpandIcon';
import ListItem from '../../../../components/ListItem';
import { ExtraReportCharacterNames } from '../../../../res/Constants';
import { ColNoPadding } from './../../../../components/Common';
import InfoText from './../../../../components/InfoText';
import colors from './../../../../res/styles/Colors';
import dimension from './../../../../res/styles/Responsive';
import CrAlertItemDetail from './CrAlertItemDetail';

export default class CrAlertItem extends PureComponent {
    static propTypes = {
        className: PropTypes.string,
        data: PropTypes.object.isRequired,
        isEditable: PropTypes.bool,
        isSelectable: PropTypes.bool,
        isSelected: PropTypes.bool,
        isRead: PropTypes.bool,
        onItemCanceled: PropTypes.func,
        onItemSelected: PropTypes.func,
        onItemUpdated: PropTypes.func
    };

    static defaultProps = {
        data: {},
        isEditable: false,
        isSelected: false,
        isRead: false,
        onItemCanceled: () => {},
        onItemSelected: () => {},
        onItemUpdated: () => {}
    };

    state = {
        isExpanded: isMobileOnly ? this.props.isEditable : true,
        isEditable: this.props.isEditable,
        print: false,
        data: this.props.data
    };

    toggleItemDetail = () => {
        this.setState({ isExpanded: !this.state.isExpanded });
    };

    toggleEdit = () => {
        this.setState(
            (prevState, props) => ({
                data: prevState.isEditable === true ? { ...props.data } : { ...prevState.data },
                isEditable: !prevState.isEditable
            }),
            () => {
                // after the state update
                if (!this.state.isEditable) {
                    this.props.onItemCanceled();
                }
            }
        );
    };

    onFieldEdited = (fieldId, value) => {
        this.setState((state) => {
            const newData = cloneDeep(state.data);
            const textValue = value.toString();
            if (newData.extraReportCharacters[fieldId] == null) {
                // create the relative field if doesn't exist
                newData.extraReportCharacters[fieldId] = {
                    name: fieldId,
                    value: textValue
                };
            } else {
                const characterValue =
                    (fieldId === ExtraReportCharacterNames.NET_HA ||
                        fieldId === ExtraReportCharacterNames.DESTROYED_HA) &&
                    textValue.length === 0
                        ? '0'
                        : textValue;
                newData.extraReportCharacters[fieldId].value = characterValue;
            }
            return {
                data: newData
            };
        });
    };

    onEditingCompleted = () => {
        this.setState({ isEditable: false });
        this.props.onItemUpdated(this.state.data);
    };

    render() {
        const expandableChildren = (
            <CrAlertItemDetail
                isEditable={this.state.isEditable}
                toggleEdit={this.toggleEdit}
                onFieldEdited={this.onFieldEdited}
                onEditingCompleted={this.onEditingCompleted}
                data={this.state.data}
            />
        );

        return (
            <StyledListItem
                expandableChildren={expandableChildren}
                className={this.props.className}
                isExpanded={this.state.isExpanded || !isMobileOnly}
                isSelectable={this.props.isSelectable}
                isSelected={this.props.isSelected}
                isRead={this.props.isRead}
                onSelect={() => this.props.onItemSelected(this.props.data.pk)}>
                {(renderProps) => (
                    <>
                        <Row>
                            <ColNoPadding>
                                <InfoText
                                    title='Techform Code'
                                    content={this.state.data.techFormCode}
                                />
                            </ColNoPadding>
                            <ColNoPadding>
                                <InfoText title='Lot. N°' content={this.state.data.lotNumber} />
                            </ColNoPadding>
                            <ColNoPadding>
                                <InfoText title='User' content={this.state.data.username} />
                            </ColNoPadding>
                            <ColNoPadding className='d-none d-md-block'>
                                <CrAlertDate date={this.state.data.date} />
                            </ColNoPadding>
                            <ColNoPadding xs='1' className='d-md-none'>
                                <ExpandIcon onClick={renderProps.toggleExpand} />
                            </ColNoPadding>
                        </Row>
                        <Row>
                            <ColNoPadding className='d-md-none'>
                                <CrAlertDate date={this.state.data.date} />
                            </ColNoPadding>
                            {this.props.isGeneric && (
                                <>
                                    <ColNoPadding>
                                        <InfoText
                                            title='Customer'
                                            content={this.state.data.clientCode}
                                        />
                                    </ColNoPadding>
                                    <ColNoPadding>
                                        <InfoText
                                            title='Species'
                                            content={`${this.state.data.speciesCode}${this.state.data.varietyCode}`}
                                        />
                                    </ColNoPadding>
                                    <ColNoPadding className='d-none d-md-block'>
                                        <InfoText
                                            title='Variety'
                                            content={this.state.data.varietyName}
                                        />
                                    </ColNoPadding>
                                    <ColNoPadding className='d-none d-md-block'>
                                        <InfoText
                                            title='Grower'
                                            content={this.state.data.growerName}
                                        />
                                    </ColNoPadding>
                                    <ColNoPadding xs='1' className='d-md-none' />
                                </>
                            )}
                        </Row>
                        {this.props.isGeneric && isMobileOnly && (
                            <Row>
                                <ColNoPadding>
                                    <InfoText
                                        title='Variety'
                                        content={this.state.data.varietyName}
                                    />
                                </ColNoPadding>
                                <ColNoPadding>
                                    <InfoText title='Grower' content={this.state.data.growerName} />
                                </ColNoPadding>
                            </Row>
                        )}
                    </>
                )}
            </StyledListItem>
        );
    }
}

const CrAlertDate = ({ date, className }) => {
    const formattedDate = moment(date).format('DD/MMMM/YYYY HH:mm');
    return <InfoText className={className} title='Date' content={formattedDate} />;
};

const StyledListItem = styled(ListItem)`
    & > div:first-child,
    & > div:first-child *:not(.selector) {
        background-color: ${({ isRead }) => (isRead !== true ? colors.light_red : 'white')};
    }

    @media ${dimension.md} {
        & > div div:not(.selector) {
            background-color: ${({ isRead }) => (isRead !== true ? colors.light_red : 'white')};
        }
    }
`;
