import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Col, Row } from 'reactstrap';
import Checkbox from '../../../components/Checkbox';
import { Input } from './Common';

class CustomerFields extends PureComponent {
    static propTypes = {
        customer: PropTypes.shape({
            code: PropTypes.string,
            name: PropTypes.string,
            reportDates: PropTypes.string,
            firstReportNotification: PropTypes.bool
        }),
        onReportDatesChange: PropTypes.func,
        onFirstReportNotificationChange: PropTypes.func
    };

    onReportDatesChange = (event) => {
        const reportDates = event.target.value;
        this.props.onReportDatesChange({
            ...this.props.customer,
            reportDates: reportDates
        });
    };

    onFirstReportNotificationChange = () => {
        this.props.onFirstReportNotificationChange({
            ...this.props.customer,
            firstReportNotification: !this.props.customer.firstReportNotification
        });
    };

    render() {
        const { code, name } = this.props.customer;
        return (
            <>
                <Row>
                    <p style={{ marginBottom: '0', fontSize: '12px' }}>
                        <strong>Code:</strong> {code}
                        <br />
                        <strong>Name:</strong> {name}
                    </p>
                </Row>
                <Row style={{ marginBottom: '16px' }}>
                    <Col style={{ paddingLeft: '0', paddingRight: '0' }}>
                        <Input
                            type='text'
                            id={'client-' + code}
                            value={this.props.customer.reportDates || ''}
                            onChange={this.onReportDatesChange}
                            onBlur={this.onReportDatesChange}
                        />
                    </Col>
                    <Col xs='1' sm='1' md='1' lg='1' style={{ paddingTop: '7px' }}>
                        <Checkbox
                            isHorizontal={true}
                            isSelected={this.props.customer.firstReportNotification}
                            onChange={this.onFirstReportNotificationChange}
                            style={{ width: '100%', margin: '0' }}
                        />
                    </Col>
                </Row>
            </>
        );
    }
}

export default CustomerFields;
