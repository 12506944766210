import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { VerticalDiv } from '../../../components/Common';
import dimension from '../../../res/styles/Responsive';

class GridButton extends PureComponent {
    static propTypes = {
        item: PropTypes.shape({
            image: PropTypes.string,
            labelLines: PropTypes.arrayOf(PropTypes.string),
            route: PropTypes.string
        }),
        onItemClick: PropTypes.func
    };

    onItemClick = () => {
        if (this.props.item.image != null) {
            this.props.onItemClick && this.props.onItemClick(this.props.item);
        }
    };

    render() {
        const image = this.props.item.image;
        const labelLines = [...this.props.item.labelLines];
        if (labelLines.length > 1) {
            for (let i = labelLines.length - 1; i >= 0; i -= 2) {
                labelLines.splice(i, 0, <br key={i} />);
            }
        }

        return (
            <GridButtonContainer onClick={this.onItemClick} dummy={image == null}>
                {image && (
                    <img
                        alt=''
                        className='responsive-margin'
                        src={require('../../../res/images/' + image + '.png')}
                    />
                )}
                <p>{labelLines}</p>
            </GridButtonContainer>
        );
    }
}

const GridButtonContainer = styled(VerticalDiv)`
    position: relative;
    width: 80px;
    height: 104px;
    text-align: center;

    ${({ dummy }) => !dummy && `cursor: pointer;`}

    & > img {
        width: 50%;
        margin-left: auto;
        margin-right: auto;
    }

    & > p {
        width: 100%;
        position: absolute;
        bottom: 0;
        font-size: 12px;
        text-align: center;
    }

    @media ${dimension.md} {
        width: 90px;
        height: 117px;
        font-size: 14px;
    }

    @media ${dimension.lg} {
        width: 100px;
        height: 130px;

        & > p {
            font-size: 15px;
        }
    }
`;

export default GridButton;
