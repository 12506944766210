import PropTypes from 'prop-types';
import React from 'react';
import { Tooltip } from 'reactstrap';
import styled from 'styled-components';
import { FlexDiv, VerticalDiv } from '../../../../../components/Common';
import Dropdown from '../../../../../components/Dropdown';
import {
    BOLTING,
    DEVELOPMENT,
    DISEASES_DESCRIPTION,
    DISEASES_GRAVITY,
    REMARKS,
    ReportCharactersNames,
    ROGUING_REMARKS,
    STANDING,
    UNIFORMITY,
    UNIFORMITY_VALUES
} from '../../../../../res/Constants';
import {
    getReportCharacterNumericErrorText,
    isReportCharacterNumericValid
} from '../../../../../service/CommonFunctions';
import dimension from './../../../../../res/styles/Responsive';

const DEVELOPMENT_VALUES = [1, 2, 3, 4];
const DISEASES_GRAVITY_VALUES = [0, 1, 2, 3];
const DISEASES_DESCRIPTION_VALUES = [
    'Alternaria',
    'Downy Mildew',
    'Botrytis',
    'Rust',
    'Albugo C.',
    'Phytium',
    'Root-Fly'
];

EditableLine.propTypes = {
    lineName: PropTypes.string,
    initialData: PropTypes.object,
    onValuesEdited: PropTypes.func
};

export default function EditableLine({ lineName, initialData, onValuesEdited }) {
    const {
        Stand,
        Develop,
        Uniformity,
        Bolt,
        DiseasesDescription,
        DiseasesGravity,
        RoguingRemarks,
        Remarks
    } = initialData;

    const targetBolt = `${ReportCharactersNames.bolt}${lineName}`;
    const targetStand = `${ReportCharactersNames.stand}${lineName}`;

    return (
        <VerticalDiv>
            <TitleContainer>
                <h5>Line {lineName}</h5>
            </TitleContainer>
            <FlexDiv orientation='row' justifyContent='space-between'>
                <InputTitle>{STANDING}</InputTitle>
                <Input
                    type='number'
                    defaultValue={Stand || '0'}
                    id={targetStand}
                    onChange={(e) => onValuesEdited(ReportCharactersNames.stand, e.target.value)}
                />
                <Tooltip
                    placement='bottom'
                    target={targetStand}
                    isOpen={
                        !isReportCharacterNumericValid(initialData[ReportCharactersNames.stand])
                    }>
                    {getReportCharacterNumericErrorText(initialData[ReportCharactersNames.stand])}
                </Tooltip>
            </FlexDiv>
            <FlexDiv orientation='row' justifyContent='space-between'>
                <InputTitle>{DEVELOPMENT}</InputTitle>
                <StyledDropdown
                    emptyOption={false}
                    options={DEVELOPMENT_VALUES}
                    defaultValue={Develop}
                    onChange={(v) => onValuesEdited(ReportCharactersNames.develop, v)}
                />
            </FlexDiv>
            <FlexDiv orientation='row' justifyContent='space-between'>
                <InputTitle>{UNIFORMITY}</InputTitle>
                <StyledDropdown
                    emptyOption={false}
                    options={Object.keys(UNIFORMITY_VALUES)}
                    defaultValue={Uniformity}
                    onChange={(v) => onValuesEdited(ReportCharactersNames.uniform, v)}
                />
            </FlexDiv>
            <FlexDiv orientation='row' justifyContent='space-between'>
                <InputTitle>{BOLTING}</InputTitle>
                <Input
                    type='text'
                    defaultValue={Bolt || '0'}
                    id={targetBolt}
                    onChange={(e) => onValuesEdited(ReportCharactersNames.bolt, e.target.value)}
                />
                <Tooltip
                    placement='bottom'
                    target={targetBolt}
                    isOpen={
                        !isReportCharacterNumericValid(initialData[ReportCharactersNames.bolt])
                    }>
                    {getReportCharacterNumericErrorText(initialData[ReportCharactersNames.bolt])}
                </Tooltip>
            </FlexDiv>
            <FlexDiv orientation='row' justifyContent='space-between'>
                <InputTitle>{DISEASES_GRAVITY}</InputTitle>
                <StyledDropdown
                    emptyOption={false}
                    options={DISEASES_GRAVITY_VALUES}
                    defaultValue={DiseasesGravity}
                    onChange={(v) => onValuesEdited(ReportCharactersNames.diseasesGravity, v)}
                />
            </FlexDiv>
            <FlexDiv orientation='row' justifyContent='space-between'>
                <InputTitle>{DISEASES_DESCRIPTION}</InputTitle>
                <StyledInputSelect
                    defaultValue={DiseasesDescription}
                    options={DISEASES_DESCRIPTION_VALUES}
                    onChange={(value) =>
                        onValuesEdited(ReportCharactersNames.diseasesDescription, value)
                    }
                />
            </FlexDiv>
            <Label htmlFor={ReportCharactersNames.roguing}>
                {ROGUING_REMARKS}
                <textarea
                    name={ReportCharactersNames.roguing}
                    id={ReportCharactersNames.roguing}
                    cols='10'
                    rows='2'
                    defaultValue={RoguingRemarks}
                    onChange={(e) => onValuesEdited(ReportCharactersNames.roguing, e.target.value)}
                />
            </Label>
            <Label htmlFor={ReportCharactersNames.remarks}>
                {REMARKS}
                <textarea
                    name={ReportCharactersNames.remarks}
                    id={ReportCharactersNames.remarks}
                    cols='10'
                    rows='2'
                    defaultValue={Remarks}
                    onChange={(e) => onValuesEdited(ReportCharactersNames.remarks, e.target.value)}
                />
            </Label>
        </VerticalDiv>
    );
}

const InputSelect = (props) => {
    return (
        <div className={props.className}>
            <span className='input-container'>
                <input
                    autoFocus
                    list='input-select'
                    name='input-select'
                    defaultValue={props.defaultValue?.trimEnd() ?? ''}
                    onChange={(e) => props.onChange(e.target.value)}></input>
            </span>
            <datalist id='input-select'>
                {props.options.map((v, index) => (
                    <option value={v} key={index}></option>
                ))}
            </datalist>
        </div>
    );
};

const StyledInputSelect = styled(InputSelect)`
    input {
        height: 30px;
        width: 200px;
        margin-right: -10px;
        border-radius: 5px;
        box-shadow: 0 1px 2px grey;
        margin-bottom: 10px;
        text-indent: 10px;

        &:focus {
            outline: none;
        }
    }

    input::-webkit-calendar-picker-indicator {
        display: none;
    }

    .input-container:after {
        border: solid transparent;
        content: ' ';
        height: 0;
        width: 0;
        pointer-events: none;
        border-color: rgba(0, 0, 0, 0);
        border-top-color: #000000;
        border-width: 5px;
        margin-top: -2px;
        z-index: 100;
        position: relative;
        top: 10px;
        right: 5px;
    }
`;

const TitleContainer = styled.div`
    text-align: center;
`;

const Label = styled.label`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

const StyledDropdown = styled(Dropdown)`
    width: 200px;
    text-indent: 10px;
`;

const Input = styled.input`
    height: 30px;
    width: 200px;
    border-radius: 5px;
    box-shadow: 0 1px 2px grey;
    margin-bottom: 10px;
    text-indent: 10px;

    :focus {
        outline: none;
    }
`;

const InputTitle = styled.p`
    width: min-content;

    @media ${dimension.md} {
        width: auto;
    }
`;
