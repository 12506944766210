import React from 'react';
import { Col } from 'reactstrap';
import styled from 'styled-components';
import { HorizontalDiv, VerticalDiv } from '../../components/Common';
import NavigationBar from '../../components/NavigationBar/NavigationBar';
import TitleBar from '../../components/TitleBar';
import buttonTheme from '../../res/styles/theme_button.module.scss';
import { sendPushNotification } from '../../service/ClientService';
import { toastError, toastInfo, toastSuccess, toastWarning } from '../../ui/ToastHelper';
import Checkbox from './../../components/Checkbox';
import { ColNoPadding } from './../../components/Common';
import EditableText from './../../components/EditableText';
import colors from './../../res/styles/Colors';
import dimension from './../../res/styles/Responsive';
import { getClients } from './../../service/ClientService';
import { ControlPanelPage, PanelFieldTitle } from './components/Common';

const MAX_CHAR_ALLOWED = 107;

SendPushMessages.whyDidYouRender = true;

export default function SendPushMessages() {
    const [charCounter, setCharCounter] = React.useState(0);
    const [message, setMessage] = React.useState();
    const [clients, setClients] = React.useState([]);
    const [clientCodesSelected, setClientCodesSelected] = React.useState(new Set());

    React.useEffect(() => {
        getClients((clients) => {
            setClients(clients);
        });
        return () => {
            setClientCodesSelected(new Set());
        };
    }, []);

    const onSelectAll = () => {
        if (clientCodesSelected.size === clients.length) {
            setClientCodesSelected(new Set());
        } else {
            setClientCodesSelected(new Set(clients.map((c) => c.code)));
        }
    };

    const onClientSelected = (clientCode) => {
        const newClientCodesSelected = new Set(clientCodesSelected);
        if (newClientCodesSelected.has(clientCode)) {
            newClientCodesSelected.delete(clientCode);
        } else {
            newClientCodesSelected.add(clientCode);
        }
        setClientCodesSelected(newClientCodesSelected);
    };

    const onMessageChange = React.useCallback((_, message) => {
        setMessage(message);
    }, []);

    const onUpdateCharCounter = React.useCallback((value) => {
        setCharCounter(value.length);
    }, []);

    const onSend = () => {
        if (charCounter > MAX_CHAR_ALLOWED) {
            toastWarning('Message too long.');
        } else if (message == null || message.length === 0) {
            toastWarning('Invalid message: fill in the message form.');
        } else if (clientCodesSelected.size === 0) {
            toastWarning('No users selected.');
        } else {
            toastInfo('Sending message…');
            sendPushNotification(
                message,
                Array.from(clientCodesSelected),
                (successfulNotificationCount) => {
                    toastSuccess(`Message sent to ${successfulNotificationCount} devices`);
                },
                () => {
                    toastError('Sending failed');
                }
            );
        }
    };

    const remainingChars = MAX_CHAR_ALLOWED - charCounter;

    return (
        <div>
            <NavigationBar />
            <TitleBar title='Send Push Messages' />
            <StyledControlPanelPage>
                <StyledCol xs={12} md={8} lg={6}>
                    <PanelFieldTitle>
                        Compose the message to send to techs with attempt at days: 3, 4, 7
                    </PanelFieldTitle>
                    <EditableText
                        rows={6}
                        initialValue={''}
                        onBlur={onMessageChange}
                        onChange={onUpdateCharCounter}
                    />
                    <CharCounter>{`${remainingChars} char available`}</CharCounter>
                    <ScrollableColumnContainer>
                        <StyledListTitleContainer>
                            <PanelFieldTitle>
                                Select clients related to the message:
                            </PanelFieldTitle>
                            <p className='clickable' onClick={onSelectAll}>
                                select all
                            </p>
                        </StyledListTitleContainer>
                        <ScrollableColumn>
                            <ScrollableDiv>
                                {clients.map((client) => (
                                    <StyledClientItem
                                        data={client}
                                        isSelected={clientCodesSelected.has(client.code)}
                                        onClientSelected={onClientSelected}
                                        key={client.pk}
                                    />
                                ))}
                            </ScrollableDiv>
                        </ScrollableColumn>
                    </ScrollableColumnContainer>
                </StyledCol>
                <Footer>
                    <Col xs={12} md={8} lg={6} style={{ display: 'flex' }}>
                        <button
                            className={buttonTheme.green}
                            style={{ marginLeft: 'auto' }}
                            onClick={onSend}>
                            SEND
                        </button>
                    </Col>
                </Footer>
            </StyledControlPanelPage>
        </div>
    );
}

function ClientItem(props) {
    return (
        <HorizontalDiv className={props.className}>
            <Checkbox
                style={{ transform: 'translateY(30%)' }}
                isEditable
                isSelected={props.isSelected}
                onChange={() => props.onClientSelected(props.data.code)}
            />
            <VerticalDiv>
                <p>
                    <b>Code: </b>
                    {props.data.code}
                </p>
                <p>
                    <b>Name: </b>
                    {props.data.name}
                </p>
            </VerticalDiv>
        </HorizontalDiv>
    );
}

const Footer = styled.div`
    width: 100%;
    display: flex;
    margin-top: auto;
    justify-content: center;

    button {
        width: 150px;
        margin-bottom: 10px;
    }
`;

const StyledCol = styled(Col)`
    display: flex;
    flex-direction: column;
    position: fixed;
    height: 100%;
    overflow: hidden;
`;

const CharCounter = styled.p`
    font-size: 12px;
    margin-left: auto;
    margin-top: -30px;
    margin-right: 10px;
    color: ${colors.darker_gray};
`;

const ScrollableColumnContainer = styled.div`
    position: absolute;
    top: 140px;
    bottom: 40px;
    width: 100%;
`;

const ScrollableColumn = styled(ColNoPadding)`
    height: 100%;
    display: flex;
    padding-bottom: 50px;
`;

const ScrollableDiv = styled.div`
    width: 100%;
    height: 70%;
    margin-right: 30px;
    overflow-y: auto;
`;

const StyledClientItem = styled(ClientItem)`
    padding-bottom: 8px;
    label {
        /* checkbox */
        transform: translateY(30%);
    }

    p {
        font-size: 12px;
        /* space from checkbox */
        margin-left: 30px;

        @media ${dimension.md} {
            font-size: 13px;
        }
    }
`;

const StyledListTitleContainer = styled(HorizontalDiv)`
    justify-content: space-between;
    margin-right: 30px;

    .clickable {
        color: ${colors.red};
        cursor: pointer;
    }
`;

const StyledControlPanelPage = styled(ControlPanelPage)`
    justify-content: center;
    p {
        margin-bottom: 0;
    }
    textarea {
        resize: none;
    }

    && {
        padding-top: 10px;
    }
`;
