/* eslint-disable global-require */
import axios from 'axios';
import { CAC_TYPE } from '../res/Constants';
import { isTrue } from '../utils/Utils';
import { canSeeOnlyLastReport } from './PermissionService';

const STORAGE_FILTERS_CROP = 'filtersCrop';
const STORAGE_FILTERS_NURSERY = 'filtersNursery';

const GET_FILTERS = '/filters';

function loadFiltersCrop() {
    return localStorage.getItem(STORAGE_FILTERS_CROP);
}

function saveFiltersCrop(object) {
    localStorage.setItem(STORAGE_FILTERS_CROP, JSON.stringify(object));
}

function loadFiltersNursery() {
    return localStorage.getItem(STORAGE_FILTERS_NURSERY);
}

function saveFiltersNursery(object) {
    localStorage.setItem(STORAGE_FILTERS_NURSERY, JSON.stringify(object));
}

export function getFilters(cacType, callback) {
    let rawFiltersNursery = JSON.parse(loadFiltersNursery());
    let rawFiltersCrop = JSON.parse(loadFiltersCrop());

    if (cacType === CAC_TYPE.CROP && rawFiltersCrop != null) {
        return callback(rawFiltersCrop);
    }
    if (cacType === CAC_TYPE.NURSERY && rawFiltersNursery != null) {
        return callback(rawFiltersNursery);
    }

    fetchFilters(CAC_TYPE.CROP).then((result) => {
        rawFiltersCrop = result.data || null;
        saveFiltersCrop(rawFiltersCrop);
        if (cacType === CAC_TYPE.CROP) {
            callback(rawFiltersCrop);
        }
    });

    fetchFilters(CAC_TYPE.NURSERY).then((result) => {
        rawFiltersNursery = result.data || null;
        saveFiltersNursery(rawFiltersNursery);
        if (cacType === CAC_TYPE.NURSERY) {
            callback(rawFiltersNursery);
        }
    });
}

function fetchFilters(cacType) {
    return axios({
        method: 'get',
        url: GET_FILTERS,
        params: { type: cacType }
    });
}

export function extractDefaultFilters(filters) {
    const defaultFilters = {};
    if (filters != null) {
        Object.entries(filters).forEach(([key, value]) => {
            defaultFilters[key] = getDefaultValueOfFilterIfPresent(value);
        });
        if (defaultFilters.onlyLastReport == null) {
            defaultFilters.onlyLastReport = canSeeOnlyLastReport();
        }
    }
    return defaultFilters;
}

export function getDefaultValueOfFilterIfPresent(object) {
    if (object == null) {
        return null;
    }
    if (object.defaultValueIndex == null) {
        return null;
    }
    if (object.type === 'BOOLEAN') {
        return isTrue(object.values[object.defaultValueIndex].name);
    } else {
        return object.values[object.defaultValueIndex].name;
    }
}
