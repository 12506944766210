import axios from 'axios';
import FileSaver from 'file-saver';
import * as _ from 'lodash';
import moment from 'moment';
import { HTTP_CODE } from '../res/Constants';
import { EXCEL_EXTENSION, MIME_TYPE_EXCEL } from './ServiceConstants';

const MAX_MONTHS_DIFFERENCE = 3;

const VISITS_API = '/visits';
const SAVE_VISIT_LIST_API = `${VISITS_API}/list`;

let latestDates;
let latestVisits;

export function getName(user) {
    return user.firstName != null && user.lastName != null
        ? `${user.lastName} ${user.firstName}`
        : user.username;
}

export function getVisitsDates() {
    // by default dates have a difference of 3 weeks
    if (latestDates == null) {
        const baseDate = moment().startOf('isoWeek');
        // we only care about the date and not the time
        latestDates = {
            from: baseDate.valueOf(),
            to: baseDate
                .add(3, 'weeks')
                .subtract(1, 'minute')
                .valueOf()
        };
    }
    return latestDates;
}

export function sanitizeDatesDifference(dates, latestUpdate) {
    const { from, to } = dates;
    const daysDifference = moment(from).diff(moment(to), 'months');

    if (from > to) {
        return latestUpdate === 'from' ? { ...dates, from: to } : { ...dates, to: from };
    }

    if (daysDifference <= MAX_MONTHS_DIFFERENCE && daysDifference >= -MAX_MONTHS_DIFFERENCE) {
        return dates;
    } else {
        const sanitizedDates = { ...dates };
        if (latestUpdate === 'from') {
            sanitizedDates.to = moment(dates.from)
                .add(MAX_MONTHS_DIFFERENCE, 'months')
                .valueOf();
        } else {
            sanitizedDates.from = moment(dates.to)
                .subtract(MAX_MONTHS_DIFFERENCE, 'months')
                .valueOf();
        }
        return sanitizedDates;
    }
}

/**
 * @param {number} fromDate
 * @param {number} toDate
 */
export function loadVisitList(fromDate, toDate, onSuccessCallback, onFailureCallback) {
    if (latestVisits != null && _.isEqual(latestDates, { fromDate, toDate })) {
        onSuccessCallback(latestVisits);
        return;
    }
    axios({ method: 'get', url: VISITS_API, params: { from: fromDate, to: toDate } })
        .then((result) => {
            if (result.status === HTTP_CODE.OK) {
                latestVisits = result.data;
                onSuccessCallback && onSuccessCallback(result.data);
            } else {
                onFailureCallback && onFailureCallback();
            }
        })
        .catch(() => {
            onFailureCallback && onFailureCallback();
        });
}

export async function saveVisit(visit, onSuccessCallback, onFailureCallback) {
    axios({
        method: 'post',
        url: VISITS_API,
        data: visit
    })
        .then((result) => {
            if (result.status === HTTP_CODE.OK) {
                onSuccessCallback && onSuccessCallback(result.data);
            } else {
                onFailureCallback && onFailureCallback();
            }
        })
        .catch(() => {
            onFailureCallback && onFailureCallback();
        });
}

export async function saveVisitList(visits, onSuccessCallback, onFailureCallback) {
    axios({
        method: 'post',
        url: SAVE_VISIT_LIST_API,
        data: visits
    })
        .then((result) => {
            if (result.status === HTTP_CODE.OK) {
                onSuccessCallback && onSuccessCallback(result.data);
            } else {
                onFailureCallback && onFailureCallback();
            }
        })
        .catch(() => {
            onFailureCallback && onFailureCallback();
        });
}

export function deleteVisit(visitPk, onSuccessCallback, onFailureCallback) {
    axios({ method: 'delete', url: VISITS_API, params: { id: visitPk } })
        .then((result) => {
            if (result.status === HTTP_CODE.OK) {
                // invalidate cached visits
                latestVisits = null;
                onSuccessCallback && onSuccessCallback(result.data);
            } else {
                onFailureCallback && onFailureCallback();
            }
        })
        .catch(() => {
            onFailureCallback && onFailureCallback();
        });
}

/**
 * @param {number} fromDate
 * @param {number} toDate
 * @param {Function} onSuccessCallback
 * @param {Function} onFailureCallback
 */
export function exportVisits(fromDate, toDate, onSuccessCallback, onFailureCallback) {
    axios({
        method: 'get',
        url: VISITS_API,
        responseType: 'blob',
        params: {
            format: EXCEL_EXTENSION,
            from: fromDate,
            to: toDate
        }
    })
        .then((response) => {
            const blob = new Blob([response.data], {
                type: MIME_TYPE_EXCEL
            });
            const fileName = `CustomerVisits.${EXCEL_EXTENSION}`;
            FileSaver.saveAs(blob, fileName);
            onSuccessCallback && onSuccessCallback();
        })
        .catch((error) => {
            onFailureCallback && onFailureCallback(error);
        });
}
