import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { SORT_TYPE } from '../res/Constants';
import dimension from '../res/styles/Responsive';
import buttonTheme from '../res/styles/theme_button.module.scss';
import Button from './Button';
import SimpleButtonDropdown from './SimpleButtonDropdown';

SortSetter.propTypes = {
    selectedIndex: PropTypes.number,
    selectedSortType: PropTypes.string,
    fields: PropTypes.arrayOf(
        PropTypes.shape({
            key: PropTypes.string,
            value: PropTypes.string
        })
    ).isRequired,
    onSortChange: PropTypes.func.isRequired
};

SortSetter.defaultProps = {
    fields: [],
    selectedIndex: 0,
    selectedSortType: SORT_TYPE.ASCENDANT
};

function SortSetter(props) {
    const [selectedField, setSelectedField] = React.useState(props.fields[props.selectedIndex]);
    const [sortType, setSortType] = React.useState(props.selectedSortType);

    const onSortTypeChange = () => {
        const newSortType =
            sortType === SORT_TYPE.ASCENDANT ? SORT_TYPE.DESCENDANT : SORT_TYPE.ASCENDANT;
        setSortType(newSortType);
        props.onSortChange({ field: selectedField, sortType: newSortType });
    };

    const onSortFieldChange = (index) => {
        setSelectedField(props.fields[index]);
        props.onSortChange({ field: props.fields[index], sortType });
    };

    const reverseBtnText = sortType === SORT_TYPE.ASCENDANT ? 'A/Z' : 'Z/A';
    const sortByNames = props.fields.map((item) => item.value);
    return (
        <>
            <StyledButtonDropdown
                className={buttonTheme.green}
                text={`Sort by ${selectedField.value}`}
                values={sortByNames}
                onChangeValue={onSortFieldChange}
            />
            <StyledButtonReverseSort
                text={reverseBtnText}
                className={buttonTheme.green}
                onClick={onSortTypeChange}
            />
        </>
    );
}
export default SortSetter;

const StyledButtonDropdown = styled(SimpleButtonDropdown)`
    .dropdown {
        padding: 0 7px;
        @media ${dimension.md} {
            padding: 0 15px;
        }
    }
`;

const StyledButtonReverseSort = styled(Button)`
    border-radius: 50px;
    padding: 0 10px;
    margin-left: 5px;
`;
