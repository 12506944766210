import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styled from 'styled-components';
import colors from '../res/styles/Colors';
import { randomId } from '../utils/Utils';

const defaultCheckboxDimension = '17px';

class Checkbox extends Component {
    static propTypes = {
        className: PropTypes.string,
        customId: PropTypes.string,
        text: PropTypes.string,
        isSelected: PropTypes.bool,
        isHorizontal: PropTypes.bool,
        isEditable: PropTypes.bool,
        checkboxDimension: PropTypes.string,
        onChange: PropTypes.func
    };

    static defaultProps = {
        checkboxDimension: defaultCheckboxDimension,
        isSelected: false,
        isHorizontal: false,
        isEditable: true,
        onChange: () => {}
    };

    handleClick = () => {
        if (this.props.isEditable === false) {
            return;
        }
        this.props.onChange(this.props.customId, !this.props.isSelected);
    };

    render() {
        const hasText = this.props.text != null;
        const customId = this.props.customId || randomId();
        return (
            <StyledCheckboxLabel
                className={this.props.className}
                checkboxDimension={this.props.checkboxDimension}
                htmlFor={customId}
                hasText={hasText}
                clickable={this.props.isEditable}>
                {!this.props.isHorizontal && this.props.text}
                <input
                    className='form-check-input'
                    type='checkbox'
                    name='checkbox'
                    value={this.props.isSelected}
                    onChange={this.props.handleClick}
                    id={customId}
                />
                <span className='checkmark' onClick={this.handleClick}>
                    {this.props.isSelected && <div className='selected' />}
                </span>
                {this.props.isHorizontal && this.props.text}
            </StyledCheckboxLabel>
        );
    }
}

const StyledCheckboxLabel = styled.label`
    ${({ hasText }) =>
        hasText &&
        `
            margin: auto;
        `}
    ${({ clickable }) => clickable && `cursor: pointer;`}
    width: fit-content;
    display: flex;
    position: relative;
    margin-bottom: 12px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    input {
        display: none;
        position: absolute;
        opacity: 0;
        height: 0;
        width: 0;
    }

    .checkmark {
        ${({ hasText }) =>
            hasText &&
            `
            margin-top: 4px;
            margin-right: 10px;
        `}

        ${({ hasText }) =>
            !hasText &&
            `
            position: absolute;
        `}
        border-radius: 2px;
        border: 1.3px solid ${colors.green};
        top: 0;
        left: 0;

        height: ${({ checkboxDimension }) => checkboxDimension};
        width: ${({ checkboxDimension }) => checkboxDimension};
        background-color: white;

        &:after {
            content: '';
            position: absolute;
            display: none;
        }
    }

    input:checked ~ .checkmark:after {
        display: block;
    }

    .selected {
        margin: auto;
        left: 4px;
        top: 1px;
        width: 5px;
        height: 10px;
        border: solid ${colors.green};
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }
`;

export default Checkbox;
