import { version } from '../../package.json';

export const isProduction = (process.env.NODE_ENV || '').trim() === 'production';

const AppVersion = () => {
    if (process.env.REACT_APP_BUILD_NUMBER > 0) {
        return `${version} (${process.env.REACT_APP_BUILD_NUMBER})`;
    }
    return `${version}`;
};

export default AppVersion;
