import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { isMobileOnly, MobileView } from 'react-device-detect';
import styled from 'styled-components';
import { EmptyDiv, HorizontalDiv, VerticalDiv } from '../../../../../components/Common';
import ExpandIcon from '../../../../../components/ExpandIcon';
import InfoText from '../../../../../components/InfoText';
import ListItem from '../../../../../components/ListItem';
import {
    CAC_TYPE,
    EDIT,
    ESTIMATED_BULBS,
    ESTIMATED_YIELD,
    EST_BULBS,
    EST_YIELD
} from '../../../../../res/Constants';
import colors from '../../../../../res/styles/Colors';
import dimension from '../../../../../res/styles/Responsive';
import themeButton from '../../../../../res/styles/theme_button.module.scss';
import { extractLinesData } from '../../../../../service/ReportService';
import ReportLine from './ReportLine';

const pictureIcon = require('../../../../../res/images/cac_picture_icon_report_black.png');

ReportItem.propTypes = {
    className: PropTypes.string,
    linesCount: PropTypes.number,
    reportData: PropTypes.object,
    cacType: PropTypes.number,
    isEditable: PropTypes.bool.isRequired,
    onEdit: PropTypes.func
};

ReportItem.defaultProps = {
    onEdit: () => {}
};

function ReportItem({ className, cacType, linesCount, reportData, isEditable, onEdit }) {
    const reportDate = moment(reportData.date).format('DD/MMM/YYYY');
    const linesData = extractLinesData(linesCount, reportData);
    const photosCount = linesData?.lineA?.photosCount ?? 0;
    const viewSettings = [
        {
            color: colors.green,
            bgColor: colors.lighter_green
        },
        {
            color: colors.red,
            bgColor: colors.lighter_red
        }
    ];

    const expandableChildren = (
        <VerticalDiv>
            <HorizontalDiv>
                {Object.entries(linesData)
                    .filter((entries) => entries[0] !== 'lineU')
                    .map((entries) => entries[1])
                    .map((objModel, index) => (
                        <HorizontalDiv style={{ width: '100%' }} key={index}>
                            <MobileView renderWithFragment>
                                {index === 1 && (
                                    <VerticalDivisorContainer>
                                        <VerticalDivisor width='2px' height='100%' />{' '}
                                    </VerticalDivisorContainer>
                                )}
                            </MobileView>
                            <FullHeightReportLine
                                cacType={cacType}
                                reportLineData={objModel}
                                {...viewSettings[index]}
                            />
                        </HorizontalDiv>
                    ))}
            </HorizontalDiv>
            {(!isMobileOnly || isEditable) && (
                <FooterContainer>
                    {!isMobileOnly && (
                        <>
                            {reportData.techId && reportData.techId != '-' && (
                                <InfoText title='Reported by' content={reportData.techId} />
                            )}
                            <InfoText
                                title={
                                    cacType === CAC_TYPE.CROP ? ESTIMATED_YIELD : ESTIMATED_BULBS
                                }
                                content={reportData.expYield}
                            />
                        </>
                    )}
                    {cacType === CAC_TYPE.NURSERY && (
                        <InfoText title='Harvest Data' content={reportData.harvestData}></InfoText>
                    )}
                    {isEditable && <EditButton onClick={onEdit}>{EDIT.toUpperCase()}</EditButton>}
                </FooterContainer>
            )}
        </VerticalDiv>
    );

    return (
        <div className={className}>
            <StyledListItem expandableChildren={expandableChildren} isExpanded={!isMobileOnly}>
                {({ toggleExpand }) => (
                    <TitleContainer>
                        <HorizontalDiv>
                            <h6>{reportDate}</h6>
                            <StyledPhotosCountDiv>
                                <img src={pictureIcon} />
                                <p style={{ marginBottom: '0' }}>{photosCount}</p>
                            </StyledPhotosCountDiv>
                        </HorizontalDiv>
                        {isMobileOnly && (
                            <>
                                {reportData.techId && reportData.techId != '-' && (
                                    <InfoText title='Reported by' content={reportData.techId} />
                                )}
                                <InfoText
                                    title={cacType === CAC_TYPE.CROP ? EST_YIELD : EST_BULBS}
                                    content={reportData.expYield}
                                />
                                <ExpandIcon
                                    alt='show the report information'
                                    onClick={toggleExpand}
                                />
                            </>
                        )}
                    </TitleContainer>
                )}
            </StyledListItem>
        </div>
    );
}

const StyledPhotosCountDiv = styled(HorizontalDiv)`
    height: min-content;
    img {
        transform: translateY(10%);
        width: 15px;
        height: 15px;
        margin-left: 5px;

        @media ${dimension.md} {
            margin-left: 20px;
            margin-right: 10px;
        }
    }

    p {
        line-height: initial;
    }
`;

const FullHeightReportLine = styled(ReportLine)`
    width: 100%;
    height: auto;
`;

const StyledListItem = styled(ListItem)`
    .inner-container-expandable {
        padding: 0;

        @media ${dimension.md} {
            padding: 0 5% 15px;
        }
    }
`;

const TitleContainer = styled(HorizontalDiv)`
    justify-content: space-between;

    @media ${dimension.md} {
        justify-content: center;
    }
`;

const FooterContainer = styled(HorizontalDiv)`
    justify-content: space-between;
    padding: 15px;
    background-color: white;

    @media ${dimension.md} {
        background-color: inherit;
    }
`;

const EditButton = styled.button.attrs({
    className: themeButton.red
})`
    flex-basis: 150px;
    margin: auto;

    @media ${dimension.md} {
        margin: 0;
    }
`;

const VerticalDivisorContainer = styled(EmptyDiv)`
    width: auto;
    height: auto;
    display: flex;
    flex-shrink: 0;

    @media ${dimension.md} {
        padding: 0 15px;
    }
`;

const VerticalDivisor = styled(EmptyDiv)`
    margin: auto 0;
    background-color: lightgray;
`;

export default ReportItem;
