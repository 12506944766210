import { BigNumber } from 'bignumber.js';
import rangeRight from 'lodash/rangeRight';
import {
    FIRST_YEAR_OF_ACTIVITY,
    GENERAL,
    NUMERIC_VALUE_TOO_HIGH,
    NUMERIC_VALUE_TOO_LOW,
    REPORT_CHARACTERS_NUMERIC_BOUNDS,
    ROGUING_REMARKS
} from '../res/Constants';
import { getCurrentYear } from '../utils/DateFormatter';

/**
 * @param {string | number} netHa
 * @param {string | number} destroyedHa
 * @returns {number}
 */
export const calculateFinalHa = (netHa, destroyedHa) => {
    const sanitizedNetHa = netHa || 0;
    const sanitizedDestroyedHa = destroyedHa || 0;
    return new BigNumber(sanitizedNetHa).minus(new BigNumber(sanitizedDestroyedHa));
};

export const extractRoguingRemarks = (techFormData) => {
    const { roguingA, roguingB, reportsList } = techFormData;
    if (reportsList == null) {
        return null;
    }
    const filteredValues = reportsList.filter(
        (value) => value.roguingA != null || value.roguingB != null
    );
    const datesA = filteredValues.map((value) => ({
        date: value.date,
        name: value.roguingA
    }));
    const datesB = filteredValues.map((value) => ({
        date: value.date,
        name: value.roguingB
    }));
    const lineA = {
        title: ROGUING_REMARKS,
        dates: datesA,
        extraContent: {
            name: GENERAL,
            content: roguingA
        }
    };
    const lineB = {
        title: ROGUING_REMARKS,
        dates: datesB,
        extraContent: {
            name: GENERAL,
            content: roguingB
        }
    };
    return { lineA, lineB };
};

/**
 * @param {String | Number} value
 */
export const isReportCharacterNumericValid = (value) => {
    return value === '' || value == null || value === '-'
        ? true
        : parseInt(value) <= REPORT_CHARACTERS_NUMERIC_BOUNDS.max &&
              parseInt(value) >= REPORT_CHARACTERS_NUMERIC_BOUNDS.min;
};

export const getReportCharacterNumericErrorText = (number) => {
    if (number > REPORT_CHARACTERS_NUMERIC_BOUNDS.max) {
        return NUMERIC_VALUE_TOO_HIGH;
    }
    if (number < REPORT_CHARACTERS_NUMERIC_BOUNDS.min) {
        return NUMERIC_VALUE_TOO_LOW;
    }
    return 'Error';
};

/**
 * @returns {Number[]} range of years between the current year + 1
 * and the first year of activity of CAC in DESC order
 */
export const getYearsOfActivityFromCurrent = () =>
    rangeRight(FIRST_YEAR_OF_ACTIVITY, getCurrentYear() + 2);
