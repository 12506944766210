import chunk from 'lodash/chunk';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import styled from 'styled-components';
import NavigationBar from '../../components/NavigationBar/NavigationBar';
import TitleBar from '../../components/TitleBar';
import { WORK_IN_PROGRESS } from '../../res/Constants';
import { ControlPanelPage } from './components/Common';
import GridButton from './components/GridButton';

const gridData = [
    {
        image: 'cac_cp_adduser',
        labelLines: ['Add', 'User'],
        route: '/adduser'
    },
    {
        image: 'cac_cp_manageuser',
        labelLines: ['Manage', 'User'],
        route: '/manageusers'
    },
    {
        image: 'cac_cp_set_customer_view',
        labelLines: ['Set Customer View'],
        route: '/customerview'
    },
    {
        image: 'cac_cp_customer_report_dates',
        labelLines: ['Customer Report Dates'],
        route: '/reportdates'
    },
    {
        image: 'cac_cp_edit_alerts',
        labelLines: ['Edit Alert Parameters'],
        route: '/alertparameters'
    },
    {
        image: 'cac_cp_send_push',
        labelLines: ['Send Push Messages'],
        route: '/pushmessages'
    },
    {
        image: 'cac_cp_mail',
        labelLines: ['CR-Alert Email Addresses'],
        route: '/alertemail'
    },
    // Filler that ideally we would like to have lodash.chunk add
    // automatically, see https://github.com/lodash/lodash/issues/4372
    { labelLines: [] }
];

class ControlPanel extends PureComponent {
    static propTypes = {
        history: PropTypes.shape({
            push: PropTypes.func.isRequired
        }).isRequired
    };

    onBackClick = () => {
        this.props.history.goBack();
    };

    onItemClick = (item) => {
        console.log(item.route);
        // TODO Remove guard clause
        const enabledRoutes = new Set([
            '/adduser',
            '/manageusers',
            '/reportdates',
            '/alertparameters',
            '/customerview',
            '/alertemail',
            '/pushmessages'
        ]);
        if (enabledRoutes.has(item.route)) {
            this.props.history.push(item.route);
        } else {
            alert(WORK_IN_PROGRESS);
        }
    };

    render() {
        return (
            <div>
                <NavigationBar />
                <TitleBar title='Control Panel' />
                <ControlPanelPage>
                    <Col>
                        {chunk(gridData, 4).map((row, i) => (
                            <GridRow key={i}>
                                {row.map((item, j) => (
                                    <GridButton
                                        key={i + j}
                                        item={item}
                                        onItemClick={this.onItemClick}
                                    />
                                ))}
                            </GridRow>
                        ))}
                    </Col>
                </ControlPanelPage>
            </div>
        );
    }
}

const GridRow = styled(Row)`
    display: flex;
    justify-content: space-around;

    && {
        margin-bottom: 50px;
    }
`;

export default withRouter(ControlPanel);
