import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import styled from 'styled-components';
import dimension from './../res/styles/Responsive';

const expandDown = require('../res/images/cac_expand_down.png');
const expandUp = require('../res/images/cac_expand_up.png');

export default class ExpandIcon extends PureComponent {
    static propTypes = {
        className: PropTypes.string,
        alt: PropTypes.string,
        onClick: PropTypes.func,
        isInitiallyActive: PropTypes.bool
    };

    state = {
        isActive: this.props.isInitiallyActive || false
    };

    handleToggle = (event) => {
        event.stopPropagation();
        this.setState((state) => ({
            isActive: !state.isActive
        }));
        this.props.onClick && this.props.onClick();
    };

    render() {
        return (
            <ImageExpandDiv className={this.props.className}>
                <img
                    onClick={this.handleToggle}
                    src={this.state.isActive ? expandUp : expandDown}
                    alt={this.props.alt}
                />
            </ImageExpandDiv>
        );
    }
}

const ImageExpandDiv = styled.div`
    flex-direction: column;
    display: flex;
    padding: 5px;
    cursor: pointer;

    img {
        width: 20px;
        margin-bottom: auto;
        margin-left: auto;
    }

    @media ${dimension.md} {
        margin-top: auto;
        margin-bottom: 10px;
    }
`;
