import moment from 'moment';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { BrowserView, MobileOnlyView } from 'react-device-detect';
import styled from 'styled-components';
import { AppContext } from '../../../App';
import InfoIcon from '../../../components/InfoIcon';
import { A, B, CAC_TYPE, EST_BULBS, EST_YIELD, OP } from '../../../res/Constants';
import colors from '../../../res/styles/Colors';
import dimension from '../../../res/styles/Responsive';
import { extractRoguingRemarks } from '../../../service/CommonFunctions';
import { formatLongToDateString } from '../../../utils/DateFormatter';
import {
    EmptyDiv,
    FieldTitle,
    HorizontalDiv,
    IconImg,
    VerticalDiv
} from './../../../components/Common';
import ExpandIcon from './../../../components/ExpandIcon';
import InfoText from './../../../components/InfoText';
import { randomId } from './../../../utils/Utils';

export default class TechItem extends PureComponent {
    static propTypes = {
        className: PropTypes.string,
        techFormData: PropTypes.object.isRequired,
        onItemClick: PropTypes.func
    };

    state = {
        isSelected: false
    };

    toggleItemDetail = () => {
        this.setState({ isSelected: !this.state.isSelected });
    };

    handleItemClick = () => {
        if (this.props.onItemClick) {
            this.props.onItemClick(this.props.techFormData.techFormPk);
        }
    };

    render() {
        const {
            techFormPk,
            custCode,
            speciesCode,
            varietyCode,
            varietyDesc,
            orderNumber,
            contractHarvest,
            contractOrganic,
            contractTunnel,
            zoneCode,
            growerName,
            growerFieldName,
            productionArea,
            finalHa,
            line,
            reportsList,
            alarmed
        } = this.props.techFormData;

        const [firstReport, secondReport] = reportsList;
        const isTechFormDestroyed = finalHa === 0;

        return (
            <TechItemContainer
                onClick={this.handleItemClick}
                className={this.props.className}
                isDestroyed={isTechFormDestroyed}>
                <div className='d-container'>
                    <div
                        className='d-item-selected d-md-none'
                        style={
                            this.state.isSelected
                                ? { backgroundColor: 'green' }
                                : { backgroundColor: 'inherit' }
                        }
                    />
                    <div className='d-inner-container'>
                        <div>
                            <TitleContainer>
                                <h5 className='main-title'>
                                    {custCode} {speciesCode} {varietyCode}
                                </h5>
                                <EmptyDiv width='20px' />
                                {alarmed && (
                                    <IconImg
                                        src={require('../../../res/images/cac_tech_alert.png')}
                                    />
                                )}
                            </TitleContainer>
                            <h4 className='customer'>{varietyDesc}</h4>
                            <p className='zone'>
                                Lot N° {orderNumber} - {contractHarvest} {contractOrganic}{' '}
                                {contractTunnel} - Zone: {zoneCode}
                            </p>
                            <HorizontalDiv>
                                <p className='grower'>{growerName}</p>
                                <InfoIcon
                                    contents={[
                                        { title: 'Grower Name', desc: growerFieldName },
                                        { title: 'Production area', desc: productionArea }
                                    ]}
                                    id={'grower' + orderNumber}
                                />
                            </HorizontalDiv>
                        </div>
                        <BrowserView viewClassName='d-none d-md-flex d-inner-container'>
                            <TechForm
                                className='d-none d-md-flex'
                                techFormData={this.props.techFormData}
                            />
                        </BrowserView>
                        <VerticalDiv>
                            {firstReport ? (
                                <ExpandIcon onClick={this.toggleItemDetail} alt='show reports' />
                            ) : (
                                <EmptyDiv width='30px' />
                            )}
                            {firstReport && (
                                <div className='d-md-none' style={{ marginTop: 'auto' }}>
                                    <p className='last-report'>Last Report</p>
                                    <p className='last-report-date'>
                                        {formatLongToDateString(firstReport.date)}
                                    </p>
                                </div>
                            )}
                        </VerticalDiv>
                    </div>
                </div>
                {this.state.isSelected && (
                    <div>
                        <MobileOnlyView viewClassName='d-inner-container-expandable d-md-none'>
                            <TechForm techFormData={this.props.techFormData} />
                        </MobileOnlyView>
                        <div>
                            {firstReport && (
                                <SingleReport
                                    techFormPk={techFormPk}
                                    line={line}
                                    {...firstReport}
                                />
                            )}
                        </div>
                        {secondReport && (
                            <div className='d-none d-md-block'>
                                <div className='h-separator' />
                                <SingleReport
                                    techFormPk={techFormPk}
                                    line={line}
                                    {...secondReport}
                                />
                            </div>
                        )}
                    </div>
                )}
            </TechItemContainer>
        );
    }
}

const TechForm = ({ techFormData }) => {
    const {
        line,
        startingYield,
        orderNumber,
        price,
        sTTypeA,
        sTTypeB,
        contractRatio,
        plantsNumberA,
        plantsNumberB,
        uncleanWeight,
        cleanWeight,
        germinationRate,
        startingHa,
        destroyHa,
        finalHa,
        notes
    } = techFormData;

    const formatDate = (date) => moment(date).format('DD-MM-YYYY');

    const roguingRemarksLines = extractRoguingRemarks(techFormData);
    const roguingA = roguingRemarksLines.lineA.dates.map((obj) => ({
        title: formatDate(obj.date),
        desc: obj.name
    }));
    roguingA.push({
        title: roguingRemarksLines.lineA.extraContent.name,
        desc: roguingRemarksLines.lineA.extraContent.content
    });
    const roguingB = roguingRemarksLines.lineB.dates.map((obj) => ({
        title: formatDate(obj.date),
        desc: obj.name
    }));
    roguingB.push({
        title: roguingRemarksLines.lineB.extraContent.name,
        desc: roguingRemarksLines.lineB.extraContent.content
    });

    const buildSowingDate = (obj) => ({ title: '', desc: formatDate(obj.date) });
    const sowingA = techFormData.sowingTransA.map((obj) => buildSowingDate(obj));
    const sowingB = techFormData.sowingTransB.map((obj) => buildSowingDate(obj));

    const nurserySowingA = techFormData.nurserySowingA.map((obj) => buildSowingDate(obj));
    const nurserySowingB = techFormData.nurserySowingB.map((obj) => buildSowingDate(obj));

    const formatSowTran = () => (line > 1 ? `${sTTypeA}/${sTTypeB}` : sTTypeA);
    const formatPlantsNumber = () =>
        line > 1 ? `${plantsNumberA}/${plantsNumberB}` : plantsNumberA;

    return (
        <>
            <div>
                {startingYield > 0 && <InfoText title='Yield/Ha' content={startingYield} />}
                <VerticalDiv>
                    <FieldTitle>Roguing Remarks</FieldTitle>
                    <HorizontalDiv style={{ justifyContent: 'space-around' }}>
                        <HorizontalDiv>
                            {roguingA.length > 0 ? (
                                <>
                                    <ContentDescription>{line > 1 ? A : OP}</ContentDescription>
                                    <InfoIcon contents={roguingA} id={`${A}${orderNumber}`} />
                                </>
                            ) : (
                                <ContentDescription>-</ContentDescription>
                            )}
                        </HorizontalDiv>
                        {line > 1 && (
                            <HorizontalDiv>
                                {roguingB.length > 0 ? (
                                    <>
                                        <ContentDescription>B</ContentDescription>
                                        <InfoIcon contents={roguingB} id={`${B}${orderNumber}`} />
                                    </>
                                ) : (
                                    <ContentDescription>-</ContentDescription>
                                )}
                            </HorizontalDiv>
                        )}
                    </HorizontalDiv>
                </VerticalDiv>
            </div>
            <div>
                <InfoText title='Price' content={price} />
                <InfoText title='Sow/Tran' content={formatSowTran()} />
            </div>
            <div>
                <InfoText title='Ratio' content={contractRatio} />
                <InfoText title='Plants Number' content={formatPlantsNumber()} />
            </div>
            <div>
                <InfoText
                    title='UKg/CKg/G%'
                    content={`${uncleanWeight ?? '-'} / ${cleanWeight ?? '-'} / ${germinationRate ??
                        '-'}`}
                />
                <VerticalDiv>
                    <InfoText title='Sowing T. Dates' />
                    <HorizontalDiv style={{ justifyContent: 'space-around' }}>
                        <HorizontalDiv>
                            {sowingA.length > 0 ? (
                                <>
                                    <ContentDescription>{line > 1 ? A : OP}</ContentDescription>
                                    <InfoIcon contents={sowingA} id={`${A}${randomId()}`} />
                                </>
                            ) : (
                                <ContentDescription>-</ContentDescription>
                            )}
                        </HorizontalDiv>
                        {line > 1 && (
                            <HorizontalDiv>
                                {sowingB.length > 0 ? (
                                    <>
                                        <ContentDescription>B</ContentDescription>
                                        <InfoIcon contents={sowingB} id={`${B}${randomId()}`} />
                                    </>
                                ) : (
                                    <ContentDescription>-</ContentDescription>
                                )}
                            </HorizontalDiv>
                        )}
                    </HorizontalDiv>
                </VerticalDiv>
            </div>
            <div>
                <InfoText title='Ha S/D/F' content={startingHa + '/' + destroyHa + '/' + finalHa} />
                <VerticalDiv>
                    <InfoText title='Nursery Sowing T. Dates' />
                    <HorizontalDiv style={{ justifyContent: 'space-around' }}>
                        <HorizontalDiv>
                            {sowingA.length > 0 ? (
                                <>
                                    <ContentDescription>{line > 1 ? A : OP}</ContentDescription>
                                    <InfoIcon contents={nurserySowingA} id={`${A}${randomId()}`} />
                                </>
                            ) : (
                                <ContentDescription>-</ContentDescription>
                            )}
                        </HorizontalDiv>
                        {line > 1 && (
                            <HorizontalDiv>
                                {sowingB.length > 0 ? (
                                    <>
                                        <ContentDescription>B</ContentDescription>
                                        <InfoIcon
                                            contents={nurserySowingB}
                                            id={`${B}${randomId()}`}
                                        />
                                    </>
                                ) : (
                                    <ContentDescription>-</ContentDescription>
                                )}
                            </HorizontalDiv>
                        )}
                    </HorizontalDiv>
                </VerticalDiv>
            </div>
            <div>
                <InfoText title='General Remarks' isPopover={true} content={notes} />
            </div>
        </>
    );
};

const SingleReport = (props) => {
    const appContext = React.useContext(AppContext);

    const formatReportProperty = (prop) => {
        return props.line > 1 ? `${props[prop + A]}/${props[prop + B]}` : props[prop + A];
    };

    const formatRoguing = () => {
        if (props.roguingA == null && props.roguingB == null) {
            return '';
        } else {
            if (props.line > 1) {
                return (
                    (props.roguingA == null ? '-' : props.roguingA) +
                    '/' +
                    (props.roguingB == null ? '-' : props.roguingB)
                );
            } else {
                return props.roguingA == null ? '-' : props.roguingA;
            }
        }
    };

    return (
        <div className='d-inner-container-expandable'>
            <ColumnInfo>
                <InfoText title='Date' content={formatLongToDateString(props.date)} />
                <InfoText title='Standing' content={formatReportProperty('stand')} />
            </ColumnInfo>
            <ColumnInfo>
                <InfoText title='Develop.' content={formatReportProperty('develop')} />
                <InfoText title='Uniform.' content={formatReportProperty('uniform')} />
            </ColumnInfo>
            <ColumnInfo>
                <InfoText title='Bolting' content={formatReportProperty('bolt')} />
                <InfoText title='Dis. Gravity' content={formatReportProperty('diseasesGravity')} />
            </ColumnInfo>
            <ColumnInfo>
                <InfoText
                    title='Dis. Desc.'
                    content={formatReportProperty('diseasesDescription')}
                />
                <InfoText title='Remarks' isPopover={true} content='' />
            </ColumnInfo>
            <ColumnInfo>
                <InfoText title='Roguing' isPopover={true} content={formatRoguing()} />
                <InfoText
                    title={appContext.cacType === CAC_TYPE.CROP ? EST_YIELD : EST_BULBS}
                    content={props.expYield}
                />
            </ColumnInfo>
        </div>
    );
};

const ColumnInfo = styled.div`
    @media ${dimension.md} {
        display: flex;
        flex-direction: row;
        width: inherit;
    }
`;

const TechItemContainer = styled.div`
    p {
        margin-bottom: 0.1rem;
    }

    .d-container {
        display: flex;
        background-color: white;

        ${({ isDestroyed }) => isDestroyed && `background-color: ${colors.light_red}`}
    }

    .d-inner-container,
    .d-inner-container-expandable {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 15px;

        &-expandable {
            background-color: ${colors.lighter_green};
        }

        @media ${dimension.md} {
            justify-content: space-between;
        }
    }

    .d-item-selected {
        width: 8px;
        background-color: white;
    }

    .zone {
        font-size: 12px;
    }

    .customer {
        font-size: 14px;
    }

    .grower {
        margin-top: auto;
        margin-bottom: auto;
        margin-right: 5px;
        font-size: 12px;
        font-weight: bold;
    }

    .last-report {
        font-size: 12px;
        font-weight: bold;
    }

    .last-report-date {
        font-size: 12px;
    }

    .h-separator {
        height: 1px;
        width: 100%;
        background-color: lightgray;
    }

    .username {
        font-weight: 500;
    }
`;

const TitleContainer = styled(HorizontalDiv)`
    .main-title {
        color: ${colors.green};
        font-size: 14px;
    }

    @media ${dimension.md} {
        min-width: 250px;
    }
`;

const ContentDescription = styled.p`
    font-size: 12px;
    color: ${colors.darker_gray};
`;
