import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import NavigationBar from '../../components/NavigationBar/NavigationBar';
import TitleBar from '../../components/TitleBar';
import buttonTheme from '../../res/styles/theme_button.module.scss';
import { getSettingList, saveSettings } from '../../service/SettingService';
import { VerticalDiv } from './../../components/Common';
import EditableText from './../../components/EditableText';
import colors from './../../res/styles/Colors';
import dimension from './../../res/styles/Responsive';
import { toastError } from './../../ui/ToastHelper';
import { ControlPanelPage } from './components/Common';

const emailKey = 'ExtraReportEmailAddresses';

export default function CrAlertEmails() {
    const history = useHistory();
    const [emails, setEmails] = React.useState(null);

    React.useEffect(() => {
        async function fetchSettings() {
            const fetchedEmails = await getSettingList([emailKey]);
            if (fetchedEmails.length > 0) {
                setEmails(fetchedEmails[0].value);
            } else {
                setEmails('');
            }
        }
        fetchSettings();
    }, []);

    const onUpdateEmails = (_, newEmails) => {
        setEmails(newEmails);
    };

    const onSave = () => {
        const newSetting = { key: emailKey, value: emails };
        saveSettings(
            [newSetting],
            () => {
                history.goBack();
            },
            () => {
                toastError('An error occurred.');
            }
        );
    };

    return (
        <div>
            <NavigationBar />
            <TitleBar title='Edit CR-Alert Email Addresses' />
            <ControlPanelPage>
                {emails && (
                    <StyledContainer>
                        <StyledFormTitle>
                            Insert email addresses separated by commas <br />
                            (e.g. you@example.com, me@cacseeds.it)
                        </StyledFormTitle>
                        <StyledTextArea rows={6} initialValue={emails} onBlur={onUpdateEmails} />
                        <StyledButtonSave onClick={onSave}>SAVE</StyledButtonSave>
                    </StyledContainer>
                )}
            </ControlPanelPage>
        </div>
    );
}

const StyledTextArea = styled(EditableText)`
    && {
        width: 100%;
    }
`;

const StyledFormTitle = styled.p`
    font-size: 14px;
    color: ${colors.darker_gray};
`;

const StyledButtonSave = styled.button.attrs({
    className: buttonTheme.green
})`
    margin-top: 30px;
`;

const StyledContainer = styled(VerticalDiv)`
    max-width: 80%;
    margin: 0 auto;

    @media ${dimension.md} {
        width: 65%;
    }

    @media ${dimension.lg} {
        width: 50%;
    }
`;
