import React from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import createPersistedState from 'use-persisted-state';
import { AppContext, DEFAULT_FILTERS_BY_USER } from '../../App';
import { HorizontalDiv } from '../../components/Common';
import LoadingWheel from '../../components/LoadingWheel';
import NavigationBar from '../../components/NavigationBar/NavigationBar';
import {
    CAC_TYPE,
    MAX_NUMBER_OF_TECHFORMS,
    SORT_TYPE,
    TECHFORM_FILTER_FIELDS,
    TECHFORM_MAIN_FIELDS,
    TECHFORM_MAIN_FIELDS_KEYS
} from '../../res/Constants';
import colors from '../../res/styles/Colors';
import dimension from '../../res/styles/Responsive';
import { getFilters } from '../../service/FilterService';
import { downloadPhotosByDates } from '../../service/PhotoService';
import { downloadExcel, getTechformList, getTechformListSize } from '../../service/TechFormService';
import { toastError, toastInfo, toastSuccess } from '../../ui/ToastHelper';
import { filterByFields, sort } from '../../utils/Algorithms';
import { formatDateStringToLong } from '../../utils/DateFormatter';
import DownloadPhotoDialog from './components/DownloadPhotoDialog';
import NavBar from './components/NavBar';
import TechFilters from './components/TechFilters';
import TechList from './components/TechList';
import TooManyResultsMessage from './components/TooManyResultsMessage';

const DEFAULT_RESULTS_COUNT = -1;
const fieldsToSearchOn = [...TECHFORM_MAIN_FIELDS_KEYS, 'varietyCode', 'orderNumber'];
const defaultSortSettings = {
    sortType: SORT_TYPE.ASCENDANT,
    field: TECHFORM_MAIN_FIELDS.GROWER
};

const usePersistedSortSettings = createPersistedState('sortSettings');

export default function TechFormList() {
    const history = useHistory();

    const [sortSettings, setSortSettings] = usePersistedSortSettings(defaultSortSettings);
    const [techFormListFiltered, setTechFormListFiltered] = React.useState(null);
    const [canFetchTechformList, setCanFetchTechformList] = React.useState(false);
    const [rawFilters, setRawFilters] = React.useState();
    const [resetFiltersCounter, setResetFiltersCounter] = React.useState(0);
    const [resetSearchCounter, setResetSearchCounter] = React.useState(0);
    const [resultsCount, setResultsCount] = React.useState(DEFAULT_RESULTS_COUNT);
    const [photosDialogVisible, setPhotosDialogVisible] = React.useState(false);

    const appContext = React.useContext(AppContext);

    const { data: techFormList, isFetching } = useQuery(
        [appContext.cacType, appContext.filtersByUser],
        async () => await getTechformList(appContext.cacType, appContext.filtersByUser),
        {
            refetchOnMount: false,
            refetchOnWindowFocus: false,
            cacheTime: Infinity,
            staleTime: Infinity,
            enabled: canFetchTechformList,
            onSuccess: () => setCanFetchTechformList(false),
            onSettled: () => setResultsCount(DEFAULT_RESULTS_COUNT)
        }
    );

    React.useEffect(() => {
        setTechFormListFiltered(null);
        setResultsCount(DEFAULT_RESULTS_COUNT);
    }, [appContext.cacType]);

    React.useEffect(() => {
        if (techFormList != null && techFormList.length > 0) {
            const { field, sortType } = sortSettings;
            setTechFormListFiltered(sortTechFormList(techFormList, field, sortType));
        }
    }, [sortSettings, techFormList]);

    React.useEffect(() => {
        getFilters(appContext.cacType, (fetchedFilters) => {
            if (fetchedFilters == null) return;
            setRawFilters(fetchedFilters);
        });
    }, [appContext.cacType]);

    function getCurrentFilters() {
        return appContext.cacType === CAC_TYPE.CROP
            ? appContext.filtersByUser.crop
            : appContext.filtersByUser.nursery;
    }

    const sortTechFormList = (list, field, sortType) => {
        const indexValue = TECHFORM_FILTER_FIELDS.findIndex((e) => e === field.value);
        if (indexValue === -1) {
            return list;
        }
        const propToSort = TECHFORM_MAIN_FIELDS_KEYS[indexValue];
        return sort(list, sortType, propToSort);
    };

    const onFiltersApply = async (newFilters) => {
        const filterKeyToUpdate = appContext.cacType === CAC_TYPE.CROP ? 'crop' : 'nursery';
        appContext.setFiltersByUser((curr) => ({
            ...curr,
            [filterKeyToUpdate]: newFilters
        }));
        setTechFormListFiltered(null);

        const resultsSize = await getTechformListSize(newFilters);
        console.log('TCL: onFiltersApply -> resultsSize', resultsSize);
        setResultsCount(resultsSize);
        if (resultsSize > MAX_NUMBER_OF_TECHFORMS || resultsSize === 0) {
            setCanFetchTechformList(false);
        } else if (resultsSize > 0) {
            onLoadTechformList();
        }
    };

    const onLoadTechformList = () => {
        setCanFetchTechformList(true);
        setResultsCount(DEFAULT_RESULTS_COUNT);
    };

    const onExcelDownload = (lines) => {
        downloadExcel(
            { ...getCurrentFilters(), type: appContext.cacType },
            lines,
            () => {},
            () => {}
        );
    };

    const onPhotosDownload = (from, to) => {
        setPhotosDialogVisible(false);
        const fromAsLong = formatDateStringToLong(from);
        const toAsLong = formatDateStringToLong(to);
        const persistentMessage = (
            <LoadingToastMessage text={'Downloading photos has started, please wait.'} />
        );
        toastInfo(persistentMessage, true);
        downloadPhotosByDates(
            { ...getCurrentFilters(), type: appContext.cacType },
            fromAsLong,
            toAsLong,
            () => toastSuccess('Download completed.'),
            (error) => toastError(error || 'Server error.')
        );
    };

    const onSearchChange = (value) => {
        const techFormsFilteredBySearch = filterByFields(techFormList, fieldsToSearchOn, value);
        setTechFormListFiltered(techFormsFilteredBySearch);
    };

    const onSortingSettingsChange = (newSortSettings) => {
        setSortSettings(newSortSettings);
    };

    const onTechFormClick = (id) => {
        history.push(`/techforms/${id}`);
    };

    const onFiltersReset = () => {
        // setTechFormList(null);
        setTechFormListFiltered(null);
        appContext.setFiltersByUser(DEFAULT_FILTERS_BY_USER);
        setSortSettings(defaultSortSettings);
        setResetFiltersCounter(resetFiltersCounter + 1);
        setResetSearchCounter(resetSearchCounter + 1);
    };

    const techFormListToDisplay = techFormListFiltered || techFormList;
    return (
        <div>
            <NavigationBar />
            <NavBar
                filters={rawFilters}
                prevFilters={getCurrentFilters()}
                fieldToSortBy={sortSettings}
                downloadEnabled={techFormListToDisplay?.length > 0}
                onExcelDownload={onExcelDownload}
                onPhotosDownload={() => setPhotosDialogVisible(true)}
                onSortSettingsChange={onSortingSettingsChange}
                onFiltersApply={onFiltersApply}
                onSearchChange={onSearchChange}
                onFiltersReset={onFiltersReset}
                searchKey={resetSearchCounter}
                key={resetFiltersCounter}
            />
            <TechFormPage>
                {!isMobileOnly && rawFilters != null && (
                    <TechFilters
                        filters={rawFilters}
                        prevFilters={getCurrentFilters()}
                        onFiltersApply={onFiltersApply}
                        onFiltersReset={onFiltersReset}
                        searchKey={resetSearchCounter}
                        key={resetFiltersCounter}
                    />
                )}
                {isFetching && <LoadingWheel />}
                {!isFetching && resultsCount === 0 && (
                    <StyledEmptyError>No results for current filters</StyledEmptyError>
                )}
                {!isFetching && techFormListToDisplay?.length > 0 && (
                    <TechList techFormList={techFormListToDisplay} onItemClick={onTechFormClick} />
                )}
                {!isFetching && resultsCount > MAX_NUMBER_OF_TECHFORMS && (
                    <TooManyResultsMessage
                        cacType={appContext.cacType}
                        resultsCount={resultsCount}
                        onContinue={onLoadTechformList}
                        onExcelDownload={onExcelDownload}
                        onPhotosDownload={() => setPhotosDialogVisible(true)}
                    />
                )}
                <DownloadPhotoDialog
                    isModalOpen={photosDialogVisible}
                    toggleDialog={() => setPhotosDialogVisible((curr) => !curr)}
                    onPhotosDownload={onPhotosDownload}
                />
            </TechFormPage>
        </div>
    );
}

function LoadingToastMessage(props) {
    return (
        <HorizontalDiv>
            <p style={{ margin: '0 20px 0 5px' }}>{props.text}</p>
            <LoadingWheel height={30} width={30} />
        </HorizontalDiv>
    );
}

const TechFormPage = styled.div`
    min-height: 74vh;
    padding-top: 0px;
    background-color: ${colors.light_gray};

    @media ${dimension.md} {
        display: flex;
        min-height: 81vh;
    }
`;

const StyledEmptyError = styled.p`
    color: ${colors.red};
    margin: auto;
    text-align: center;

    @media ${dimension.md} {
        margin-top: 30px;
    }
`;
