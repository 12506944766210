import * as _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { EmptyDiv, FlexDiv, HorizontalDiv, ImgButton, Textarea } from '../../../components/Common';
import ConfirmDialog from '../../../components/ConfirmDialog';
import Dropdown from '../../../components/Dropdown';
import NavigationBar from '../../../components/NavigationBar/NavigationBar';
import TitleBar from '../../../components/TitleBar';
import { USER_ROLES } from '../../../res/Constants';
import colors from '../../../res/styles/Colors';
import dimension from '../../../res/styles/Responsive';
import themeButton from '../../../res/styles/theme_button.module.scss';
import { getClients } from '../../../service/ClientService';
import { getUsers } from '../../../service/UserService';
import { getName, saveVisitList } from '../../../service/VisitService';
import {
    dismissToast,
    toastError,
    toastInfo,
    toastSuccess,
    toastWarning
} from '../../../ui/ToastHelper';
import { timeWithSeconds } from '../../../utils/DateFormatter';
import { Input, PanelFieldTitle } from '../../ControlPanel/components/Common';
import { MaterialDatePicker } from '../Components/Common';
import StaffInput from '../Components/StaffInput';
import VisitItem from '../Components/VisitItem';

const OPERATIONS = Object.freeze({
    create: 'create',
    edit: 'edit',
    clone: 'clone'
});

const initialVisit = {
    date: moment().valueOf(),
    users: [],
    otherUsers: []
};

VisitEditing.propTypes = {
    visit: PropTypes.object
};

export default function VisitEditing(props) {
    const history = useHistory();
    const visitFromProps = props?.location?.data;

    const [consecutiveVisits, setConsecutiveVisits] = React.useState([]);
    const [clients, setClients] = React.useState([]);
    const [staffList, setStaffList] = React.useState([]);
    const [visit, setVisit] = React.useState(visitFromProps ?? initialVisit);
    /**
     * temporary id required to identify the visits
     * of the list below. This is also used to identify
     * which visit to delete.
     */
    const [latestTempId, setLatestTempId] = React.useState(0);
    const [cancelDialogOpen, setCancelDialogOpen] = React.useState(false);
    const [hasPendingRequest, setHasPendingRequest] = React.useState(false);

    React.useEffect(() => {
        getClients((clients) => {
            setClients(clients);
        });
        getUsers((users) => {
            setStaffList(users.filter((u) => u.role !== USER_ROLES.client));
        });
    }, []);

    const onFieldChange = (e) => {
        const name = e?.target?.name;
        let value = e?.target?.value;

        if (name != null && value != null) {
            if (name === 'date') value = moment(value).valueOf();
            setVisit((curr) => ({ ...curr, [name]: value }));
        }
    };

    const onClientChange = (clientName) => {
        const selClient = clients.find((c) => c.code === clientName) || null;
        setVisit((curr) => ({ ...curr, client: selClient }));
    };

    const onStaffAdd = (name) => {
        if (name == null || name === '') {
            return toastWarning('Staff not selected.');
        }
        const userFromStaff = staffList.find((u) => getName(u) === name);
        if (visit.users.includes(userFromStaff) || visit.otherUsers.includes(name)) {
            return toastWarning('User already assigned.');
        }
        if (userFromStaff != null) {
            setVisit((curr) => ({ ...curr, users: [...curr.users, userFromStaff] }));
        } else {
            setVisit((curr) => ({ ...curr, otherUsers: [...curr.otherUsers, name] }));
        }
    };

    const onRemoveAssignedUser = (name) => {
        const userFromStaff = visit?.users?.find((u) => getName(u) === name);
        if (userFromStaff != null) {
            setVisit((curr) => ({ ...curr, users: _.without(curr.users, userFromStaff) }));
        } else {
            setVisit((curr) => ({
                ...curr,
                otherUsers: _.without(curr.otherUsers, name)
            }));
        }
    };

    const onSaveVisitDay = () => {
        if (visit?.date == null || isNaN(visit?.date)) {
            return toastWarning('Date is required.');
        }
        if (visit != null) {
            setConsecutiveVisits((curr) => [...curr, { ...visit, tempId: latestTempId - 1 }]);
            setLatestTempId((curr) => curr - 1);
            setVisit((curr) => ({
                ...curr,
                date: moment(curr.date)
                    .add(1, 'day')
                    .valueOf()
            }));
        }
    };

    const onRemoveVisitDay = (visitTempId) => {
        setConsecutiveVisits((curr) => curr.filter((v) => v.tempId != visitTempId));
    };

    const onCancel = () => {
        setCancelDialogOpen(true);
    };

    const onCancelConfirmed = (_, isConfirmed) => {
        setCancelDialogOpen(false);
        if (isConfirmed) {
            history.goBack();
        }
    };

    const onDone = () => {
        if (visit.date == null) {
            return toastWarning('Date is required.');
        }
        // remove the temporary id from the visits
        let visitsToSave = [];
        if (getOperation(visitFromProps) === OPERATIONS.edit) {
            visitsToSave.push({ ...visit, time: timeWithSeconds(visit?.time) });
        } else {
            visitsToSave = consecutiveVisits.map((v) => {
                const { time, ...rest } = v;
                return { ...rest, time: timeWithSeconds(time), tempId: null };
            });
        }

        toastInfo('Saving visit...', true);
        setHasPendingRequest(true);
        saveVisitList(
            visitsToSave,
            () => {
                toastSuccess('Visits saved.');
                dismissToast();
                setHasPendingRequest(false);
                history.goBack();
            },
            () => {
                toastError('Error while saving visits.');
                dismissToast();
                setHasPendingRequest(false);
            }
        );
    };

    const defaultClient = _.find(clients, { 'code': visit?.client?.code })?.code;

    const staffOptions = React.useMemo(() => {
        const alreadyAssignedNames = visit?.users?.map((u) => u?.username);
        const staffNames = staffList.map((u) => getName(u));
        return _.difference(staffNames, alreadyAssignedNames);
    }, [staffList, visit]);

    const clientsOptions = React.useMemo(() => {
        return clients.map((c) => c.code);
    }, [clients]);

    const pageOp = getOperation(visitFromProps);

    const showFooterButtons =
        (consecutiveVisits?.length > 0 &&
            (pageOp === OPERATIONS.clone || pageOp === OPERATIONS.create)) ||
        pageOp === OPERATIONS.edit;

    return (
        <StyledVisitPage>
            <div style={{ position: 'fixed', width: '100%' }}>
                <NavigationBar />
                <TitleBar title={getPageTitle(pageOp)} hasBack={true} />
            </div>
            <StyledBodyContainer>
                <DatesPickerContainer>
                    <FlexDiv justifyContent='space-between'>
                        <p>Date: </p>
                        <MaterialDatePicker
                            value={moment(visit?.date).format(moment.HTML5_FMT.DATE)}
                            name='date'
                            onChange={onFieldChange}
                        />
                    </FlexDiv>
                    <EmptyDiv width='40px' />
                    <HorizontalDiv>
                        <p>Pick-up Time: </p>
                        <MaterialDatePicker
                            type='time'
                            value={visit?.time}
                            name='time'
                            onChange={onFieldChange}
                        />
                    </HorizontalDiv>
                </DatesPickerContainer>
                <PanelFieldTitle>Client</PanelFieldTitle>
                <Dropdown
                    id='client'
                    style={{ width: '100%' }}
                    emptyOption={true}
                    options={clientsOptions}
                    value={defaultClient}
                    onChange={onClientChange}
                />
                <StyledStaffInput staff={staffOptions} onStaffAdd={onStaffAdd} />
                <AssignedStaffContainer>
                    {visit.users.map((user) => (
                        <AssignedUser
                            key={user.pk}
                            name={getName(user)}
                            onRemove={() => onRemoveAssignedUser(getName(user))}
                        />
                    ))}
                    {visit.otherUsers.map((name) => (
                        <AssignedUser
                            key={name}
                            name={name}
                            onRemove={() => onRemoveAssignedUser(name)}
                        />
                    ))}
                </AssignedStaffContainer>
                <PanelFieldTitle>Contacts</PanelFieldTitle>
                <Input
                    type='text'
                    name='contacts'
                    defaultValue={visit?.contacts || ''}
                    onChange={onFieldChange}
                />
                <PanelFieldTitle>Pick-up Place</PanelFieldTitle>
                <Input
                    type='text'
                    name='place'
                    defaultValue={visit?.place || ''}
                    onChange={onFieldChange}
                />
                <PanelFieldTitle>Notes</PanelFieldTitle>
                <Textarea
                    rows={6}
                    defaultValue={visit?.notes || ''}
                    name='notes'
                    placeholder='Write a note...'
                    onChange={onFieldChange}
                />
                {pageOp !== OPERATIONS.edit && (
                    <button className={themeButton.green} onClick={onSaveVisitDay}>
                        ADD VISIT DAY
                    </button>
                )}
            </StyledBodyContainer>
            <StyledListContainer>
                {consecutiveVisits?.map((visit) => (
                    <VisitItem
                        key={visit.tempId}
                        visit={visit}
                        onDelete={() => onRemoveVisitDay(visit.tempId)}
                    />
                ))}
                {showFooterButtons && (
                    <FooterButtonsContainer direction='row' justifyContent='space-between'>
                        <button
                            className={themeButton.gray}
                            disabled={hasPendingRequest}
                            onClick={onCancel}>
                            CANCEL
                        </button>
                        <button
                            className={themeButton.red}
                            disabled={hasPendingRequest}
                            onClick={onDone}>
                            DONE
                        </button>
                    </FooterButtonsContainer>
                )}
            </StyledListContainer>
            <ConfirmDialog
                elementId={_.uniqueId('confirm-')}
                isOpen={cancelDialogOpen}
                text='Are you sure you want to abort insertion?'
                onConfirm={onCancelConfirmed}
            />
        </StyledVisitPage>
    );
}

function getOperation(visit) {
    if (visit != null && visit.pk != null) return OPERATIONS.edit;
    else if (visit != null) return OPERATIONS.clone;
    else return OPERATIONS.create;
}

function getPageTitle(op) {
    if (op === OPERATIONS.create) return 'Add a new visit';
    else if (op === OPERATIONS.edit) return 'Edit visit day';
    else if (op === OPERATIONS.clone) return 'Clone visit day';
}

const AssignedUser = ({ name, onRemove }) => {
    return (
        <FlexDiv direction='row' alignItems='center'>
            <ImgButton
                src={require('../../../res/images/cac_remove_staff.png')}
                onClick={onRemove}
            />
            <p style={{ fontSize: '12px', marginBottom: '0' }}>{name}</p>
        </FlexDiv>
    );
};

const StyledStaffInput = styled(StaffInput)`
    input {
        height: 30px;
        width: 200px;
        border-radius: 5px;
        box-shadow: 0 1px 2px grey;
        margin-bottom: 10px;

        &:focus {
            outline: none;
        }
    }
`;

const FooterButtonsContainer = styled(FlexDiv)`
    width: 100%;
    padding-top: 20px;

    button {
        width: 35%;
        max-width: 200px;
    }
`;

const StyledListContainer = styled.div`
    background-color: ${colors.light_gray};
    padding: 0 5%;
    margin-top: 20px;

    @media ${dimension.md} {
        padding: 0 15%;
    }

    & > * {
        padding-bottom: 15px;
    }
`;

const AssignedStaffContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
`;

const DatesPickerContainer = styled.div`
    display: flex;
    flex-direction: column;

    /* smartphone */
    margin: 0 auto;
    width: fit-content;

    @media ${dimension.md} {
        width: 100%;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    div {
        margin-bottom: 10px;
    }

    p {
        font-size: 14px;
        margin-bottom: 0;
        margin-right: 10px;
        font-weight: bold;
    }

    & > * {
        margin-top: 5px;

        @media ${dimension.lg} {
            margin-top: 15px;
        }
    }
`;

const StyledVisitPage = styled.div`
    min-height: 100%;
    background-color: ${colors.light_gray};
`;

const StyledBodyContainer = styled.div`
    padding-top: 19vh; // due to the fixed header
    padding-bottom: 30px;
    width: 70%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    background-color: ${colors.light_gray};

    @media ${dimension.md} {
        width: 50%;
    }

    @media ${dimension.lg} {
        width: 40%;
    }

    & > * {
        margin-top: 10px;
    }
    input {
        font-size: 14px;
    }
`;
