import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { isMobileOnly } from 'react-device-detect';
import styled from 'styled-components';
import { ColNoPadding, HorizontalDiv } from '../../../../../components/Common';
import InfoText from '../../../../../components/InfoText';
import { CAC_TYPE } from '../../../../../res/Constants';
import dimension from '../../../../../res/styles/Responsive';

ReportLine.propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
    bgColor: PropTypes.string,
    reportLineData: PropTypes.object,
    cacType: PropTypes.number.isRequired
};

export default function ReportLine(props) {
    const {
        Stand,
        Develop,
        Uniformity,
        Bolt,
        DiseasesDescription,
        DiseasesGravity,
        RoguingRemarks,
        Remarks,
        lineName
    } = _.mapValues(props.reportLineData, (value) => (value == null || value == '' ? '-' : value));

    return (
        <ColoredDiv className={props.className} bgColor={isMobileOnly ? props.bgColor : 'white'}>
            <TitleContainer>
                <Title color={props.color}>{`Line ${lineName}`}</Title>
            </TitleContainer>
            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
                <InfoText title='Standing' content={Stand} />
                <InfoText title='Development' content={Develop} />
                <InfoText title='Uniformity' content={Uniformity} />
                {props.cacType === CAC_TYPE.CROP ? (
                    <ColNoPadding xs='6'>
                        <InfoText title='Bolting' content={Bolt} />
                    </ColNoPadding>
                ) : (
                    <none />
                )}
                <InfoText title='Dis. Gravity' content={DiseasesGravity} />
                <InfoText title='Dis. Description' content={DiseasesDescription} />
                <InfoText title='Remarks' content={Remarks} />
                <InfoText title='Roguing' content={RoguingRemarks} />
            </div>
        </ColoredDiv>
    );
}

const ColoredDiv = styled.div`
    padding: 15px;
    background-color: ${({ bgColor }) => bgColor || 'inherit'};
`;

const Title = styled.h5`
    color: ${({ color }) => color || 'black'};
`;

const TitleContainer = styled(HorizontalDiv)`
    justify-content: center;

    @media ${dimension.md} {
        padding-bottom: 15px;
    }
`;
