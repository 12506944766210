import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { Row } from 'reactstrap';
import styled from 'styled-components';
import { ColNoPadding } from '../../../../../components/Common';
import ExpandIcon from '../../../../../components/ExpandIcon';
import InfoText from '../../../../../components/InfoText';
import ListItem from '../../../../../components/ListItem';
import { CAC_TYPE, HarvestValues, OrganicValues, TunnelValues } from '../../../../../res/Constants';
import Coordinates from './../components/Coordinates';

export default class BasicInfo extends PureComponent {
    static propTypes = {
        className: PropTypes.string,
        techFormData: PropTypes.object.isRequired,
        toggleExpand: PropTypes.func,
        cacType: PropTypes.number
    };

    render() {
        const {
            speciesCode,
            varietyDesc,
            varietyCode,
            price,
            startingYield,
            orderNumber,
            custCode
        } = this.props.techFormData;

        const cacNumber = `${speciesCode}${varietyCode}`;
        return (
            <StyledListItem
                className={this.props.className}
                isExpanded={!isMobileOnly}
                expandableChildren={
                    <ExpandedDetailData
                        techFormData={this.props.techFormData}
                        cacType={this.props.cacType}
                    />
                }>
                {(renderProps) => (
                    <div style={{ width: '100%' }}>
                        <TitleRow>
                            <ColNoPadding xs='8' md='12'>
                                <h5>Basic Information</h5>
                            </ColNoPadding>
                            <ColNoPadding className='d-md-none' xs='auto'>
                                <ExpandIcon onClick={renderProps.toggleExpand} />
                            </ColNoPadding>
                        </TitleRow>
                        <Row>
                            <ColNoPadding xs='3'>
                                <InfoText title='Variety' content={varietyDesc} />
                            </ColNoPadding>
                            <ColNoPadding xs='3'>
                                <InfoText title='CAC N.' content={cacNumber} />
                            </ColNoPadding>
                            <ColNoPadding xs='3'>
                                <InfoText title='Customer' content={custCode} />
                            </ColNoPadding>
                        </Row>
                        <Row>
                            <ColNoPadding xs='3'>
                                <InfoText title='Lot N°' content={orderNumber} />
                            </ColNoPadding>
                            {startingYield > 0 && (
                                <ColNoPadding xs='3'>
                                    <InfoText title='Yield/Ha' content={startingYield} />
                                </ColNoPadding>
                            )}
                            {this.props.cacType === CAC_TYPE.CROP && (
                                <ColNoPadding xs='3'>
                                    <InfoText title='Price' content={price} />
                                </ColNoPadding>
                            )}
                        </Row>
                    </div>
                )}
            </StyledListItem>
        );
    }
}

const ExpandedDetailData = ({ techFormData, cacType }) => {
    const {
        techFormPk,
        finalHa,
        destroyHa,
        startingHa,
        cleanWeight,
        uncleanWeight,
        sTTypeA,
        germinationRate,
        contractRatio,
        contractHarvest,
        contractOrganic,
        contractTunnel,
        productionArea,
        sectorCode,
        zoneCode,
        metadata,
        reportsList
    } = techFormData;

    const potentialYield = '';
    const harvest = HarvestValues[contractHarvest];
    const convOrg = OrganicValues[contractOrganic];
    const tunnel = TunnelValues[contractTunnel];
    const potentialBulbs = reportsList[0]?.expYield ?? '-';
    const coordinates = !!metadata === false ? {} : JSON.parse(metadata);

    return (
        <>
            <Row>
                {cacType === CAC_TYPE.CROP && (
                    <>
                        <ColNoPadding xs='3'>
                            <InfoText title='Harvest' content={harvest} />
                        </ColNoPadding>
                        <ColNoPadding xs='3'>
                            <InfoText title='Conv/Org' content={convOrg} />
                        </ColNoPadding>
                        <ColNoPadding xs='3'>
                            <InfoText title='Production Way' content={tunnel} />
                        </ColNoPadding>
                    </>
                )}
                <ColNoPadding xs='3'>
                    <InfoText title='Ratio' content={contractRatio} />
                </ColNoPadding>
                {cacType === CAC_TYPE.NURSERY && (
                    <ColNoPadding xs='3'>
                        <InfoText title='Potential Bulbs' content={potentialBulbs} />
                    </ColNoPadding>
                )}
            </Row>
            <Row>
                <ColNoPadding>
                    <InfoText title='Starting Ha' content={startingHa} />
                </ColNoPadding>
                <ColNoPadding>
                    <InfoText title='Destroyed Ha' content={destroyHa} />
                </ColNoPadding>
                <ColNoPadding>
                    <InfoText title='Final Ha' content={finalHa} />
                </ColNoPadding>
                <ColNoPadding>
                    {cacType === CAC_TYPE.CROP && <InfoText title='Sow/Trap' content={sTTypeA} />}
                </ColNoPadding>
            </Row>
            {cacType === CAC_TYPE.CROP && (
                <Row>
                    <ColNoPadding>
                        <InfoText title='Potential Yield' content={potentialYield} />
                    </ColNoPadding>
                    <ColNoPadding>
                        <InfoText title='Kg Unclean' content={uncleanWeight} />
                    </ColNoPadding>
                    <ColNoPadding>
                        <InfoText title='Kg Clean' content={cleanWeight} />
                    </ColNoPadding>
                    <ColNoPadding>
                        <InfoText
                            title='Germ'
                            content={`${Math.round(Number(germinationRate))} %`}
                        />
                    </ColNoPadding>
                </Row>
            )}
            <Row>
                <ColNoPadding>
                    <InfoText title='Sector' content={sectorCode} />
                </ColNoPadding>
                <ColNoPadding>
                    <InfoText title='Zone' content={zoneCode} />
                </ColNoPadding>
                <ColNoPadding>
                    {cacType == CAC_TYPE.NURSERY && (
                        <InfoText title='Line' content={contractHarvest} />
                    )}
                </ColNoPadding>
                <ColNoPadding>
                    <Coordinates techFormPk={techFormPk} coordinates={coordinates} />
                </ColNoPadding>
            </Row>
            <Row>
                <ColNoPadding>
                    <InfoText title='Address' content={productionArea} />
                </ColNoPadding>
            </Row>
        </>
    );
};

const StyledListItem = styled(ListItem)`
    p {
        margin-bottom: 0;
    }
`;

const TitleRow = styled(Row)`
    width: 100%;
    justify-content: space-between;
`;
