import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import styled from 'styled-components';
import themebutton from '../res/styles/theme_button.module.scss';
import Button from './Button';
import { CONFIRM, CANCEL } from '../res/Constants';

export default class ConfirmDialog extends PureComponent {
    static propTypes = {
        isOpen: PropTypes.bool,
        text: PropTypes.string,
        elementId: PropTypes.number.isRequired,
        onConfirm: PropTypes.func
    };

    onConfirm = (confirm) => {
        this.props.onConfirm && this.props.onConfirm(this.props.elementId, confirm);
    };

    render() {
        return (
            <Modal isOpen={this.props.isOpen} centered={true}>
                <ModalBody>
                    <p style={{ height: '70px', textAlign: 'center' }}>{this.props.text}</p>
                    <StyledButtonsFooter>
                        <Button
                            className={[themebutton.red, 'w-40', 'mx-auto'].join(' ')}
                            onClick={() => this.onConfirm(false)}
                            text={CANCEL.toUpperCase()}
                        />
                        <Button
                            className={[themebutton.green, 'w-40', 'mx-auto'].join(' ')}
                            onClick={() => this.onConfirm(true)}
                            text={CONFIRM.toUpperCase()}
                        />
                    </StyledButtonsFooter>
                </ModalBody>
            </Modal>
        );
    }
}

const StyledButtonsFooter = styled.div`
    display: flex;
    justify-content: space-evenly;
`;
