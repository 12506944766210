import React from 'react';
import styled from 'styled-components';
import dimension from '../res/styles/Responsive';
import { randomId } from '../utils/Utils';
import colors from '../res/styles/Colors';
import { FieldTitle, VerticalDiv } from './Common';
import InfoIcon from './InfoIcon';



const InfoText = ({ className = '', title, id, content, isPopover }) => {
    const customId = id || randomId();

    const contentData = (
        <>
            {isPopover ? (
                <InfoIcon content={content} id={customId} />
            ) : (
                <StyledInfoContent>{content}</StyledInfoContent>
            )}
        </>
    );
    return (
        <>
            {title ? (
                <StyledVerticalDiv className={className}>
                    <FieldTitle>{title}</FieldTitle>
                    {contentData}
                </StyledVerticalDiv>
            ) : (
                <>{contentData}</>
            )}
        </>
    );
};

export default InfoText;

const StyledVerticalDiv = styled(VerticalDiv)`
    @media ${dimension.md} {
        width: inherit;
    }
`;

const StyledInfoContent = styled.p.attrs({
    className: 'info-text-content'
})`
    font-size: 12px;
    color: ${colors.darker_gray};
`;
