import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { isMobileOnly } from 'react-device-detect';
import styled from 'styled-components';
import colors from '../res/styles/Colors';
import dimension from '../res/styles/Responsive';
import { CellItemContainer, EmptyDiv, HorizontalDiv } from './Common';
import IconButton from './IconButton';

const selectIcon = require('../res/images/cac_select.png');

export default class ListItem extends PureComponent {
    static propTypes = {
        className: PropTypes.string,
        isExpanded: PropTypes.bool,
        isSelected: PropTypes.bool,
        isSelectable: PropTypes.bool,
        expandableChildren: PropTypes.object,
        onSelect: PropTypes.func
    };

    static defaultProps = {
        isSelected: false,
        isSelectable: false
    };

    state = {
        isExpanded: this.props.isExpanded,
        isSelected: this.props.isSelected
    };

    toggleExpand = () => {
        this.setState((state) => ({
            isExpanded: !state.isExpanded
        }));
    };

    toggleSelection = () => {
        if (this.props.onSelect != null) {
            this.props.onSelect(!this.state.isSelected);
        }
    };

    render() {
        const renderProps = {
            toggleExpand: this.toggleExpand,
            isExpanded: this.state.isExpanded
        };

        const children = (
            <CellItemInner>{this.props.children && this.props.children(renderProps)}</CellItemInner>
        );

        return (
            <CellItemContainer className={this.props.className}>
                {this.props.isSelectable ? (
                    <StyledHorizontalDiv>
                        {this.props.isSelected && <StyledSelectionIndicator />}
                        <StyledIconButton src={selectIcon} onClick={this.toggleSelection} />
                        <div style={{ width: '100%' }}>{children}</div>
                    </StyledHorizontalDiv>
                ) : (
                    <>{children}</>
                )}
                {this.state.isExpanded && this.props.expandableChildren && (
                    <StyledHorizontalDiv>
                        {!isMobileOnly && this.props.isSelected && <StyledSelectionIndicator />}
                        <div style={{ width: '100%' }}>
                            <CellItemExpanded>{this.props.expandableChildren}</CellItemExpanded>
                        </div>
                    </StyledHorizontalDiv>
                )}
            </CellItemContainer>
        );
    }
}

const StyledIconButton = styled(IconButton)`
    margin: 3px;
`;

const StyledHorizontalDiv = styled(HorizontalDiv)`
    background-color: ${(props) => props.backgroundColor || 'white'};
`;

const StyledSelectionIndicator = styled(EmptyDiv).attrs({
    className: 'selector'
})`
    width: 10px;
    flex-shrink: 0;
    background-color: ${colors.green};

    @media ${dimension.md} {
        width: 12px;
    }
`;

const CellItemInner = styled.div.attrs({
    className: 'inner-container'
})`
    padding: 15px;
    background-color: ${(props) => props.backgroundColor || 'white'};

    & > *:not(:last-child) {
        margin-bottom: 10px;
    }
`;

export const CellItemExpanded = styled(CellItemInner).attrs({
    className: 'inner-container-expandable'
})`
    background-color: ${({ backgroundColor }) => backgroundColor || colors.lighter_green};

    @media ${dimension.md} {
        padding-top: 0;
        background-color: white;
    }
`;
