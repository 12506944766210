import PropTypes from 'prop-types';
import React, { Component } from 'react';
import SimpleButtonDropdown from '../../../components/SimpleButtonDropdown';

class ButtonDropDown extends Component {
    static propTypes = {
        values: PropTypes.arrayOf(PropTypes.object).isRequired,
        defaultValue: PropTypes.string.isRequired,
        currentValue: PropTypes.string,
        className: PropTypes.string,
        hasEmptyValue: PropTypes.bool,
        keys: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
        onChangeValue: PropTypes.func
    };

    static defaultProps = {
        hasEmptyValue: true,
        onChangeValue: () => {}
    };

    state = { dropdownOpen: false, selectedValue: '' };

    onDropdownItemClick = (index) => {
        if (this.props.onChangeValue) {
            if (index == null) {
                this.props.onChangeValue(null);
                this.setState({ selectedValue: null });
            } else {
                const { value, name } = this.props.values[index];
                const newValue = name ?? value;
                this.setState({ selectedValue: newValue });
                this.props.onChangeValue(newValue);
            }
        }
    };

    render() {
        const values = this.props.values.map((e) => e.name);
        const text = this.state.selectedValue || this.props.defaultValue;

        return (
            <SimpleButtonDropdown
                text={text}
                values={values}
                keys={this.props.keys}
                className={this.props.className}
                isScrollable={true}
                hasEmptyValue={this.props.hasEmptyValue}
                onChangeValue={(index) => this.onDropdownItemClick(index)}
            />
        );
    }
}

export default ButtonDropDown;
