import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import * as animations from 'react-animations';
import styled, { css, keyframes } from 'styled-components';

ToggleableAnimation.propTypes = {
    animationName: PropTypes.string.isRequired,
    duration: PropTypes.number,
    shouldAnimate: PropTypes.bool,
    shouldAnimateOnce: PropTypes.bool
};

/**
 * @param {string} animationName from animate.css library
 */
function ToggleableAnimation({
    animationName,
    duration = 1,
    shouldAnimate,
    shouldAnimateOnce,
    children
}) {
    const [isAnimate, setIsAnimate] = useState(shouldAnimateOnce || shouldAnimate === true);

    useEffect(() => {
        if (shouldAnimateOnce) {
            // The timeout delay must be greater than the duration but it's required to be
            // the smallest number sufficient to finish the animation
            setTimeout(() => {
                setIsAnimate(false);
            }, duration * 1000 * 1.1);
        }
    }, [duration, shouldAnimateOnce]);

    return (
        <>
            {isAnimate ? (
                <AnimatedDiv animationName={animationName} duration={duration}>
                    {children}
                </AnimatedDiv>
            ) : (
                <div>{children}</div>
            )}
        </>
    );
}

const animationKeyframe = (animationName) => keyframes`${animationName}`;
const animationCSS = (props) =>
    css`
        ${animationKeyframe(animations[props.animationName])} ${props.duration}s;
    `;

const AnimatedDiv = styled.div`
    animation: ${animationCSS};
`;

export default ToggleableAnimation;
