import { PropTypes } from 'prop-types';
import React, { PureComponent } from 'react';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Nav } from 'reactstrap';
import styled from 'styled-components';
import colors from './../res/styles/Colors';

export default class SimpleButtonDropdown extends PureComponent {
    static propTypes = {
        values: PropTypes.arrayOf(PropTypes.string).isRequired,
        onChangeValue: PropTypes.func,
        className: PropTypes.string,
        text: PropTypes.string,
        isScrollable: PropTypes.bool,
        hasEmptyValue: PropTypes.bool,
        keys: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string]))
    };

    static defaultProps = {
        isScrollable: false,
        hasEmptyValue: false,
        keys: []
    };

    state = { dropdownOpen: false };

    toggle = () => {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        });
    };

    handleDropdownItemClick = (index) => {
        if (this.props.onChangeValue) {
            const sanitizedIndex = this.props.hasEmptyValue ? index - 1 : index;
            const selectedIndex = sanitizedIndex === -1 ? null : sanitizedIndex;
            const selectedValue = this.props.values[selectedIndex];
            this.props.onChangeValue(selectedIndex, selectedValue);
        }
    };

    render() {
        const values = this.props.hasEmptyValue ? ['', ...this.props.values] : this.props.values;

        return (
            <StyledNav className={['button-dropdown', this.props.className].join(' ')}>
                <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
                    <DropdownToggle nav caret>
                        {this.props.text}
                    </DropdownToggle>
                    {/* .toString() required by React */}
                    {this.props.values && (
                        <StyledDropdownMenu scrollable={this.props.isScrollable.toString()}>
                            {values.map((item, index) => (
                                <StyledDropdownItem
                                    key={this.props.keys[index] ?? item}
                                    onClick={() => this.handleDropdownItemClick(index)}>
                                    {item}
                                </StyledDropdownItem>
                            ))}
                        </StyledDropdownMenu>
                    )}
                </Dropdown>
            </StyledNav>
        );
    }
}

const StyledNav = styled(Nav)`
    border-radius: 50px;

    .dropdown {
        width: 100%;
        text-align: center;
    }

    .dropdown-toggle::after {
        position: absolute;
        right: 13px;
        top: 50%;
        margin-top: -3px;
    }

    .dropdown a,
    .nav-item a,
    .nav-link a {
        background-color: inherit;
        color: inherit;
        font-size: 12px;
    }

    .dropdown-item:hover {
        background-color: ${colors.light_red};
    }

    button:focus {
        outline: none;
    }

    .nav-link.active {
        background-color: ${colors.lighter_green};
        color: $green;
        border-radius: 50px;
    }

    p {
        font-size: 14px;
        margin: auto;
    }
`;

const StyledDropdownMenu = styled(DropdownMenu)`
    &.show {
        width: 100%;
        border-radius: 5px;

        ${({ scrollable }) =>
            scrollable &&
            `
            max-height: 300px;
            overflow-y: scroll; 
            `}
    }
`;

const StyledDropdownItem = styled(DropdownItem)`
    height: 30px;
    padding: 5px 15px;
    font-size: 14px;
`;
