import axios from 'axios';
import * as _ from 'lodash';
import moment from 'moment';
import { MOCKED_DATA } from '../debug/Debug';
import {
    ExtraReportCharacterNames,
    FloweringDatesCharacterNames,
    HTTP_CODE,
    U
} from '../res/Constants';
import { getCurrentDateLong } from '../utils/DateFormatter';

let mockedCrAlert;

if (process.env.NODE_ENV === 'development' && MOCKED_DATA) {
    mockedCrAlert = require('../debug/TechFormDetailMockResponse.json');
}

const GET_TECHFORM_DETAIL = (techformId) => `/techforms/${techformId}`;
const SAVE_TECHFORM_FLOWERING = '/techforms/characters/flowering';
const SAVE_TECHFORM_METADATA = '/techforms/characters/metadata';
const SAVE_TECHFORM_NOTES = '/techforms/characters/notes';
const SAVE_CR_ALERT = '/report/extra';

/**
 * @param {number} techformId
 * @param {function} callback
 */
export function getTechFormDetail(techformId, callback) {
    if (MOCKED_DATA) {
        callback(processTechFormDetail(mockedCrAlert));
        return;
    }

    axios({ method: 'get', url: GET_TECHFORM_DETAIL(techformId) })
        .then((result) => {
            callback(processTechFormDetail(result.data));
        })
        .catch((error) => {
            callback(processTechFormDetail(null));
            console.log(error);
        });
}

function processTechFormDetail(data) {
    if(data != null){
        const crAlertViewModels = data.extraReportList.map((value) =>
            buildCrAlertViewModel(data, value)
        );
        return { ...data, extraReportList: crAlertViewModels };
    }
    return null;
}

function buildCrAlertViewModel(reportData, crAlert) {
    const {
        speciesCode,
        varietyCode,
        orderNumber,
        contractHarvest,
        contractOrganic,
        contractTunnel
    } = reportData;
    const growerName = reportData.growerName.replace(' ', '_');
    const varietyName = crAlert.varietyName.replace(' ', '_');
    const crAlertDate = moment(crAlert.date).format('YYYYMMDD');
    const title = [
        orderNumber,
        speciesCode + varietyCode,
        orderNumber,
        growerName,
        varietyName,
        crAlertDate
    ].join('-');

    const harvestType = contractHarvest;
    const bioConv = contractOrganic;
    const pt = contractTunnel;

    return { ...crAlert, title, harvestType, bioConv, pt };
}

/**
 * @param {object} crAlertViewModel
 * @param {function} callback
 */
export function updateCrAlert(crAlertViewModel, callback) {
    const crAlert = buildCrAlert(crAlertViewModel);
    axios({
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        url: SAVE_CR_ALERT,
        data: crAlert
    })
        .then((result) => {
            callback(result.data);
        })
        .catch((error) => {
            console.log(error);
        });
}

const defaultExtraReportCharacters = Object.freeze({
    [ExtraReportCharacterNames.NOTES]: {
        name: ExtraReportCharacterNames.NOTES,
        value: ''
    },
    [ExtraReportCharacterNames.DESTROYED_REASON]: {
        name: ExtraReportCharacterNames.DESTROYED_REASON,
        value: ''
    },
    [ExtraReportCharacterNames.NET_HA]: {
        name: ExtraReportCharacterNames.NET_HA,
        value: 0
    },
    [ExtraReportCharacterNames.DESTROYED_HA]: {
        name: ExtraReportCharacterNames.DESTROYED_HA,
        value: 0
    },
    [ExtraReportCharacterNames.INDEMNITY]: {
        name: ExtraReportCharacterNames.INDEMNITY,
        value: 'false'
    },
    [ExtraReportCharacterNames.INFORMED_CUSTOMER]: {
        name: ExtraReportCharacterNames.INFORMED_CUSTOMER,
        value: 'false'
    }
});

function buildCrAlert(viewModel) {
    const { pk, username, techFormPk, date } = viewModel;
    const characters = _.merge(defaultExtraReportCharacters, viewModel.extraReportCharacters);

    return {
        pk,
        date,
        user: { username },
        techForm: { pk: techFormPk },
        extraReportCharacters: _.values(characters)
    };
}

export function extractExtraReportCharacters(extraReportCharacters) {
    const {
        NET_HA,
        DESTROYED_HA,
        DESTROYED_REASON,
        INFORMED_CUSTOMER,
        INDEMNITY,
        NOTES
    } = ExtraReportCharacterNames;

    return {
        netHa: extraReportCharacters[NET_HA] || {},
        destroyedHa: extraReportCharacters[DESTROYED_HA] || {},
        destroyedReason: extraReportCharacters[DESTROYED_REASON] || {},
        informedCustomer: extraReportCharacters[INFORMED_CUSTOMER] || {},
        indemnity: extraReportCharacters[INDEMNITY] || {},
        notes: extraReportCharacters[NOTES] || {}
    };
}

/**
 * @param {Object[]} characters
 */
export function updateTechFormCharacters(characters, callback) {
    // converts to the data model required by the server
    const sanitizedCharacters = characters.map((obj) => ({
        ...obj,
        id: undefined,
        name: FloweringDatesCharacterNames[obj.id],
        value: obj.date,
        date: getCurrentDateLong()
    }));
    axios({
        method: 'post',
        url: SAVE_TECHFORM_FLOWERING,
        data: sanitizedCharacters
    })
        .then((result) => {
            callback && callback(result);
        })
        .catch((error) => {});
}

/**
 * @param {string} coordinates JSON string
 * @param {number} techFormPk
 * @param {function(Boolean)} callback
 */
export function updateTechFormCoordinates(coordinates, techFormPk, callback) {
    const data = {
        techFormPk,
        name: 'Metadata',
        value: JSON.stringify(coordinates),
        line: U,
        date: getCurrentDateLong()
    };
    axios({
        method: 'post',
        url: SAVE_TECHFORM_METADATA,
        data
    })
        .then((result) => {
            callback && callback(result.status === HTTP_CODE.OK);
        })
        .catch(() => {
            if (callback != null) {
                callback(false);
            }
        });
}

/**
 * @param {string} notes
 * @param {number} techFormPk
 * @param {function(Boolean)} callback
 */
export function updateTechFormNotes(notes, techFormPk, callback) {
    const data = {
        techFormPk,
        name: 'Notes',
        value: notes,
        line: U,
        date: getCurrentDateLong()
    };

    axios({
        method: 'post',
        url: SAVE_TECHFORM_NOTES,
        data
    })
        .then((result) => {
            callback && callback(result.status === HTTP_CODE.OK);
        })
        .catch(() => {
            if (callback != null) {
                callback(false);
            }
        });
}
