import PropTypes from 'prop-types';
import React, { Component } from 'react';
import SimpleInputDropdown from '../../../components/SimpleInputDropdown';

export default class InputDropDown extends Component {
    static propTypes = {
        values: PropTypes.arrayOf(
            PropTypes.shape({
                label: PropTypes.string,
                key: PropTypes.string
            })
        ).isRequired,
        defaultValue: PropTypes.string.isRequired,
        currentValue: PropTypes.string,
        className: PropTypes.string,
        hasEmptyValue: PropTypes.bool,
        onChangeValue: PropTypes.func
    };

    static defaultProps = {
        hasEmptyValue: true,
        onChangeValue: () => {}
    };

    state = { selectedValue: '' };

    onDropdownItemClick = (index) => {
        if (this.props.onChangeValue) {
            if (index == null) {
                this.props.onChangeValue(null);
                this.setState({ selectedValue: null });
            } else {
                const { value, name } = this.props.values[index];
                const newValue = name ?? value;
                this.setState({ selectedValue: newValue });
                this.props.onChangeValue(newValue);
            }
        }
    };

    render() {
        const options = this.props.values.map((e) => ({ label: e.name, key: e.name }));
        const text = this.state.selectedValue || this.props.defaultValue;

        return (
            <SimpleInputDropdown
                text={text}
                options={options}
                className={this.props.className}
                isScrollable={true}
                isInput={true}
                hasEmptyValue={this.props.hasEmptyValue}
                onChangeValue={(index) => this.onDropdownItemClick(index)}>
                {this.props.children}
            </SimpleInputDropdown>
        );
    }
}
