import PropTypes from 'prop-types';
import React from 'react';
import onClickOutside from 'react-onclickoutside';
import { PopoverBody, PopoverHeader, UncontrolledPopover } from 'reactstrap';
import styled from 'styled-components';
import colors from '../res/styles/Colors';

PopoverMenu.propTypes = {
    className: PropTypes.string,
    isOpen: PropTypes.bool.isRequired,
    target: PropTypes.string.isRequired,
    header: PropTypes.string,
    body: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            onClick: PropTypes.func.isRequired
        })
    ),
    onToggle: PropTypes.func.isRequired,
    onClickOutside: PropTypes.func
};

PopoverMenu.defaultTypes = {
    isOpen: false
};

export default onClickOutside(PopoverMenu, {
    handleClickOutside: () => PopoverMenu.handleClickOutside
});

function PopoverMenu(props) {
    PopoverMenu.handleClickOutside = () => {
        props.onToggle();
    };

    return (
        <UncontrolledPopover
            className={props.className}
            isOpen={props.isOpen}
            placement={'bottom'}
            trigger='legacy'
            target={props.target}
            toggle={props.onToggle}>
            {props.header && (
                <PopoverHeader style={{ textAlign: 'center' }}>{props.header}</PopoverHeader>
            )}
            {props.body && (
                <PopoverBody>
                    {props.body.map((bodyItem, index) => (
                        <PopoverButtonItem key={index} onClick={bodyItem.onClick}>
                            {bodyItem.label}
                        </PopoverButtonItem>
                    ))}
                </PopoverBody>
            )}
            {props.children && <PopoverBody>{props.children}</PopoverBody>}
        </UncontrolledPopover>
    );
}

export const PopoverButtonItem = styled.button.attrs({
    className: 'popup-button'
})`
    display: flex;
    justify-content: center;
    width: 100%;
    height: 32px;
    padding: 1px 6px;
    margin: auto 0;
    color: inherit;
    text-decoration: none;

    &:focus {
        background-color: ${colors.light_red};
    }
    &:hover {
        background-color: ${colors.light_red};
        color: inherit;
        text-decoration: none;
    }

    a {
        margin: auto 0;
        color: inherit;
        text-decoration: none;
    }
`;
