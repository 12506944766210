import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Container, Row } from 'reactstrap';
import styled from 'styled-components';
import { AppContext } from '../../../../App';
import { ColNoPadding, VerticalDiv } from '../../../../components/Common';
import {
    CAC_TYPE,
    FLOWERING_DATES,
    ROGUING_REMARKS,
    SOWING,
    SOWING_INFO
} from './../../../../res/Constants';
import BasicInfo from './sections/BasicInfo';
import FloweringDates from './sections/FloweringDates';
import GeneralRemarks from './sections/GeneralRemarks';
import Roguing from './sections/Roguing';
import SowingInfo from './sections/SowingInfo';
import SowingTransplating from './sections/SowingTransplating';

export default class Growing extends PureComponent {
    static propTypes = {
        techFormData: PropTypes.object
    };

    render() {
        return (
            <AppContext.Consumer>
                {(appContext) => (
                    <div className='list-container'>
                        {this.props.techFormData && (
                            <>
                                <BasicInfo
                                    cacType={appContext.cacType}
                                    techFormData={this.props.techFormData}
                                    className='item'
                                />
                                <Container fluid style={{ padding: '0' }}>
                                    <Row>
                                        <VerticalDiv className='d-none d-md-flex'>
                                            {appContext.cacType === CAC_TYPE.CROP ? (
                                                <>
                                                    <h4>{SOWING}</h4>
                                                    <h6>Transplant info</h6>
                                                </>
                                            ) : (
                                                <h4>{SOWING_INFO}</h4>
                                            )}
                                        </VerticalDiv>
                                    </Row>
                                    <Row>
                                        <ColNoPadding className='item'>
                                            {appContext.cacType === CAC_TYPE.CROP ? (
                                                <SowingTransplating
                                                    techFormData={this.props.techFormData}
                                                />
                                            ) : (
                                                <ColNoPadding md='5'>
                                                    <SowingInfo
                                                        techFormData={this.props.techFormData}
                                                    />
                                                </ColNoPadding>
                                            )}
                                        </ColNoPadding>
                                        <ColNoPadding md='4' className='d-none d-md-flex'>
                                            <StyledGeneralRemarks
                                                techFormData={this.props.techFormData}
                                            />
                                        </ColNoPadding>
                                    </Row>
                                    <Row>
                                        <ColNoPadding className='item'>
                                            <h4 className='d-none d-md-block'>{ROGUING_REMARKS}</h4>
                                            <Roguing
                                                techFormData={this.props.techFormData}
                                                cacType={appContext.cacType}
                                            />
                                        </ColNoPadding>
                                        <ColNoPadding md='4' />
                                    </Row>
                                    {appContext.cacType === CAC_TYPE.CROP && (
                                        <Row>
                                            <ColNoPadding className='item'>
                                                <h4 className='d-none d-md-block'>
                                                    {FLOWERING_DATES}
                                                </h4>
                                                <FloweringDates
                                                    techFormData={this.props.techFormData}
                                                    cacType={appContext.cacType}
                                                />
                                            </ColNoPadding>
                                            <ColNoPadding md='4' />
                                        </Row>
                                    )}
                                    <Row className='d-md-none'>
                                        <StyledGeneralRemarks
                                            techFormData={this.props.techFormData}
                                        />
                                    </Row>
                                </Container>
                            </>
                        )}
                    </div>
                )}
            </AppContext.Consumer>
        );
    }
}

const StyledGeneralRemarks = styled(GeneralRemarks)`
    width: 100%;
`;
