import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Row } from 'reactstrap';
import styled from 'styled-components';
import dimension from '../../../../res/styles/Responsive';
import themeButton from '../../../../res/styles/theme_button.module.scss';
import { calculateFinalHa } from '../../../../service/CommonFunctions';
import { extractExtraReportCharacters } from '../../../../service/TechFormDetailService';
import Checkbox from './../../../../components/Checkbox';
import { ColNoPadding } from './../../../../components/Common';
import InfoText from './../../../../components/InfoText';
import { ExtraReportCharacterNames } from './../../../../res/Constants';
import CrAlertPrinter from './CrAlertPrinter';
import EditableInfoText from './EditableInfoText';

export default class CrAlertItemDetail extends PureComponent {
    static whyDidYouRender = true;
    static propTypes = {
        className: PropTypes.string,
        isEditable: PropTypes.bool,
        onToggleEdit: PropTypes.func,
        onEditingCompleted: PropTypes.func,
        onFieldEdited: PropTypes.func,
        data: PropTypes.object
    };

    static defaultProps = {
        className: '',
        data: {}
    };

    render() {
        const {
            netHa,
            destroyedHa,
            destroyedReason,
            informedCustomer,
            indemnity,
            notes
        } = extractExtraReportCharacters(this.props.data.extraReportCharacters);
        const finalHa = calculateFinalHa(netHa.value, destroyedHa.value).toString();

        return (
            <StyledCrAlertContainer className={this.props.className}>
                <Row>
                    <ColNoPadding>
                        <SmallEditableInfoText
                            type='number'
                            title='Net Ha'
                            initialValue={netHa.value}
                            isEditable={this.props.isEditable}
                            fieldId={ExtraReportCharacterNames.NET_HA}
                            onFieldEdited={this.props.onFieldEdited}
                        />
                    </ColNoPadding>
                    <ColNoPadding>
                        <SmallEditableInfoText
                            type='number'
                            title='Destroyed Ha'
                            initialValue={destroyedHa.value}
                            isEditable={this.props.isEditable}
                            fieldId={ExtraReportCharacterNames.DESTROYED_HA}
                            onFieldEdited={this.props.onFieldEdited}
                        />
                    </ColNoPadding>
                    <ColNoPadding>
                        <InfoText title='Final Ha' content={finalHa} />
                    </ColNoPadding>
                    <ColNoPadding className='d-none d-md-block'>
                        <DestroyedReasonEditableInfoText
                            isEditable={this.props.isEditable}
                            destroyedReason={destroyedReason.value}
                            onFieldEdited={this.props.onFieldEdited}
                        />
                    </ColNoPadding>
                </Row>
                <Row className='d-md-none'>
                    <DestroyedReasonEditableInfoText
                        isEditable={this.props.isEditable}
                        destroyedReason={destroyedReason.value}
                        onFieldEdited={this.props.onFieldEdited}
                    />
                </Row>
                <Row>
                    <ColNoPadding>
                        <StyledCheckbox
                            text='Customer has been informed?'
                            isHorizontal={false}
                            isSelected={informedCustomer.value === 'true'}
                            customId={ExtraReportCharacterNames.INFORMED_CUSTOMER}
                            isEditable={this.props.isEditable}
                            onChange={this.props.onFieldEdited}
                        />
                    </ColNoPadding>
                    <ColNoPadding>
                        <StyledCheckbox
                            text='Indemnity'
                            isHorizontal={false}
                            isSelected={indemnity.value === 'true'}
                            customId={ExtraReportCharacterNames.INDEMNITY}
                            isEditable={this.props.isEditable}
                            onChange={this.props.onFieldEdited}
                        />
                    </ColNoPadding>
                </Row>
                {(notes || this.props.isEditable) && (
                    <Row>
                        <ColNoPadding>
                            <EditableInfoText
                                isEditable={this.props.isEditable}
                                onFieldEdited={this.props.onFieldEdited}
                                title='Notes'
                                fieldId={ExtraReportCharacterNames.NOTES}
                                initialValue={notes.value}
                                rows={3}
                            />
                        </ColNoPadding>
                    </Row>
                )}
                <FooterRow>
                    <ColNoPadding>
                        <button className={themeButton.red} onClick={this.props.toggleEdit}>
                            {this.props.isEditable ? 'CANCEL' : 'EDIT'}
                        </button>
                    </ColNoPadding>
                    {this.props.isEditable ? (
                        <ColNoPadding>
                            <button
                                className={themeButton.green}
                                onClick={this.props.onEditingCompleted}>
                                OK
                            </button>
                        </ColNoPadding>
                    ) : (
                        <ColNoPadding>
                            {/* Avoid to create a Printable CR-Alert if the Cr-Alert hasn't been saved to the server*/}
                            {this.props.data.pk != null && (
                                <CrAlertPrinter crAlert={this.props.data}>
                                    <button className={themeButton.textGreen}>
                                        <StyledImg
                                            src={require('../../../../res/images/cac_print_black.png')}
                                            alt='print button'
                                        />
                                        PRINT
                                    </button>
                                </CrAlertPrinter>
                            )}
                        </ColNoPadding>
                    )}
                </FooterRow>
            </StyledCrAlertContainer>
        );
    }
}

const DestroyedReasonEditableInfoText = ({
    destroyedReason,
    onFieldEdited,
    isEditable,
    ...props
}) => {
    return (
        <EditableInfoText
            {...props}
            isEditable={isEditable}
            title='Destruction Explanation'
            fieldId={ExtraReportCharacterNames.DESTROYED_REASON}
            initialValue={destroyedReason}
            onFieldEdited={onFieldEdited}
        />
    );
};

const SmallEditableInfoText = styled(EditableInfoText)`
    &.editable input {
        width: 70px;
    }
`;

const StyledCrAlertContainer = styled.div`
    & > *:not(:last-child) {
        margin-bottom: 10px;
    }
`;

const StyledImg = styled.img`
    width: 20px;
    margin-right: 10px;
`;

const StyledCheckbox = styled(Checkbox).attrs({
    className: 'flex-column'
})`
    margin: inherit;
    font-size: 12px;

    @media ${dimension.md} {
        font-size: 13px;
    }
`;

const FooterRow = styled(Row)`
    justify-content: space-between;

    .col {
        text-align: center;
    }

    button {
        width: 80%;

        @media ${dimension.md} {
            width: 150px;
            margin: 0;
        }
    }
`;
