import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import { QueryCache, ReactQueryCacheProvider } from 'react-query';
import { Redirect, Route, Router, Switch, withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import createPersistedState from 'use-persisted-state';
import whyDidYouRender from './debug/Debug';
import AddUser from './pages/ControlPanel/AddUser';
import AlertParameters from './pages/ControlPanel/AlertParameters';
import ControlPanel from './pages/ControlPanel/ControlPanel';
import CrAlertEmails from './pages/ControlPanel/CrAlertEmails';
import CustomerReportDates from './pages/ControlPanel/CustomerReportDates';
import CustomerView from './pages/ControlPanel/CustomerView';
import ManageUsers from './pages/ControlPanel/ManageUsers';
import SendPushMessages from './pages/ControlPanel/SendPushMessages';
import ErrorPage from './pages/ErrorPage/ErrorPage';
import GenericCrAlertList from './pages/GenericCrAlertList/GenericCrAlertList';
import Login from './pages/login/Login';
import TechFormDetail from './pages/TechFormDetail/TechFormDetail';
import TechFormList from './pages/TechFormList/TechFormList';
import VisitEditing from './pages/Visits/Editing/VisitEditing';
import VisitList from './pages/Visits/List/VisitList';
import { CAC_TYPE, HTTP_CODE, SERVER_CODE_MAPPER } from './res/Constants';
import './res/styles/common.scss';
import './res/styles/util_classes.scss';
import { isCurrentUserAdmin, isUserLogged, logout } from './service/AccountService';
import './service/AxiosConfig';
import { CAC_TYPE_STORAGE } from './service/CacTypeService';
import { isProduction } from './service/InfoService';
import { toastError } from './ui/ToastHelper';

if (!isProduction) {
    whyDidYouRender(React);
}
toast.configure();

const userPersistedCacType = createPersistedState(CAC_TYPE_STORAGE);
const queryCache = new QueryCache();

const AppContext = React.createContext();
export { AppContext };

export const DEFAULT_FILTERS_BY_USER = {
    crop: null,
    nursery: null
};

function App(props) {
    const [cacType, setCacType] = userPersistedCacType(CAC_TYPE.CROP);
    const [filtersByUser, setFiltersByUser] = React.useState(DEFAULT_FILTERS_BY_USER);

    axios.interceptors.response.use(
        (response) => response,
        (error) => {
            const errorStatus =
                error.response == null ? HTTP_CODE.SERVER_ERROR : error.response.status;

            switch (errorStatus) {
                case HTTP_CODE.UNAUTHORIZED:
                    logout();
                    clearCache();
                    props.history.push('/login');
                    toastError(getErrorText(error.response.status));
                    break;
                case HTTP_CODE.SERVER_ERROR:
                case HTTP_CODE.NOT_FOUND:
                    if (!window.location.href.includes('/error')) {
                        props.history.push({
                            pathname: '/error',
                            state: {
                                errorCode: errorStatus,
                                errorText: getErrorText(HTTP_CODE.SERVER_ERROR)
                            }
                        });
                    }
                    break;
                default:
                    toastError(getErrorText(error.response.status));
                    break;
            }
            return Promise.reject(error);
        }
    );

    function getNavigationTarget() {
        if (cacType === CAC_TYPE.VISITS) {
            return '/visits';
        } else if (cacType === CAC_TYPE.CROP || cacType === CAC_TYPE.NURSERY) {
            return '/techforms';
        }
    }

    React.useEffect(() => {
        // navigate to the right section when the cacType changes
        const navigation = getNavigationTarget();
        if (!window.location.href.includes(navigation)) {
            props.history.push(navigation);
        }
    }, [cacType]);

    const onCacTypeChange = (type) => {
        if (type != null) {
            setCacType(type);
        }
    };

    const clearCache = () => {
        queryCache.clear();
    };

    return (
        <div className='App'>
            <ReactQueryCacheProvider queryCache={queryCache}>
                <AppContext.Provider
                    value={{
                        cacType,
                        onCacTypeChange,
                        filtersByUser,
                        setFiltersByUser,
                        clearCache
                    }}>
                    <Router history={props.history}>
                        <Switch>
                            <OnlyNotLoggedRoute exact path='/' component={Login} />
                            <Route exact path='/login' render={() => <Redirect to='/' />} />
                            <Route
                                exact
                                path='/nav'
                                render={() => <Redirect to={getNavigationTarget()} />}
                            />
                            <PrivateRoute exact path='/techforms' component={TechFormList} />
                            <PrivateRoute
                                exact
                                path='/techforms/:techformId'
                                component={TechFormDetail}
                            />
                            <PrivateRoute
                                exact
                                path='/reports/extra'
                                component={GenericCrAlertList}
                            />
                            <PrivateRoute exact path='/visits' component={VisitList} />
                            <PrivateRoute exact path='/visits/create' component={VisitEditing} />
                            <PrivateRoute
                                exact
                                path='/visits/edit/:visitId'
                                component={VisitEditing}
                            />
                            <AdminRoute exact path='/controlpanel' component={ControlPanel} />
                            <AdminRoute exact path='/adduser' component={AddUser} />
                            <AdminRoute exact path='/manageusers' component={ManageUsers} />
                            <AdminRoute exact path='/alertparameters' component={AlertParameters} />
                            <AdminRoute exact path='/customerview' component={CustomerView} />
                            <AdminRoute exact path='/reportdates' component={CustomerReportDates} />
                            <AdminRoute exact path='/pushmessages' component={SendPushMessages} />
                            <AdminRoute exact path='/alertemail' component={CrAlertEmails} />
                            <Route exact path='/error' component={ErrorPage} />
                            <Route component={ErrorPage} />
                        </Switch>
                    </Router>
                </AppContext.Provider>
            </ReactQueryCacheProvider>
        </div>
    );
}

const OnlyNotLoggedRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={(props) =>
            !isUserLogged() ? <Component {...props} /> : <Redirect to='/techforms' />
        }
    />
);

const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={(props) => (isUserLogged() ? <Component {...props} /> : <Redirect to='/' />)}
    />
);

const AdminRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={(props) => (isCurrentUserAdmin() ? <Component {...props} /> : <Redirect to='/' />)}
    />
);

const getErrorText = (errorCode) =>
    SERVER_CODE_MAPPER[errorCode] != null ? SERVER_CODE_MAPPER[errorCode] : `Error: ${errorCode}`;

export default withRouter(App);
