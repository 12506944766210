import React from 'react';
import styled from 'styled-components';
import { HorizontalDiv, InputDate } from '../Common';
import colors from './../../res/styles/Colors';
import dimension from './../../res/styles/Responsive';
import { getDateOrEmpty } from './../../utils/DateFormatter';
import LinePoint from './LinePoint';

export default function Date({
    className,
    date,
    content,
    hasLine = true,
    color = 'black',
    isEditable = false,
    id,
    onChange
}) {
    const formattedDate = getDateOrEmpty(date, 'DD/MMM/YYYY');

    return (
        <HorizontalDiv className={className}>
            {isEditable ? (
                <StyledInputDate
                    id={id}
                    color={color}
                    dateAsLong={date}
                    onChange={(event) => {
                        onChange && onChange(id, event.target.value);
                    }}
                />
            ) : (
                <StyledDate>{formattedDate}</StyledDate>
            )}
            <StyledLinePoint color={color} hasLine={hasLine} circlePosition='top' />
            <p style={{ fontWeight: 'bold' }}>{content}</p>
        </HorizontalDiv>
    );
}

const StyledDate = styled.p`
    flex-basis: 80px;
    flex-shrink: 0;
    color: ${colors.darker_gray};
    margin-bottom: 1rem;
`;

const StyledLinePoint = styled(LinePoint)`
    flex-shrink: 0;
    width: 30px;
    height: ${({ hasLine }) => (hasLine ? 'auto' : 'fit-content')};
`;

const StyledInputDate = styled(InputDate)`
    && {
        font-size: 12px;
        margin-bottom: 2rem;
        @media ${dimension.md} {
            width: 150px;
            height: 30px;
        }

        :focus {
            box-shadow: none;
            border-color: ${({ color }) => color};
        }
    }
`;
