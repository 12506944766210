import React, { PureComponent } from 'react';
import { Button, ButtonGroup } from 'reactstrap';
import styled from 'styled-components';

class ButtonRadio extends PureComponent {
    static defaultProps = {
        values: []
    };

    state = {
        indexSelected: this.props.defaultValueIndex
    };

    onRadioBtnClick = (index, value) => {
        this.setState(
            (prevState) => ({
                indexSelected: prevState.indexSelected === index ? null : index
            }),
            () => {
                if (this.props.onChangeValue) {
                    const resultValue = this.state.indexSelected == null ? null : value;
                    this.props.onChangeValue(resultValue);
                }
            }
        );
    };

    isActive(index) {
        return this.state.indexSelected === index;
    }

    render() {
        return (
            <StyledButtonGroup>
                {this.props.values.map((item, index) => (
                    <Button
                        key={item.name}
                        className={
                            this.isActive(index) ? this.props.onActiveStyle : this.props.className
                        }
                        onClick={() => this.onRadioBtnClick(index, item.name)}>
                        {item.name}
                    </Button>
                ))}
            </StyledButtonGroup>
        );
    }
}

const StyledButtonGroup = styled(ButtonGroup)`
    width: 100%;
`;

export default ButtonRadio;
