import PropTypes from 'prop-types';
import React from 'react';
import PermissionDialog from './PermissionDialog';

PermissionPane.propTypes = {
    zoneName: PropTypes.string,
    selectedSpecies: PropTypes.arrayOf(PropTypes.string),
    allSpecies: PropTypes.arrayOf(PropTypes.object),
    isOpen: PropTypes.bool,
    onPermissionsEditingFinished: PropTypes.func
};

PermissionPane.defaultProps = {
    selectedSpecies: [],
    allSpecies: [],
    isOpen: false
};

export default function PermissionPane({ zoneName, selectedSpecies, allSpecies, isOpen, onPermissionsEditingFinished }) {    
    const onConfirmPermissions = (selectedSpecies) => {
        onPermissionsEditingFinished(selectedSpecies);
    };

    const onCancel = () => {
        onPermissionsEditingFinished();
    };

    return (
        isOpen?
          <PermissionDialog
            key={zoneName}
            zoneName={zoneName}
            isOpen={isOpen}
            selectedSpecies={selectedSpecies}
            allSpecies={allSpecies}
            onConfirm={onConfirmPermissions}
            onCancel={onCancel}/>
        : null
    );
}
