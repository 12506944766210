import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { EmptyDiv, FieldTitle, HorizontalDiv, ImgButton } from '../../../../../components/Common';
import { canEditGrowing } from '../../../../../service/PermissionService';
import { updateTechFormCoordinates } from '../../../../../service/TechFormDetailService';
import EditableInfoText from '../../CrAlerts/EditableInfoText';

const saveIcon = require('../../../../../res/images/cac_save_icon.png');
const cancelIcon = require('../../../../../res/images/cac_cancel_icon.png');
const editIcon = require('../../../../../res/images/cac_edit_green.png');

Coordinates.propTypes = {
    techFormPk: PropTypes.number,
    coordinates: PropTypes.shape({
        latitude: PropTypes.number,
        longitude: PropTypes.number
    }),
    isEditable: PropTypes.bool
};

Coordinates.defaultProps = {
    isEditable: false
};

export default function Coordinates({ techFormPk, coordinates, isEditable }) {
    const initialCoords = { ...coordinates };
    const [isEditableState, setIsEditableState] = useState(isEditable);
    const [coords, setCoords] = useState(coordinates);
    const [latestSavedCoords, setLatestSavedCoords] = useState(initialCoords);

    useEffect(() => {
        setIsEditableState(isEditable);
    }, [isEditable]);

    const onSave = () => {
        setIsEditableState(false);
        updateTechFormCoordinates(coords, techFormPk, (success) => {
            if (success) {
                setLatestSavedCoords(coords);
            } else {
                setIsEditableState(true);
            }
        });
    };

    const onCancel = () => {
        setCoords(latestSavedCoords);
        setIsEditableState(false);
    };

    const { latitude, longitude } = coords;

    return (
        <>
            <HorizontalDiv>
                <FieldTitle>Geo Coord</FieldTitle>
                <EmptyDiv width='20px' height='20px' />
                {!isEditableState && canEditGrowing() && (
                    <ImgButton
                        src={editIcon}
                        alt='edit the coordinates'
                        onClick={() => setIsEditableState(true)}
                    />
                )}
            </HorizontalDiv>
            <HorizontalDiv>
                <StyledEditableInfoText
                    initialValue={latitude || '-'}
                    isEditable={isEditableState}
                    onFieldEdited={(_, value) => setCoords({ ...coords, latitude: value })}
                />
                <EmptyDiv width='10px' />
                <StyledEditableInfoText
                    initialValue={longitude || '-'}
                    isEditable={isEditableState}
                    onFieldEdited={(_, value) => setCoords({ ...coords, longitude: value })}
                />
            </HorizontalDiv>
            {isEditableState && (
                <ButtonsContainer>
                    <ImgButton src={saveIcon} alt='save the coordinates' onClick={onSave} />
                    <EmptyDiv width='40px' />
                    <ImgButton
                        src={cancelIcon}
                        alt='cancel the editing'
                        onClick={() => onCancel()}
                    />
                </ButtonsContainer>
            )}
        </>
    );
}

const ButtonsContainer = styled(HorizontalDiv)`
    margin-top: 15px;
    align-items: center;
    justify-content: center;
`;

const StyledEditableInfoText = styled(EditableInfoText).attrs({
    type: 'number'
})`
    font-size: 12px;
    width: 80px;
`;
