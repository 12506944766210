import React, { Component } from 'react';
import { Form } from 'react-final-form';
import { Field } from 'react-final-form-html5-validation';
import { Route, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { AppContext } from '../../App';
import Button from '../../components/Button';
import Checkbox from '../../components/Checkbox';
import SimpleFooter from '../../components/SimpleFooter';
import SimpleHeader from '../../components/SimpleHeader';
import { MOCKED_DATA } from '../../debug/Debug';
import { CAC_TYPE } from '../../res/Constants';
import { login } from '../../service/AccountService';
import { dismissToast } from '../../ui/ToastHelper';
import styles from './login.module.scss';
import './login.scss';

export default function Login(props) {
    const history = useHistory();
    const appContext = React.useContext(AppContext);
    const [rememberMe, setRememberMe] = React.useState(false);

    React.useEffect(() => {
        return () => {
            dismissToast();
        };
    }, []);

    const onRememberMeChange = () => {
        setRememberMe((curr) => !curr);
    };

    const onLogin = (event) => {
        const { username, password } = event;
        const formData = {
            username: username,
            password: password,
            'remember-me': rememberMe
        };
        login(formData, (result) => {
            if (result.status === 200) {
                appContext.onCacTypeChange(CAC_TYPE.CROP);
                history.push('/nav');
            } else {
                console.log('login failed');
            }
        });
    };

    return (
        <Route
            render={() => (
                <div className='h-100 login-page'>
                    <SimpleHeader />
                    <div className='row' id='body-container'>
                        <div className='col-12 col-sm-10 col-md-7 col-lg-4 col-xl-4 my-auto mx-auto'>
                            <div className='form-container'>
                                <h4>Enter your credentials</h4>
                                <Form
                                    onSubmit={onLogin}
                                    render={({ handleSubmit }) => (
                                        <form onSubmit={handleSubmit}>
                                            <LoginField
                                                name='username'
                                                type='text'
                                                placeholder='User'
                                                text='Username'
                                                autoComplete='current-password'
                                                required={!MOCKED_DATA}
                                                valueMissing={
                                                    MOCKED_DATA ? undefined : 'Empty username'
                                                }
                                            />
                                            <LoginField
                                                name='password'
                                                type='password'
                                                placeholder='Password'
                                                text='Password'
                                                autoComplete='current-password'
                                                required={!MOCKED_DATA}
                                                valueMissing={
                                                    MOCKED_DATA ? undefined : 'Empty password'
                                                }
                                            />
                                            <StyledCheckbox
                                                text='Remember access'
                                                isHorizontal={true}
                                                isSelected={rememberMe}
                                                onChange={onRememberMeChange}
                                            />
                                            <Button
                                                text='LOGIN'
                                                className={styles.loginBtn}
                                                type='submit'
                                            />
                                            <p>{rememberMe}</p>
                                        </form>
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                    <SimpleFooter {...props} />
                </div>
            )}
        />
    );
}
class LoginField extends Component {
    state = {
        isOnFocus: false
    };

    onFocus = () => {
        this.setState({ isOnFocus: true });
    };

    onBlur = () => {
        this.setState({ isOnFocus: false });
    };

    render() {
        return (
            <div className='field-container'>
                <label
                    style={
                        this.state.isOnFocus
                            ? { fontWeight: 'bold', color: 'green' }
                            : { fontWeight: 400, color: 'black' }
                    }>
                    {this.props.text}
                </label>
                <Field
                    component='input'
                    className='input-field'
                    onFocus={this.onFocus}
                    onBlur={this.onBlur}
                    {...this.props}
                />
            </div>
        );
    }
}

const StyledCheckbox = styled(Checkbox)`
    height: 50px;
    padding-left: 25px;
    width: 80%;
`;
