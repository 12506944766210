import PropTypes from 'prop-types';
import React, { Component } from 'react';
import onClickOutside from 'react-onclickoutside';
import { Popover, PopoverBody } from 'reactstrap';

class InfoIcon extends Component {
    static propTypes = {
        id: PropTypes.string.isRequired,
        image: PropTypes.string,
        alt: PropTypes.string,
        content: PropTypes.string,
        contents: PropTypes.arrayOf(PropTypes.object)
    };

    static defaultProps = {
        image: 'cac_info_icon.png'
    };

    state = {
        popoverOpen: false
    };

    toggle = (event) => {
        // this prevents the duplication of the event click due to the DOM
        event.stopPropagation();
        this.setState({
            popoverOpen: !this.state.popoverOpen
        });
    };

    handleClickOutside() {
        this.setState({ popoverOpen: false });
    }

    render() {
        return (
            <>
                <img
                    id={this.props.id}
                    onClick={this.toggle}
                    style={imgStyle}
                    src={require('../res/images/' + this.props.image)}
                    alt={this.props.alt}
                />
                <Popover
                    placement='bottom'
                    target={this.props.id}
                    trigger='hover focus legacy'
                    isOpen={this.state.popoverOpen}>
                    <PopoverBody>
                        {this.props.content && <p style={contentStyle}>{this.props.content}</p>}
                        {this.props.contents &&
                            this.props.contents.map((item, index) => (
                                <p key={item.title + index} style={contentStyle}>
                                    {item.title && <span style={titleStyle}>{item.title}: </span>}
                                    {item.desc}
                                </p>
                            ))}
                    </PopoverBody>
                </Popover>
            </>
        );
    }
}

const imgStyle = {
    width: '20px',
    height: '20px'
};

const titleStyle = {
    fontWeight: 'bold',
    fontSize: '12px',
    marginBottom: '5px'
};

const contentStyle = {
    fontSize: '12px',
    marginBottom: '5px'
};

export default onClickOutside(InfoIcon);
