import React from 'react';
import styled from 'styled-components';
import Dropdown from '../../components/Dropdown';
import LoadingWheel from '../../components/LoadingWheel';
import NavigationBar from '../../components/NavigationBar/NavigationBar';
import { CR_ALERTS } from '../../res/Constants';
import colors from '../../res/styles/Colors';
import { getYearsOfActivityFromCurrent } from '../../service/CommonFunctions';
import { getExtraReportsByYear } from '../../service/ExtraReportService';
import { updateCrAlert } from './../../service/TechFormDetailService';
import { toastInfo, toastSuccess } from './../../ui/ToastHelper';
import { getCurrentYear } from '../../utils/DateFormatter';
import { randomId } from '../../utils/Utils';
import CrAlertList from '../TechFormDetail/tabs/CrAlerts/CrAlertList';
import dimension from './../../res/styles/Responsive';

GenericCrAlertList.propTypes = {};

function GenericCrAlertList() {
    const [loading, setLoading] = React.useState(false);
    const [extraReportList, setExtraReportList] = React.useState([]);
    const [selectedFilterYear, setSelectedFilterYear] = React.useState(getCurrentYear());

    React.useEffect(() => {
        setLoading(true);
        setExtraReportList([]);
        getExtraReportsByYear(selectedFilterYear, (fetchedList) => {
            setLoading(false);
            if (fetchedList != null) {
                setExtraReportList(fetchedList);
            }
        });
    }, [selectedFilterYear]);

    const onItemUpdated = (crAlertData) => {
        toastInfo('Updating CR-Alert...');

        updateCrAlert(crAlertData, (updatedCrAlert) => {
            if (updatedCrAlert != null) {
                toastSuccess('CR-Alert successfully updated.');
                setExtraReportList(
                    [...extraReportList].map((obj) =>
                        obj.pk === crAlertData.pk ? crAlertData : obj
                    )
                );
            }
        });
    };

    const yearFilter = (
        <div key={randomId()}>
            <YearFilter>Years</YearFilter>
            <StyledDropdown
                options={getYearsOfActivityFromCurrent()}
                emptyOption={false}
                defaultValue={selectedFilterYear}
                onChange={(year) => setSelectedFilterYear(parseInt(year))}
            />
        </div>
    );

    return (
        <div>
            <NavigationBar />
            <StyledContainer>
                <h2>{CR_ALERTS}</h2>
                {loading ? (
                    <LoadingWheel />
                ) : (
                    <CrAlertList
                        isGeneric
                        list={extraReportList}
                        onCrAlertUpdated={onItemUpdated}
                        filters={[yearFilter]}
                    />
                )}
            </StyledContainer>
        </div>
    );
}
export default GenericCrAlertList;

const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 90vh;
    padding: 1rem 5% 0;
    margin: 0 auto;
    background-color: ${colors.light_gray};
`;

const StyledDropdown = styled(Dropdown)`
    min-width: 70px;

    @media ${dimension.md} {
        min-width: 150px;
    }
`;

const YearFilter = styled.p`
    margin-bottom: 0.1rem;
`;
