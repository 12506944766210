import sha512 from 'js-sha512';
import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { Col, Tooltip } from 'reactstrap';
import styled from 'styled-components';
import Button from '../../components/Button';
import Dropdown from '../../components/Dropdown';
import NavigationBar from '../../components/NavigationBar/NavigationBar';
import TitleBar from '../../components/TitleBar';
import { USER_ROLES } from '../../res/Constants';
import buttonTheme from '../../res/styles/theme_button.module.scss';
import { getClients } from '../../service/ClientService';
import { addUser } from '../../service/UserService';
import { toastSuccess } from '../../ui/ToastHelper';
import { ControlPanelPage, Input, PanelFieldTitle } from './components/Common';

const userRoles = {
    'Admin': USER_ROLES.admin,
    'Tech': USER_ROLES.tech,
    'Commercial': USER_ROLES.commercial,
    'Financial': USER_ROLES.financial,
    'Customer': USER_ROLES.client
};

function getInvalidFieldErrorText(fieldName) {
    return `${fieldName} is not valid`;
}

class AddUser extends PureComponent {
    state = {
        user: {},
        confirmPassword: null,
        validation: false,
        clients: [],
        selectedClient: ''
    };

    componentDidMount() {
        getClients((clients) => {
            this.setState({
                clients: [...clients],
                selectedClient: clients[0].code
            });
        });
    }

    onUsernameEdited = (event) => {
        const username = event.target.value;
        this.setState({ user: { ...this.state.user, username: username }, validation: false });
    };

    onPasswordEdited = (event) => {
        const password = event.target.value;
        this.setState({ user: { ...this.state.user, password: password }, validation: false });
    };

    onConfirmPasswordEdited = (event) => {
        const password = event.target.value;
        this.setState({ ...this.state, confirmPassword: password, validation: false });
    };

    onFirstNameEdited = (event) => {
        const firstName = event?.target?.value;
        // set as null if empty
        this.setState({
            user: {
                ...this.state.user,
                firstName: firstName || null
            }
        });
    };

    onLastNameEdited = (event) => {
        const lastName = event?.target?.value;
        // set as null if empty
        this.setState({
            user: {
                ...this.state.user,
                lastName: lastName || null
            }
        });
    };

    onRoleSelected = (role) => {
        const roleValue = userRoles[role];
        this.setState({
            user: { ...this.state.user, role: roleValue },
            validation: false
        });
    };

    onClientSelected = (client) => {
        this.setState({ ...this.state, selectedClient: client });
    };

    onAddUser = () => {
        this.setState({ ...this.state, validation: true }, () => {
            if (
                this.isUsernameValid() &&
                this.isPasswordValid() &&
                this.isConfirmPasswordValid() &&
                this.isRoleValid()
            ) {
                const user = { ...this.state.user };
                user.password = sha512(user.password);
                if (user.role == USER_ROLES.client) {
                    user.username = this.state.selectedClient;
                    user.client = this.state.clients.find(
                        (client) => client.code == this.state.selectedClient
                    );
                }
                addUser(user, (addedUser) => {
                    if (addedUser != null) {
                        toastSuccess(`User ${addedUser.username} successfully added`);
                        this.props.history.goBack();
                    }
                });
            }
        });
    };

    isUsernameValid = () => {
        const clientCodes = new Set(this.state.clients.map((client) => client.code));
        return (
            !this.state.validation ||
            this.state.user.role == USER_ROLES.client ||
            (this.state.user.username != null &&
                this.state.user.username.length > 0 &&
                !clientCodes.has(this.state.user.username.toUpperCase()))
        );
    };

    isPasswordValid = () => {
        return (
            !this.state.validation ||
            (this.state.user.password != null && this.state.user.password.length > 0)
        );
    };

    isConfirmPasswordValid = () => {
        return (
            (!this.state.validation && this.isPasswordValid()) ||
            (this.state.confirmPassword != null &&
                this.state.confirmPassword.length > 0 &&
                this.state.confirmPassword === this.state.user.password)
        );
    };

    isRoleValid = () => {
        return (
            !this.state.validation ||
            (this.state.user.role != null && this.state.user.role.length > 0)
        );
    };

    render() {
        return (
            <div>
                <NavigationBar />
                <TitleBar title='Add User' />
                <ControlPanelPage>
                    <FieldsCol xs='9' sm='7' md='5' lg='4' className='mx-auto'>
                        <PanelFieldTitle>Username</PanelFieldTitle>
                        <Input
                            type='text'
                            id='selectedClient'
                            disabled={true}
                            value={this.state.selectedClient}
                            style={{
                                display:
                                    this.state.user.role == USER_ROLES.client ? 'block' : 'none'
                            }}
                        />
                        <Input
                            type='text'
                            id='username'
                            onBlur={this.onUsernameEdited}
                            style={{
                                display:
                                    this.state.user.role != USER_ROLES.client ? 'block' : 'none'
                            }}
                        />
                        <Tooltip
                            placement='bottom'
                            target='username'
                            isOpen={!this.isUsernameValid()}>
                            {getInvalidFieldErrorText('Username')}
                        </Tooltip>

                        <PanelFieldTitle>Password</PanelFieldTitle>
                        <Input type='password' id='password' onBlur={this.onPasswordEdited} />
                        <Tooltip
                            placement='bottom'
                            target='password'
                            isOpen={!this.isPasswordValid()}>
                            {getInvalidFieldErrorText('Password')}
                        </Tooltip>

                        <PanelFieldTitle>Confirm Password</PanelFieldTitle>
                        <Input
                            type='password'
                            id='confirm-password'
                            onBlur={this.onConfirmPasswordEdited}
                        />
                        <Tooltip
                            placement='bottom'
                            target='confirm-password'
                            isOpen={!this.isConfirmPasswordValid()}>
                            Confirm Password does not match with Password
                        </Tooltip>
                        <PanelFieldTitle>First Name</PanelFieldTitle>
                        <Input type='text' id='first-name' onBlur={this.onFirstNameEdited} />
                        <PanelFieldTitle>Last Name</PanelFieldTitle>
                        <Input type='text' id='last-name' onBlur={this.onLastNameEdited} />
                        <PanelFieldTitle>Role</PanelFieldTitle>
                        <Dropdown
                            id='role'
                            options={Object.keys(userRoles)}
                            style={{ width: '100%' }}
                            emptyOption={true}
                            onChange={this.onRoleSelected}
                        />
                        <Tooltip placement='bottom' target='role' isOpen={!this.isRoleValid()}>
                            {getInvalidFieldErrorText('Role')}
                        </Tooltip>

                        <div
                            style={{
                                display:
                                    this.state.user.role == USER_ROLES.client ? 'block' : 'none',
                                marginTop: '20px'
                            }}>
                            <PanelFieldTitle>Customer</PanelFieldTitle>
                            <Dropdown
                                id='customer'
                                options={this.state.clients.map((c) => c.code)}
                                style={{ width: '100%' }}
                                emptyOption={false}
                                onChange={this.onClientSelected}
                            />
                        </div>

                        <div style={{ marginTop: '40px' }}>
                            <Button
                                text='ADD NEW USER'
                                className={buttonTheme.green}
                                style={{ width: '100%' }}
                                type='button'
                                onClick={this.onAddUser}
                            />
                        </div>
                    </FieldsCol>
                </ControlPanelPage>
            </div>
        );
    }
}

const FieldsCol = styled(Col)`
    & > input {
        margin-bottom: 20px;
    }
    & > select {
        margin-bottom: 0px;
    }
`;

export default withRouter(AddUser);
