import React from 'react';
import { isMobileOnly } from 'react-device-detect';
import { Row } from 'reactstrap';
import styled from 'styled-components';
import { ColNoPadding } from '../../../../../components/Common';
import Timeline from '../../../../../components/Timeline';
import colors from '../../../../../res/styles/Colors';
import dimension from '../../../../../res/styles/Responsive';

export const TitleRow = styled(Row)`
    justify-content: space-between;
`;

const StyledColumn = styled(ColNoPadding)`
    height: inherit;
    text-align: center;
    padding-top: 15px;
    background-color: ${({ backgroundColor }) => backgroundColor};

    h5 {
        color: ${({ color }) => color};
    }

    @media ${dimension.md} {
        padding-top: 0;
        text-align: left;
        background-color: white;

        .cell-shadow {
            height: 100%;
        }
    }
`;

export const GreenColumn = (props) => {
    return <StyledColumn backgroundColor={colors.lighter_green} color={colors.green} {...props} />;
};

export const RedColumn = (props) => {
    return <StyledColumn backgroundColor={colors.lighter_red} color={colors.red} {...props} />;
};

export const RedTimeLine = (props) => {
    const timelineColor = isMobileOnly ? colors.red : colors.light_red;
    return <Timeline titleColor={colors.red} timelineColor={timelineColor} {...props} />;
};

export const GreenTimeLine = (props) => {
    const timelineColor = isMobileOnly ? colors.green : colors.light_green;
    return <Timeline titleColor={colors.green} timelineColor={timelineColor} {...props} />;
};
