import PropTypes from 'prop-types';
import React from 'react';
import { isMobileOnly } from 'react-device-detect';
import Loader from 'react-loader-spinner';
import styled from 'styled-components';
import colors from '../res/styles/Colors';

LoadingWheel.propTypes = {
    className: PropTypes.string,
    isVisible: PropTypes.bool,
    centerMode: PropTypes.oneOf(['parent', 'absolute'])
};

LoadingWheel.defaultProps = {
    isVisible: true,
    centerMode: isMobileOnly ? 'absolute' : 'parent',
    height: 100,
    width: 100
};

/**
 * The parent container should implement "display: flex"
 */
function LoadingWheel(props) {
    return (
        <StyledLoader
            className={[props.className, props.centerMode].join(' ')}
            type='TailSpin'
            visible={props.isVisible}
            color={colors.green}
            height={props.height}
            width={props.width}
            centerMode={props.centerMode}
        />
    );
}

const StyledLoader = styled(Loader)`
    margin: auto;

    &.absolute {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
`;

export default LoadingWheel;
