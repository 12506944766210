import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { BrowserView, isBrowser, isMobile, MobileView } from 'react-device-detect';
import ReactVisibilitySensor from 'react-visibility-sensor';
import styled from 'styled-components';
import Checkbox from '../../../../components/Checkbox';
import ToggleableAnimation from '../../../../components/ToggleableAnimation';
import dimension from '../../../../res/styles/Responsive';
import buttonTheme from '../../../../res/styles/theme_button.module.scss';
import { readExtraReport } from '../../../../service/ExtraReportService';
import { filterByFields, sort } from '../../../../utils/Algorithms';
import { HorizontalDiv } from './../../../../components/Common';
import SearchBar from './../../../../components/SearchBar';
import SortSetter from './../../../../components/SortSetter';
import { ExtraReportCharacterNames } from './../../../../res/Constants';
import colors from './../../../../res/styles/Colors';
import { getCurrentCachedUserData } from './../../../../service/AccountService';
import { exportExtraReportList } from './../../../../service/ExtraReportService';
import { toastError, toastSuccess } from './../../../../ui/ToastHelper';
import CrAlertItem from './CrAlertItem';

const ANIMATION_DURATION = 1;

const SORT_BY_FIELDS = {
    CUSTOMER: {
        key: 'clientCode',
        value: 'Customer'
    },
    SPECIES: {
        key: 'speciesCode',
        value: 'Species'
    },
    TECHFORM_CODE: {
        key: 'techFormCode',
        value: 'Techform Code'
    },
    VARIETY: {
        key: 'varietyCode',
        value: 'Variety'
    },
    GROWER: {
        key: 'growerName',
        value: 'Grower'
    },
    LOT_N: {
        key: 'lotNumber',
        value: 'Lot Number'
    },
    USER: {
        key: 'username',
        value: 'User'
    },
    DATE: {
        key: 'date',
        value: 'Date'
    },
    INDEMNITY: {
        key: 'indemnity',
        value: 'Indemnity'
    }
};
const fieldsToSortBy = Object.values(SORT_BY_FIELDS);
const fieldsToSearchOn = [...fieldsToSortBy].slice(0, -2).map((e) => e.key);

CrAlertList.propTypes = {
    list: PropTypes.array,
    techFormData: PropTypes.object,
    isGeneric: PropTypes.bool,
    filters: PropTypes.arrayOf(PropTypes.object),
    onCrAlertUpdated: PropTypes.func
};

CrAlertList.defaultProps = {
    list: []
};

export default function CrAlertList(props) {
    const [selectAll, setSelectAll] = React.useState(false);
    const [isAddingCrAlert, setIsAddingCrAlert] = React.useState(false);
    const [selectedCrAlerts, setSelectedCrAlerts] = React.useState(new Set());
    const [readCrAlerts, setReadCrAlerts] = React.useState(new Set());
    const [filteredList, setFilteredList] = React.useState(props.list);

    React.useEffect(() => {
        setFilteredList(props.list);
    }, [props.list]);

    React.useEffect(() => {
        const newReadCrAlerts = filteredList
            .filter((crAlert) => crAlert.read === true)
            .map((crAlert) => crAlert.pk);
        setReadCrAlerts(new Set(newReadCrAlerts));
    }, [filteredList]);

    React.useEffect(() => {
        setIsAddingCrAlert(false);
    }, [props]);

    const onNewCrAlert = () => {
        setIsAddingCrAlert(!isAddingCrAlert);
    };

    const onEditingCanceled = () => {
        setIsAddingCrAlert(false);
    };

    const onExportCrAlerts = () => {
        if (selectedCrAlerts.size === 0) {
            toastError('No CR-Alerts selected!');
            return;
        }
        exportExtraReportList(
            Array.from(selectedCrAlerts),
            () => toastSuccess('Exported'),
            () => toastError('An error occurred')
        );
    };

    const onItemSelected = React.useCallback(
        (crAlertPk) => {
            const newSelectedCrAlerts = new Set(selectedCrAlerts);
            if (newSelectedCrAlerts.has(crAlertPk)) {
                newSelectedCrAlerts.delete(crAlertPk);
            } else {
                newSelectedCrAlerts.add(crAlertPk);
            }
            setSelectedCrAlerts(newSelectedCrAlerts);
        },
        [selectedCrAlerts]
    );

    const onSelectAll = () => {
        if (props?.list?.length === 0) return;
        const newSelectedCrAlerts =
            selectedCrAlerts.size != props?.list?.length
                ? new Set(props?.list?.map((item) => item.pk))
                : new Set();
        const newSelectAll = newSelectedCrAlerts.size === props?.list?.length;
        setSelectedCrAlerts(newSelectedCrAlerts);
        setSelectAll(newSelectAll);
    };

    const onSortChange = ({ field, sortType }) => {
        const fieldToSortBy = fieldsToSortBy.find((e) => e.key === field.key);
        if (fieldToSortBy != null) {
            const listToManipulate =
                fieldToSortBy.key == SORT_BY_FIELDS.INDEMNITY.key
                    ? filteredList.map((obj) => mapIndemnityToProperty(obj))
                    : filteredList;
            setFilteredList(sort(listToManipulate, sortType, fieldToSortBy.key));
        }
    };

    const onSearchChange = (searchValue) => {
        if (searchValue === '' || searchValue == null) {
            setFilteredList(props.list);
        } else {
            setFilteredList(filterByFields(props.list, fieldsToSearchOn, searchValue));
        }
    };

    const onReadCrAlert = (pk) => {
        if (!readCrAlerts.has(pk)) {
            readExtraReport(pk, () => {
                setReadCrAlerts((current) => {
                    const newReadCrAlerts = new Set(current);
                    newReadCrAlerts.add(pk);
                    return newReadCrAlerts;
                });
            });
        }
    };

    const exportButtonText = 'EXPORT SEL. CR-ALERTS';
    const exportButton = (
        <StyledRedButton onClick={onExportCrAlerts}>{exportButtonText}</StyledRedButton>
    );

    const selectAllCheckbox = (
        <StyledCheckboxSelectAll
            isHorizontal
            isEditable
            text='Select all'
            isSelected={selectAll}
            onChange={onSelectAll}
        />
    );
    return (
        <div className='list-container'>
            <MobileView renderWithFragment>
                <StyledFiltersContainer>{props.filters}</StyledFiltersContainer>
            </MobileView>
            <StyledFiltersContainer>
                <BrowserView renderWithFragment>{props.filters}</BrowserView>
                <StyledSearchBar onSearchChange={onSearchChange} />
                <StyledSortDiv>
                    <SortSetter fields={fieldsToSortBy} onSortChange={onSortChange} />
                </StyledSortDiv>
                {props.isGeneric && isBrowser && (
                    <StyledRedButton
                        style={{ marginTop: 'auto', marginBottom: '10px' }}
                        onClick={onExportCrAlerts}>
                        {exportButtonText}
                    </StyledRedButton>
                )}
            </StyledFiltersContainer>
            <ButtonContainer>
                {props.isGeneric && isMobile && (
                    <>
                        <div />
                        {exportButton}
                    </>
                )}
                {!props.isGeneric && (
                    <>
                        {exportButton}
                        <StyledRedButton onClick={onNewCrAlert}>NEW CR-ALERT</StyledRedButton>
                    </>
                )}
            </ButtonContainer>
            <ButtonContainer>
                <div />
                {selectAllCheckbox}
            </ButtonContainer>
            {isAddingCrAlert && props.techFormData && (
                <ToggleableAnimation
                    animationName='fadeInDown'
                    duration={ANIMATION_DURATION}
                    shouldAnimateOnce={true}>
                    <CrAlertItem
                        className='item'
                        isEditable={true}
                        isSelectable={true}
                        data={emptyCrAlert(props.techFormData)}
                        onItemUpdated={props.onCrAlertUpdated}
                        onItemCanceled={onEditingCanceled}
                    />
                </ToggleableAnimation>
            )}
            {filteredList.map((crAlert) => (
                <ReactVisibilitySensor
                    active={!readCrAlerts.has(crAlert.pk)}
                    delayedCall={true}
                    onChange={(isVisible) => {
                        if (isVisible) onReadCrAlert(crAlert.pk);
                    }}
                    key={crAlert.pk}>
                    <CrAlertItem
                        className='item'
                        data={crAlert}
                        isGeneric={props.isGeneric}
                        isSelectable={true}
                        isSelected={selectedCrAlerts.has(crAlert.pk)}
                        isRead={readCrAlerts.has(crAlert.pk)}
                        onItemUpdated={props.onCrAlertUpdated}
                        onItemSelected={onItemSelected}
                    />
                </ReactVisibilitySensor>
            ))}
        </div>
    );
}

const mapIndemnityToProperty = (obj) => ({
    ...obj,
    indemnity: obj?.extraReportCharacters?.Indemnity?.value ?? 'false'
});

const emptyCrAlert = (techFormData) => ({
    pk: null,
    read: false,
    readState: 0,
    date: moment(),
    username: (getCurrentCachedUserData() || {}).username,
    techFormPk: techFormData.techFormPk,
    techFormCode: techFormData.orderCode,
    clientCode: techFormData.custCode,
    growerName: techFormData.growerName,
    lotNumber: techFormData.orderNumber,
    varietyName: techFormData.varietyDesc,
    varietyCode: techFormData.varietyCode,
    extraReportCharacters: {
        [ExtraReportCharacterNames.NET_HA]: {
            pk: null,
            name: ExtraReportCharacterNames.NET_HA,
            value: techFormData.startingHa?.toString() ?? '0'
        }
    }
});

const StyledFiltersContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const StyledSortDiv = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: auto;
    margin-bottom: 10px;
`;

const StyledSearchBar = styled(SearchBar)`
    background: inherit;
    margin-top: auto;
    margin-bottom: 10px;

    & input {
        margin: 0;
    }
`;

const StyledCheckboxSelectAll = styled(Checkbox)`
    margin: auto 0 auto auto;
    color: ${colors.green};
`;

const ButtonContainer = styled(HorizontalDiv)`
    justify-content: space-between;
    padding: 0 0 15px;
`;

const StyledRedButton = styled.button.attrs({
    className: buttonTheme.red
})`
    && {
        width: 40%;
        margin: inherit;

        @media ${dimension.md} {
            width: inherit;
            padding: 0 35px;
        }
    }
`;
