import React from 'react';
import styled from 'styled-components';
import { CROP_REPORT } from './../res/Constants';
import colors from './../res/styles/Colors';
import dimension from './../res/styles/Responsive';

const SimpleHeader = (props) => {
    return (
        <StyledHeader className='row my-auto header'>
            <img id='logo' src={require('../res/images/cac_login_logo.png')} alt='CAC logo' />
            <h2 id='title'>{CROP_REPORT}</h2>
            <span />
        </StyledHeader>
    );
};

const StyledHeader = styled.div`
    width: 100%;
    color: white;
    background-color: ${colors.green};
    border-bottom: 2px solid ${colors.dark_green};

    #logo {
        position: absolute;
        margin-top: 5vh;
        margin-left: 1vh;
        z-index: 1;
        width: 10vh;
        height: 10vh;
    }

    #title {
        text-align: center;
        width: 100%;
        margin-top: auto;
        margin-bottom: auto;
        vertical-align: center;

        @media ${dimension.md} {
            text-align: start;
            margin-bottom: 0;
            margin-left: 80px;
        }
    }
`;

export default SimpleHeader;
