import React from 'react';
import {
    APP_DOWNLOADS,
    DOWNLOAD_CROP_APP,
    DOWNLOAD_CUSTOMER_VISITS_APP,
    DOWNLOAD_NURSERY_APP,
    DOWNLOAD_INVENTORY_APP,
    USER_ROLES
} from '../../../res/Constants';
import { getCurrentRole } from '../../../service/AccountService';
import PopoverMenu, { PopoverButtonItem } from '../../PopoverMenu';

export default function DownloadApplicationIcon(props) {
    const [popoverVisible, setPopoverVisible] = React.useState(false);

    const onTogglePopover = () => {
        setPopoverVisible((curr) => !curr);
    };

    return (
        <div className={props.className}>
            {getCurrentRole() !== USER_ROLES.client && (
                <>
                    <img
                        style={{ height: '26px', cursor: 'pointer' }}
                        src={require('../../../res/images/cac_download_app.png')}
                        alt={'Download the android applications'}
                        id='app-download'
                        onClick={onTogglePopover}
                    />
                    <PopoverMenu
                        target='app-download'
                        isOpen={popoverVisible}
                        header={props.username}
                        onToggle={onTogglePopover}>
                        <PopoverButtonItem as='a' href={APP_DOWNLOADS.CROP} target='_blank'>
                            {DOWNLOAD_CROP_APP}
                        </PopoverButtonItem>
                        <PopoverButtonItem as='a' href={APP_DOWNLOADS.NURSERY} target='_blank'>
                            {DOWNLOAD_NURSERY_APP}
                        </PopoverButtonItem>
                        <PopoverButtonItem
                            as='a'
                            href={APP_DOWNLOADS.CUSTOMER_VISITS}
                            target='_blank'>
                            {DOWNLOAD_CUSTOMER_VISITS_APP}
                        </PopoverButtonItem>
                        <PopoverButtonItem as='a' href={APP_DOWNLOADS.INVENTORY} target='_blank'>
                            {DOWNLOAD_INVENTORY_APP}
                        </PopoverButtonItem>
                    </PopoverMenu>
                </>
            )}
        </div>
    );
}
