import { USER_ROLES } from '../res/Constants';
import { getCurrentCachedUserData, getCurrentRole } from './AccountService';

export function canSeeCrAlerts() {
    return getCurrentRole() == null ? false : !(getCurrentRole() === USER_ROLES.client);
}

export function canEditGrowing() {
    const userRole = getCurrentRole();
    return userRole === USER_ROLES.admin || userRole === USER_ROLES.tech;
}

export function canCreateReport() {
    const userRole = getCurrentRole();
    return userRole === USER_ROLES.tech || userRole === USER_ROLES.admin;
}

export function canEditReport(reportIndex, reportOwnerUsername) {
    return canEditOthersReports(reportOwnerUsername) && canEditOwnReports(reportIndex);
}

export function canEditOthersReports(reportOwnerUsername) {
    const userRole = getCurrentRole();
    const userData = getCurrentCachedUserData();
    if (
        userRole === USER_ROLES.commercial ||
        userRole === USER_ROLES.financial ||
        userRole === USER_ROLES.client
    ) {
        return false;
    }
    if (userRole === USER_ROLES.tech && reportOwnerUsername !== userData.username) {
        return false;
    }
    return true;
}

export function canEditOwnReports(reportIndex) {
    const userRole = getCurrentRole();
    if (reportIndex !== 0 && userRole === USER_ROLES.tech) {
        return false;
    }
    if (
        userRole === USER_ROLES.commercial ||
        userRole === USER_ROLES.financial ||
        userRole === USER_ROLES.client
    ) {
        return false;
    }
    return true;
}

export function canReadExtraReport() {
    const userRole = getCurrentRole();
    return userRole === USER_ROLES.financial || userRole === USER_ROLES.commercial;
}

export function canSeeOnlyLastReport() {
    return getCurrentRole() === USER_ROLES.client;
}
