import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { Modal, ModalBody } from 'reactstrap';
import styled from 'styled-components';
import SortSetter from '../../../components/SortSetter';
import colors from '../../../res/styles/Colors';
import dimension from '../../../res/styles/Responsive';
import buttonTheme from '../../../res/styles/theme_button.module.scss';
import Button from './../../../components/Button';
import { TECHFORM_MAIN_FIELDS } from './../../../res/Constants';
import DownloadButton from './DownloadButton';
import TechFilters from './TechFilters';
export default class NavBar extends PureComponent {
    static propTypes = {
        filters: PropTypes.object,
        prevFilters: PropTypes.object,
        fieldToSortBy: PropTypes.object,
        downloadEnabled: PropTypes.bool,
        onExcelDownload: PropTypes.func,
        onPhotosDownload: PropTypes.func,
        onSortSettingsChange: PropTypes.func,
        onFiltersApply: PropTypes.func,
        onSearchChange: PropTypes.func,
        searchKey: PropTypes.number,
        onFiltersReset: PropTypes.func
    };

    state = {
        modal: false
    };

    toggleModal = () => {
        this.setState({
            modal: !this.state.modal
        });
    };

    render() {
        const fieldsToSortBy = Object.values(TECHFORM_MAIN_FIELDS).filter(
            (e) => e.key !== 'sectorCode'
        );
        const initialFieldIndexToSortBy = fieldsToSortBy.findIndex(
            (f) => f.key === this.props.fieldToSortBy.field.key
        );
        const initialSortType =
            this.props.fieldToSortBy == null ? undefined : this.props.fieldToSortBy.sortType;
        return (
            <TechFormNavBar>
                {isMobileOnly && (
                    <TechFormSearch
                        onSearchChange={this.props.onSearchChange}
                        searchKey={this.props.searchKey}
                    />
                )}
                <div className='d-nav-buttons'>
                    {!isMobileOnly ? (
                        <TechFormSearch
                            onSearchChange={this.props.onSearchChange}
                            key={this.props.searchKey}
                        />
                    ) : (
                        <>
                            <FilterButton
                                text='Filters'
                                className={buttonTheme.green}
                                onClick={this.toggleModal}
                            />
                            <Modal
                                centered={true}
                                size='sm'
                                isOpen={this.state.modal}
                                toggle={this.toggleModal}>
                                <ModalBody>
                                    {this.props.filters && (
                                        <TechFilters
                                            filters={this.props.filters}
                                            prevFilters={this.props.prevFilters}
                                            onFiltersApply={(filters) => {
                                                this.toggleModal();
                                                this.props.onFiltersApply(filters);
                                            }}
                                            onFiltersReset={this.props.onFiltersReset}
                                        />
                                    )}
                                </ModalBody>
                            </Modal>
                        </>
                    )}
                    <DivSpace />
                    <SortSetter
                        selectedIndex={initialFieldIndexToSortBy}
                        selectedSortType={initialSortType}
                        fields={fieldsToSortBy}
                        onSortChange={this.props.onSortSettingsChange}
                    />
                    <DivSpace />
                    <DownloadButton
                        downloadEnabled={this.props.downloadEnabled}
                        onExcelDownload={this.props.onExcelDownload}
                        onPhotosDownload={this.props.onPhotosDownload}
                    />
                </div>
            </TechFormNavBar>
        );
    }
}

const TechFormSearch = ({ onSearchChange, searchKey }) => {
    return (
        <ContainerSearch>
            <input
                type='text'
                placeholder='Search'
                className='input-field'
                onChange={(e) => onSearchChange(e.target.value)}
                key={searchKey}
            />
        </ContainerSearch>
    );
};

const ContainerSearch = styled.div`
    background: linear-gradient(
        to bottom,
        ${colors.green} 40%,
        ${colors.dark_green} 10%,
        ${colors.light_gray} 50%
    );
    display: flex;

    @media ${dimension.md} {
        background: inherit;
    }

    input {
        margin: auto;
        width: 90%;
        text-indent: 20px;

        @media ${dimension.md} {
            width: 300px;
        }

        &::placeholder {
            color: ${colors.dark_green};
        }
    }
`;

const FilterButton = styled(Button)`
    padding: 0 25px;
`;

const TechFormNavBar = styled.div`
    background-color: ${colors.light_gray};

    @media ${dimension.md} {
        height: auto;
        background-color: white;
    }

    button {
        border-radius: 50px;
    }

    .d-nav-buttons {
        min-height: 11vh;
        display: flex;
        width: 95%;
        margin: auto;
        padding-top: 15px;
        padding-bottom: 15px;

        @media ${dimension.md} {
            min-height: 9vh;
            padding-top: 2vh;
        }

        @media ${dimension.lg} {
            height: 9vh;
        }

        & > * {
            margin-top: auto;
            margin-bottom: auto;
        }
    }
`;

const DivSpace = styled.div`
    width: 10px;

    @media ${dimension.md} {
        width: 50px;
    }
`;
