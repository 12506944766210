import axios from 'axios';
import { isProduction } from './InfoService';

axios.defaults.withCredentials = true;
if (isProduction) {
    axios.defaults.baseURL = '/crsapi/';
} else {
    axios.defaults.baseURL = '/cac-cropreport-web-api/';
}


/**
 * Debug
 */
axios.interceptors.request.use((request) => {
    console.log('Starting Request', request);
    return request;
});

axios.interceptors.response.use((response) => {
    console.log('Response:', response);
    return response;
});
