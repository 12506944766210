import axios from 'axios';
import isEmpty from 'lodash/isEmpty';
import querystring from 'querystring';
import { MOCKED_DATA } from '../debug/Debug';
import { CAC_TYPE, USER_ROLES } from '../res/Constants';
import { saveCacType } from './CacTypeService';
import { resetReportPksRead } from './ExtraReportService';

let mockedUser;

if (process.env.NODE_ENV === 'development' && MOCKED_DATA) {
    // eslint-disable-next-line global-require
    mockedUser = require('../debug/UserDataMockReponse.json');
}

const USER_DATA = 'userData';
const IS_LOGGED = 'isLogged';

const LOGIN_ADDRESS = '/login';
const LOGOUT_ADDRESS = '/logout';
const USER_DATA_ADDRESS = '/users/current';

function getLoginData(formData) {
    if (MOCKED_DATA) {
        return mockedUser;
    }
    return formData;
}

export function login(formData, callback) {
    const loginData = getLoginData(formData);
    const headers = { 'Content-Type': 'application/x-www-form-urlencoded' };
    axios
        .post(LOGIN_ADDRESS, querystring.stringify(loginData), headers)
        .then((result) => {
            localStorage.setItem(IS_LOGGED, 'true');
            saveCacType(CAC_TYPE.CROP);
            callback(result);
        })
        .catch(() => {
            localStorage.removeItem(IS_LOGGED);
        });
}

export function getCurrentRole() {
    return getCurrentCachedUserData()?.role;
}

export function isCurrentUserAdmin() {
    return (getCurrentCachedUserData() || {}).role === USER_ROLES.admin;
}

export function getCurrentCachedUserData() {
    const cachedUserData = localStorage.getItem(USER_DATA);
    return cachedUserData == null ? null : JSON.parse(cachedUserData);
}

export async function getCurrentUserDataAsync() {
    const cachedUserData = getCurrentCachedUserData();
    if (cachedUserData != null && !isEmpty(cachedUserData)) return cachedUserData;

    const userDataResponse = await axios.get(USER_DATA_ADDRESS);
    if (userDataResponse.name === 'Error') return null;
    const fetchedUserData = userDataResponse.data;
    localStorage.setItem(USER_DATA, JSON.stringify(fetchedUserData));
    return fetchedUserData;
}

export function logout(callback) {
    localStorage.clear();
    resetReportPksRead();
    axios
        .post(LOGOUT_ADDRESS)
        .then((result) => {
            if (callback != null) {
                callback(result);
            }
        })
        .catch((error) => {
            if (callback != null) {
                callback(error);
            }
        });
}

export function isUserLogged() {
    return localStorage.getItem(IS_LOGGED) === 'true';
}
